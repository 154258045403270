import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TempFilters from "../../components/Filters/TempFilters";

import HCMore from 'highcharts/highcharts-more'; // If you need additional chart types
import HighchartsExporting from 'highcharts/modules/exporting'; // For exporting the chart as an image or PDF
import HighchartsExportData from 'highcharts/modules/export-data'; // For exporting data as CSV or XLSX

// Initialize the additional modules
HCMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const Retirement = () => {


    const chartProps4 = {
        type: 'column',
        title: '',
        categories: [ 'Top <br/> Management', 'Senior <br/> Management', 'Professional / Middle <br/> Management', 'Technical / Junior <br/> Management', 'Semi-Skilled', 'Unskilled', 'Average <br/>Headcount'],
        colorByPoint: true,
        colors: ['#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#E88B00',],
        creditsEnabled: false,
        credits:'',
        legendEnabled:false,
        series: [{
            name: 'Retirements',
            data: [0.5, 5.7, 38.2, 54.7, 0.5, 0.5, 4.7],
            dataLabels: {
                enabled: true,
                format: '{y}%',
            },
            color: '#5BBDCB',
        },
        ]
    };

    const perBA = {
        type: 'column',
        title: '',
        categories: ['Client & Adviser <br/> Experience','Client & Adviser <br /> Service Centre','Information <br/>Technology','Employee <br/>Benefits','Finance','Compliance <br/>& Forensic','Asset <br/>Management / Institutional',
            'Human <br/> Capital','Business <br/> Development','Properties','Actuarial<br/>& Risk','Legal','Marketing & <br/>Communications','Retail Risk <br/>Solutions',],
        colorByPoint: true,
        colors: ['#74BBC9','#74BBC9'],
        creditsEnabled: false,
        credits:'',
        legendEnabled:false,
        yAxisTitleText:'',
        series: [
            {
                data: [55, 53, 38, 19, 13, 13, 6, 6, 4, 1, 1, 1, 1, 1],
                dataLabels: {
                    enabled: true,
                    format: '{y}',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#5BBDCB',
            },
        ]
    };

    const getOptions3 = (props) => ({
        chart: {
            type: props.type,
           /* width: props.width === undefined ? 500 : props.width,
            height: props.height === undefined ? 400 : props.height,*/
            backgroundColor: 'white',
        },
        plotOptions: {
            column: {
                colorByPoint: props.colorByPoint
            },
            series: {
                borderRadius: '25%'
            }
        },
        colors: props.colors,
        title: {
            text: props.title
        },
        credits: {
            enabled: props.creditsEnabled
        },
        legend: {
            enabled: props.legendEnabled
        },
        yAxis: {
            title: {
                text: props.yAxisTitleText === undefined ? 'Percentages(%)' : props.yAxisTitleText,
            },
        },
        xAxis: {
            categories: props.categories,
            labels: {
                style: {
                    fontSize: '12px', // Adjust font size if needed
                    whiteSpace: 'normal', // Allow labels to wrap when long
                    overflow: 'scroll',
                },
                tickWidth: 0,
            },
        },
        series: props.series,
    });



    // Sample data for the pie chart
    const chartData = [
        { name: 'Early Retirement', y: 33.3 },
        { name: 'Normal Retirement', y: 66.7 },
    ];

    // Highcharts configuration object
    const options = {
        colors: [ '#73BBC9', '#5E90B2'],
        chart: {
            type: 'pie',
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            pie: {
                showInLegend: true,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                },
                innerSize: '50%',
            },
        },
        series: [
            {
                name: 'Categories',
                colorByPoint: true,
                data: chartData,
            },
        ],
        legend: {
            enabled: true, // Set to true to enable the legend
        },
    };


    return (
        <>
            <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Workforce Analysis / Retirement"} />
            <main id="main" className="main">
                <div className='d-flex flex-column'>
                    <div>
                        <button className='btn btn-primary btn-sm py-1 me-2'>RESET</button>
                        <button className='btn btn-primary btn-sm py-1'>REAPPLY</button>
                    </div>
                    <div>
                        <TempFilters />
                    </div>
                </div>

                <section className="section ">
                    <div className='row'>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Retirees</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={"d-flex justify-content-center my-3"}>
                                        <div className="table-container">
                                            <table className={"custom-table-wa retirees"}>
                                                <tbody>
                                                <tr>
                                                    <td  className={" text-end"}><i className="fa-solid fa-percent fs-4 fw-bolder"></i></td>
                                                    <td  className={"text-white fw-bold heading4"}>Jan '23</td>
                                                    <td  className={"text-white fw-bold heading4"}>Feb '23</td>
                                                    <td  className={"text-white fw-bold heading4"}>Mar '23</td>
                                                    <td  className={"text-white fw-bold heading4"}>Apr '23</td>
                                                    <td  className={"text-white fw-bold heading4"}>May '23</td>
                                                    <td  className={"text-white fw-bold heading4"}>Jun '23</td>
                                                </tr>
                                                <tr>
                                                    <td  className={" text-end"}></td>
                                                    <td colSpan="6" className="retirees-red">TOTAL #: 33</td>
                                                </tr>
                                                <tr>
                                                    <td  className={" text-end"}>Top Management <i className="fa-solid fa-square text-sky-blue"></i></td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-5"}>0</td>

                                                </tr>
                                                <tr>
                                                    <td  className={" text-end"}>Senior Management <i className="fa-solid fa-square text-sky-blue"></i></td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>1</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-5"}>1</td>

                                                </tr>
                                                <tr>
                                                    <td  className={" text-end"}>Professional / Middle Management <i className="fa-solid fa-square text-sky-blue"></i></td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>2</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>3</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>1</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>1</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>3</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>3</td>

                                                </tr>

                                                <tr>
                                                    <td  className={" text-end"}>
                                                        <div className={"d-flex align-items-center justify-content-end"}>
                                                            <span className={"col-first"}>Technical / Junior Management</span> <i className="fa-solid fa-square text-sky-blue"></i>
                                                        </div>
                                                    </td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>2</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>1</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>1</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>3</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>6</td>
                                                    <td  className={"text-black fst-normal bg-col-5"}>4</td>
                                                </tr>

                                                <tr>
                                                    <td  className={" text-end"}>
                                                        <div className={"d-flex align-items-center justify-content-end"}>
                                                            <span className={"col-first"}>Semi - Skilled</span> <i className="fa-solid fa-square text-sky-blue"></i>
                                                        </div>
                                                    </td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>1</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-5"}>0</td>
                                                </tr>

                                                <tr>
                                                    <td  className={" text-end"}>Unskilled <i className="fa-solid fa-square text-sky-blue"></i></td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-5"}>0</td>
                                                </tr>

                                                <tr>
                                                    <td  className={""}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>

                                                </tr>

                                                <tr>
                                                    <td  className={""}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>

                                                </tr>

                                                <tr>
                                                    <td  className={" text-end fw-bold"}>TOTAL <i className="fa-solid fa-square-check"></i></td>
                                                    <td  className={"retirees-red"}>6</td>
                                                    <td  className={"retirees-red"}>4</td>
                                                    <td  className={"retirees-red"}>2</td>
                                                    <td  className={"retirees-red"}>4</td>
                                                    <td  className={"retirees-red"}>9</td>
                                                    <td  className={"retirees-red"}>8</td>

                                                </tr>

                                                <tr>
                                                    <td  className={""}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>

                                                </tr>

                                                <tr>
                                                    <td  className={""}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>

                                                </tr>

                                                <tr>
                                                    <td  className={""}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>

                                                </tr>

                                                <tr>
                                                    <td  className={" text-end"}>African <i className="fa-solid fa-square text-light-blue"></i></td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>2</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>3</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>2</td>
                                                    <td  className={"text-black fst-normal bg-col-5"}>1</td>
                                                </tr>

                                                <tr>
                                                    <td  className={" text-end"}>Indian <i className="fa-solid fa-square text-light-blue"></i></td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>1</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>1</td>
                                                    <td  className={"text-black fst-normal bg-col-5"}>0</td>

                                                </tr>

                                                <tr>
                                                    <td  className={" text-end"}>Coloured <i className="fa-solid fa-square text-light-blue"></i></td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>2</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>1</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>1</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>0</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>4</td>
                                                    <td  className={"text-black fst-normal bg-col-5"}>3</td>

                                                </tr>

                                                <tr>
                                                    <td  className={" text-end"}>White <i className="fa-solid fa-square text-light-blue"></i></td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>2</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>2</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>1</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>1</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>2</td>
                                                    <td  className={"text-black fst-normal bg-col-5"}>4</td>

                                                </tr>

                                                <tr>
                                                    <td  className={""}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>

                                                </tr>

                                                <tr>
                                                    <td  className={""}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>

                                                </tr>

                                                <tr>
                                                    <td  className={""}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>

                                                </tr>

                                                <tr>
                                                    <td  className={" text-end"}>Female <i className="fa-solid fa-square text-dark-blue"></i></td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>1</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>2</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>1</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>1</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>6</td>
                                                    <td  className={"text-black fst-normal bg-col-5"}>8</td>
                                                </tr>

                                                <tr>
                                                    <td  className={" text-end"}>Male  <i className="fa-solid fa-square text-dark-blue"></i></td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>5</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>2</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>1</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>3</td>
                                                    <td  className={"text-black fst-normal bg-col-4"}>3</td>
                                                    <td  className={"text-black fst-normal bg-col-5"}>0</td>
                                                </tr>

                                                <tr>
                                                    <td  className={""}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>

                                                </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <span className="fw-bold h5 mb-3">YTD Retirees’ Stats</span>
                                    <div className="retiree-stats mt-3">
                                        <div className="d-flex justify-content-between mb-3">
                                            <div className="d-flex justify-content-start align-items-center retiree-stats-title py-2">
                                                <span className="retiree-stats-title-icon">
                                                    <i className="fa-solid fa-info"></i>
                                                </span> <span className="ms-1">Average Retirement Age</span>
                                            </div>
                                            <div className="retiree-stats-value d-flex align-items-center ">
                                                <span className="ms-2">59</span>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-between mb-3">
                                            <div className="d-flex justify-content-start align-items-center retiree-stats-title py-2">
                                                <span className="retiree-stats-title-icon">
                                                    <i className="fa-solid fa-info"></i>
                                                </span> <span className="ms-1 fw-bold">Earliest Early Retirement Age</span>
                                            </div>
                                            <div className="retiree-stats-value d-flex align-items-center ">
                                                <span className="ms-2 fw-bold">54</span>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-between mb-3">
                                            <div className="d-flex justify-content-start align-items-center retiree-stats-title py-2">
                                                <span className="retiree-stats-title-icon">
                                                    <i className="fa-solid fa-info"></i>
                                                </span> <span className="ms-1">Re-contracted Retirees</span>
                                            </div>
                                            <div className="retiree-stats-value d-flex align-items-center ">
                                                <span className="ms-2">5</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className="mx-3 my-3">
                                        <span className={"fs-4"}>Retirement Age (59+ in 12 Months) = </span> <span className={"fs-4 fw-bold"}>212</span>
                                    </div>
                                    <HighchartsReact highcharts={Highcharts} options={getOptions3(chartProps4)} />
                                    <button className="btn btn-primary">
                                        <i className="fa-solid fa-table text-white"></i> Export List
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Normal Retirement vs. Early Retirement</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={"d-flex justify-content-center"}>
                                        <HighchartsReact highcharts={Highcharts} options={options} />
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-3 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Retirement Age (59+) in 12 Month: Business Area</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                    <HighchartsReact highcharts={Highcharts} options={getOptions3(perBA)} />

                                    <button className="btn btn-primary">
                                        <i className="fa-solid fa-table text-white"></i> Export List
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
export default Retirement;
