import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import TempFilters from '../../components/Filters/TempFilters';

const OutliersJobgradingVSBenchmark = () => {
const [show,setShow] = useState(false)

  const employees = [
    {
      empNo: "A76543",
      name: "Joe Carey",
      jobTitle: "Manager: Operations",
      lineManager: "Jim Slovo",
      businessArea: "Retail",
      businessUnit: "Client Services"
    },
    {
      empNo: 'B77548',
      name: "Manh Smith",
      jobTitle: "Project Manager: IT",
      lineManager: "Mariah Olin",
      businessArea: "Information Technology",
      businessUnit: "Mariah Olin"
    },
    {
      empNo: "A67549",
      name: "Oliver White",
      jobTitle: "Team Leader",
      lineManager: "Joe Carey",
      businessArea: "Retail",
      businessUnit: "Client Services"
    },
  ]

  const grades = [
    {
      alignedGrade: 0,
      belowGrade: 0,
      inRange: 5,
      aheadGrade: 0,
      unknown: 0
    },
    {
      alignedGrade: 0,
      belowGrade: 0,
      inRange: 9,
      aheadGrade: 0,
      unknown: 0
    },
    {
      alignedGrade: 22,
      belowGrade: 0,
      inRange: 7,
      aheadGrade: 0,
      unknown: 732
    },
    {
      alignedGrade: 0,
      belowGrade: 0,
      inRange: 18,
      aheadGrade: 0,
      unknown: 74
    },
    {
      alignedGrade: 7,
      belowGrade: 0,
      inRange: 0,
      aheadGrade: 0,
      unknown: 22
    },

    {
      alignedGrade: 117,
      belowGrade: 0,
      inRange: 2,
      aheadGrade: 4,
      unknown: 0
    },

    {
      alignedGrade: 956,
      belowGrade: 43,
      inRange: 90,
      aheadGrade: 11,
      unknown: "1 345"
    },
    {
      alignedGrade: 10,
      belowGrade: 5,
      inRange: 5,
      aheadGrade: 5,
      unknown: 23
    },
    {
      alignedGrade: 9,
      belowGrade: 0,
      inRange: 0,
      aheadGrade: 0,
      unknown: 56
    },
    {
      alignedGrade: 5,
      belowGrade: 0,
      inRange: 4,
      aheadGrade: 8,
      unknown: 33
    },

    {
      alignedGrade: 640,
      belowGrade: 5,
      inRange: 10,
      aheadGrade: 5,
      unknown: 88
    },

    {
      alignedGrade: 52,
      belowGrade: 0,
      inRange: 5,
      aheadGrade: 5,
      unknown: 56
    },
    {
      alignedGrade: 7,
      belowGrade: 22,
      inRange: 0,
      aheadGrade: 0,
      unknown: "D4"
    },

    {
      alignedGrade: 0,
      belowGrade: 0,
      inRange: 34,
      aheadGrade: 0,
      unknown: 76
    },

    {
      alignedGrade: 72,
      belowGrade: 0,
      inRange: 0,
      aheadGrade: 0,
      unknown: 43
    },

    {
      alignedGrade: 5,
      belowGrade: 0,
      inRange: 9,
      aheadGrade: 3,
      unknown: 23
    },

    {
      alignedGrade: 55,
      belowGrade: 0,
      inRange: 2,
      aheadGrade: 0,
      unknown: 57
    },

    {
      alignedGrade: 0,
      belowGrade: 0,
      inRange: 5,
      aheadGrade: 0,
      unknown: 87
    },
    {
      alignedGrade: 5,
      belowGrade: 0,
      inRange: 0,
      aheadGrade: 0,
      unknown: 34
    },
    {
      alignedGrade: 44,
      belowGrade: 0,
      inRange: 6,
      aheadGrade: 4,
      unknown: 11
    },
    {
      alignedGrade: 0,
      belowGrade: 1,
      inRange: 0,
      aheadGrade: 0,
      unknown: 7
    },
    {
      alignedGrade: 0,
      belowGrade: 1,
      inRange: 0,
      aheadGrade: 0,
      unknown: 2
    }
  ]

  const rewardBenchmarkGrade = [
    {
      jobGrade: "A1",
      a1: {
        color: "bg-brown",
        value: 5
      },
      b2: null,
      b3: null,
      b4: null,
      b5: null,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: null,
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },

    {
      jobGrade: "B1",
      a1: null,
      b2: {
        color: "bg-brown",
        value: 9
      },
      b3: null,
      b4: null,
      b5: null,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: null,
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },

    {
      jobGrade: "B2",
      a1: null,
      b2: {
        color: "bg-green",
        value: 22
      },
      b3: {
        color: "bg-brown",
        value: 7
      },
      b4: null,
      b5: null,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: null,
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },
    {
      jobGrade: "B3",
      a1: null,
      b2: null,
      b3: null,
      b4: {
        color: "bg-brown",
        value: 18
      },
      b5: null,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: null,
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },
    {
      jobGrade: "B4",
      a1: null,
      b2: null,
      b3: null,
      b4: {
        color: "bg-green",
        value: 7
      },
      b5: null,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: null,
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },

    {
      jobGrade: "C1",
      a1: null,
      b2: null,
      b3: null,
      b4: null,
      b5: {
        color: "bg-purple",
        value: 4
      },
      c1: {
        color: "bg-green",
        value: 117
      },
      c2: {
        color: "bg-brown",
        value: 2
      },
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: null,
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },
    {
      jobGrade: "C2",
      a1: null,
      b2: null,
      b3: null,
      b4: {
        color: "bg-purple",
        value: 11
      },
      b5: null,
      c1: {
        color: "bg-brown",
        value: 5
      },
      c2: {
        color: "bg-green",
        value: 956
      },
      c3: {
        color: "bg-brown",
        value: 76
      },
      c4: {
        color: "bg-orange",
        value: 43
      },
      c5: {
        color: "bg-brown",
        value: 2
      },
      d1: {
        color: "bg-brown",
        value: 7
      },
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: null,
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },

    {
      jobGrade: "C3",
      a1: null,
      b2: null,
      b3: null,
      b4: null,
      b5: null,
      c1: null,
      c2: {
        color: "bg-brown",
        value: 5
      },
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: {
        color: "bg-purple",
        value: 5
      },
      d3: {
        color: "bg-green",
        value: 5
      },
      d4: {
        color: "bg-orange",
        value: 5
      },
      d5: {
        color: "bg-green",
        value: 5
      },
      e1: null,
      e2: null,
      e3: null,
      e4: null,
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },

    {
      jobGrade: "C4",
      a1: null,
      b2: null,
      b3: null,
      b4: null,
      b5: null,
      c1: null,
      c2: {
        color: "bg-green",
        value: 9
      },
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: null,
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },


    {
      jobGrade: "D1",
      a1: null,
      b2: null,
      b3: null,
      b4: null,
      b5: null,
      c1: null,
      c2: null,
      c3: {
        color: "bg-purple",
        value: 8
      },
      c4: {
        color: "bg-green",
        value: 5
      },
      c5: {
        color: "bg-brown",
        value: 4
      },
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: null,
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },


    {
      jobGrade: "D2",
      a1: null,
      b2: null,
      b3: null,
      b4: null,
      b5: null,
      c1: null,
      c2: {
        color: "bg-purple",
        value: 5
      },
      c3: null,
      c4: {
        color: "bg-brown",
        value: 5
      },
      c5: {
        color: "bg-green",
        value: 456
      },
      d1: {
        color: "bg-brown",
        value: 5
      },
      d2: {
        color: "bg-green",
        value: 5
      },
      d3: {
        color: "bg-orange",
        value: 5
      },
      d4: {
        color: "bg-green",
        value: 94
      },
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: null,
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },


    {
      jobGrade: "D3",
      a1: null,
      b2: null,
      b3: null,
      b4: null,
      b5: null,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: {
        color: "bg-purple",
        value: 5
      },
      e1: {
        color: "bg-green",
        value: 44
      },
      e2: {
        color: "bg-brown",
        value: 5
      },
      e3: {
        color: "bg-green",
        value: 8
      },
      e4: null,
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },

    {
      jobGrade: "D4",
      a1: null,
      b2: null,
      b3: null,
      b4: null,
      b5: null,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      c5: null,
      d1: {
        color: "bg-green",
        value: 7
      },
      d2: {
        color: "bg-orange",
        value: 22
      },
      d3: null,
      d4: null,
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: null,
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },

    {
      jobGrade: "D5",
      a1: null,
      b2: null,
      b3: null,
      b4: null,
      b5: null,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: null,
      d3: {
        color: "bg-brown",
        value: 5
      },
      d4: null,
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: null,
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },

    {
      jobGrade: "E1",
      a1: null,
      b2: null,
      b3: null,
      b4: null,
      b5: null,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: null,
      d3: {
        color: "bg-green",
        value: 72
      },
      d4: null,
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: null,
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },

    {
      jobGrade: "E2",
      a1: null,
      b2: null,
      b3: null,
      b4: null,
      b5: null,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: null,
      d3: null,
      d4: {
        color: "bg-purple",
        value: 3
      },
      d5: {
        color: "bg-green",
        value: 5
      },
      e1: {
        color: "bg-brown",
        value: 9
      },
      e2: null,
      e3: null,
      e4: null,
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },

    {
      jobGrade: "E3",
      a1: null,
      b2: null,
      b3: null,
      b4: null,
      b5: null,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      e1: null,
      e2: {
        color: "bg-green",
        value: 55
      },
      e3: {
        color: "bg-brown",
        value: 2
      },
      e4: null,
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },


    {
      jobGrade: "E4",
      a1: null,
      b2: null,
      b3: null,
      b4: null,
      b5: null,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: {
        color: "bg-brown",
        value: 5
      },
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },

    {
      jobGrade: "E5",
      a1: null,
      b2: null,
      b3: null,
      b4: null,
      b5: null,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: {
        color: "bg-green",
        value: 5
      },
      e5: null,
      f1: null,
      f2: null,
      f3: null
    },

    {
      jobGrade: "F1",
      a1: null,
      b2: null,
      b3: null,
      b4: null,
      b5: null,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: null,
      e5: {
        color: "bg-purple",
        value: 4
      },
      f1: {
        color: "bg-green",
        value: 44
      },
      f2: {
        color: "bg-brown",
        value: 6
      },
      f3: null
    },

    {
      jobGrade: "F2",
      a1: null,
      b2: null,
      b3: null,
      b4: null,
      b5: null,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: null,
      e5: null,
      f1: null,
      f2: {
        color: "bg-orange",
        value: 1
      },
      f3: null
    },
    {
      jobGrade: "F3",
      a1: null,
      b2: null,
      b3: null,
      b4: null,
      b5: null,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      c5: null,
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      e1: null,
      e2: null,
      e3: null,
      e4: null,
      e5: null,
      f1: null,
      f2: {
        color: "bg-orange",
        value: 1
      },
      f3: null
    }
  ]


  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Work Architecture / Outliers: Job Grading vs. Benchmark"} />
      <main id="main" className="main">
        <div className='d-flex flex-column'>
          <div>
            <button className='btn btn-primary btn-sm py-1 me-2'>RESET</button>
            <button className='btn btn-primary btn-sm py-1'>REAPPLY</button>
          </div>
          <div>
            <TempFilters />
          </div>
        </div>
        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>
                  <div className='row'>
                    <div className='col-lg-8 col-md-12 col-sm-12'>
                    {/* <div className="scroll-x mb-3"> */}
                    <table className="table border-collape benchmark-table reset-td-colors">
                      <thead>
                        <tr className='text-center '>
                          <th scope="col" className='bg-primary text-nowrap' >Job Grade</th>
                          <th scope="col" colSpan={24} className='text-start bg-tertiary'>Reward Benchmark Grade</th>

                        </tr>
                        <tr className='text-center'>
                          <th scope="col" className='text-dark border-0'></th>
                          <th scope="col" className='text-dark'>A1</th>
                          <th scope="col" className='text-dark'>B2</th>
                          <th scope="col" className='text-dark'>B3</th>
                          <th scope="col" className='text-dark'>B4</th>
                          <th scope="col" className='text-dark'>B5</th>
                          <th scope="col" className='text-dark'>C1</th>
                          <th scope="col" className='text-dark'>C2</th>
                          <th scope="col" className='text-dark'>C3</th>
                          <th scope="col" className='text-dark'>C4</th>
                          <th scope="col" className='text-dark'>C5</th> 
                          <th scope="col" className='text-dark'>D1</th>
                          <th scope="col" className='text-dark'>D2</th>
                          <th scope="col" className='text-dark'>D3</th>
                          <th scope="col" className='text-dark'>D4</th>
                          <th scope="col" className='text-dark'>D5</th>
                          <th scope="col" className='text-dark'>E1</th>
                          <th scope="col" className='text-dark'>E2</th>
                          <th scope="col" className='text-dark'>E3</th>
                          <th scope="col" className='text-dark'>E4</th>
                          <th scope="col" className='text-dark'>E5</th>
                          <th scope="col" className='text-dark'>F1</th>
                          <th scope="col" className='text-dark'>F2</th>
                          <th scope="col" className='text-dark'>F3</th>
                        </tr>
                      </thead>
                      <tbody>

                        {rewardBenchmarkGrade.map((item, key) => (<>
                          <tr key={key} >
                            <td  className="text-start ps-2 border">{item.jobGrade}</td>
                            <td  className={item.a1?.color} >{item.a1?.value}</td>
                            <td  className={item.b2?.color} >{item.b2?.value}</td>
                            <td  className={item.b3?.color} >{item.b3?.value}</td>
                            <td  className={item.b4?.color} >{item.b4?.value}</td>
                            <td  className={item.b5?.color} >{item.b5?.value}</td>
                            <td  className={item.c1?.color} >{item.c1?.value}</td>
                            <td  className={item.c2?.color} >{item.c2?.value}</td>
                            <td  className={item.c3?.color} >{item.c3?.value}</td>
                            <td  className={item.c4?.color} >{item.c4?.value}</td>
                            <td  className={item.c5?.color} >{item.c5?.value}</td>
                            <td  className={item.d1?.color} >{item.d1?.value}</td>
                            <td  className={item.d2?.color} >{item.d2?.value}</td>
                            <td  className={item.d3?.color}>{item.d3?.value}</td>
                            <td onClick={()=>{item.d4?.value==3? setShow(!show):console.log(2)}} className={item.d4?.color} >{item.d4?.value}</td>
                            <td  className={item.d5?.color} >{item.d5?.value}</td>
                            <td  className={item.e1?.color} >{item.e1?.value}</td>
                            <td  className={item.e2?.color} >{item.e2?.value}</td>
                            <td  className={item.e3?.color} >{item.e3?.value}</td>
                            <td  className={item.e4?.color} >{item.e4?.value}</td>
                            <td  className={item.e5?.color} >{item.e5?.value}</td>
                            <td  className={item.f1?.color} >{item.f1?.value}</td>
                            <td  className={item.f2?.color} >{item.f2?.value}</td>
                            <td  className={item.f3?.color} >{item.f3?.value}</td>

                          </tr>
                        </>))}

                       
                      </tbody>
                    </table>
                  {/* </div> */}
                    </div>
                    <div className='col-lg-4 col-md-12 col-sm-12'>
                    {/* <div className='card'>

                <div className='card-body'> */}

                    <div className="scroll-x mb-3">
                      <table className="table  border-collape reset-td-colors">
                        <thead>
                          <tr className='text-center '>
                            <th scope="col" className='text-start bg-green' style={{height:"58px"}}>Aligned Grade</th>
                            <th scope="col" className='text-start bg-orange'>Below Grade</th>
                            <th scope="col" className='text-start bg-brown'>In Range</th>
                            <th scope="col" className='text-start bg-purple'>Ahead Grade</th>
                            <th scope="col" className='text-start bg-grey'>Unknown</th>
                          </tr>
                        </thead>
                        <tbody>

                          {grades.map((item, key) => (<>
                            <tr key={key} >
                              <td className="text-start ps-2" >{item.alignedGrade}</td>
                              <td className="text-start ps-2">{item.belowGrade}</td>
                              <td className="text-start ps-2">{item.inRange}{ }</td>
                              <td className="text-start ps-2">{item.aheadGrade}</td>
                              <td className="text-start ps-2">{item.unknown}</td>

                            </tr>
                          </>))}
                        </tbody>
                      </table>
                      {/* </div>

                </div> */}
                    </div>
                  </div>
                  </div>




                 

                </div>
              </div>
            </div>



            <div className='col-lg-12 col-md-6 col-sm-12'>
              <div className='card'>

                <div className='card-body'>

                  <div className="scroll-x mb-3">
                    <table className="table  border-collape table-striped reset-td-colors">
                      <thead>
                        <tr className='text-center bg-primary'>
                          <th scope="col" className='text-start'>Emp #</th>
                          <th scope="col" className='text-start'>Name</th>
                          <th scope="col" className='text-start'>Job Title</th>
                          <th scope="col" className='text-start'>Line Manager</th>
                          <th scope="col" className='text-start'>Business Area</th>
                          <th scope="col" className='text-start'>Business Unit</th>

                        </tr>
                      </thead>
                      <tbody>

                        {show && employees.map((item, key) => (<>
                          <tr key={key} >
                            <td className="text-start ps-2" >{item.empNo}</td>
                            <td className="text-start ps-2">{item.name}</td>
                            <td className="text-start ps-2">{item.jobTitle}{ }</td>
                            <td className="text-start ps-2">{item.lineManager}</td>
                            <td className="text-start ps-2">{item.businessArea}</td>
                            <td className="text-start ps-2">{item.businessUnit}</td>
                          </tr>
                        </>))}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default OutliersJobgradingVSBenchmark;
