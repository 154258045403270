import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TempFilters from "../../components/Filters/TempFilters";

const TurnOverAnalysis = () => {
    const categories = [
        'Top Management',
        'Senior Management',
        'Professional / Middle <br/> Management',
        'Technical / Junior <br/>  Management',
        'Semi-Skilled',
        'Unskilled',
    ];
    const data = [0, 2.2, 36.8, 59.9, 1.1, 0.0];
    const size = {
    };

    const categories2 = [
        'Not Achieve',
        'Partially Achieved',
        'Fully Achieved',
        'Exceptionally Achieved',
        'Too Early to Assess',
    ];
    const data2 = [0, 10.8, 55.0, 8.2, 26.0];
    const size2 = {
        width: 300,
        height: 500,
    };

    const categories3 = [
        '00 - 02',
        '03 - 05',
        '06 - 10',
        '11 - 20',
        '21 - 30',
        '31+',
    ];
    const data3 = [24.5, 26.0, 26.4, 13.4, 4.8, 4.8];

    const categories4 = [
        '18 - 24',
        '25 - 29',
        '30 - 39',
        '40 - 49',
        '50 - 59',
        '60+',
    ];
    const data4 = [3.0, 11.9, 42.0, 22.3, 11.2, 9.7];

    const categories5 = [
        'African',
        'Indian',
        'Coloured',
        'White',
    ];
    const data5 = [56.9, 8.2, 21.2, 13.8];
    const size5 = {
        width: 300,
        height: 500,
    }

    const getOptions = (type, title, data, categories, size) => ({
        chart: {
            type,
            backgroundColor: 'white',
        },
        plotOptions: {
            column: {
                colorByPoint: false
            },
            series: {
                borderRadius: '25%'
            }
        },
        title: {
            text: `${title}`,
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        yAxis: {
            title: {
                text: 'Percentages(%)',
            },
        },
        xAxis: {
            categories: categories,
            labels: {
                // reserveSpace: false,
                //rotation: -27,
                style: {
                    fontSize: '12px', // Adjust font size if needed
                    whiteSpace: 'normal', // Allow labels to wrap when long
                    overflow: 'justify',
                },
                tickWidth: 0,
            },
        },
        series: [
            {
                data: data,
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                },
                color: '#6C9BBC',
            },
        ],
    });

    const averageDistribution = {
        type: 'column',
        title: '',
        categories: ["Jan","Feb","Mar","Apr","May","Jun" ],
        colorByPoint: false,
        colors: ['#4D91B6', '#E7E6E5'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: true,
        yAxisTitleText:'',
        series: [
            {
                name: '2023',
                data: [38,38, 23,46,64,60,],
                dataLabels: {
                    enabled: true,
                    format: '{y}',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#4D91B6',
            },
            {
                name: '2022',
                data: [45,60,109,31,9,139,],
                dataLabels: {
                    enabled: true,
                    format: '{y}',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#E7E6E5',
            }
        ]
    };

    const perGender = {
        type: 'column',

        title: '',
        categories: ["Female","Male" ],
        colorByPoint: true,
        colors: ['#4D91B6', '#74BBC9'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: false,
        series: [
            {
                data:[58.4, 41],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                colors: ['#4D91B6', '#74BBC9'],
            }
        ]
    };

    const getOptions3 = (props) => ({
        chart: {
            type: props.type,
            backgroundColor: 'white',
        },
        plotOptions: {
            column: {
                colorByPoint: props.colorByPoint
            },
            series: {
                borderRadius: '25%'
            }
        },
        colors: props.colors,
        title: {
            text: props.title
        },
        credits: {
            enabled: props.creditsEnabled
        },
        legend: {
            enabled: props.legendEnabled
        },
        yAxis: {
            title: {
                text: props.yAxisTitleText === undefined ? 'Percentages(%)' : props.yAxisTitleText,
            },
        },
        xAxis: {
            categories: props.categories,
            labels: {
                style: {
                    fontSize: '12px', // Adjust font size if needed
                    whiteSpace: 'normal', // Allow labels to wrap when long
                    overflow: 'scroll',
                },
                tickWidth: 0,
            },
        },
        series: props.series,
    });

    return (
        <>
            <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Workforce Analysis / Turnover Analysis"} />
            <main id="main" className="main">
                <div className='d-flex flex-column'>
                    <div>
                        <button className='btn btn-primary btn-sm py-1 me-2'>RESET</button>
                        <button className='btn btn-primary btn-sm py-1'>REAPPLY</button>
                    </div>
                    <div>
                        <TempFilters />
                    </div>
                </div>
                <section className="section ">
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Exits Distribution</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <HighchartsReact highcharts={Highcharts} options={getOptions3(averageDistribution)} />
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckDefault" checked />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                Compare same period last year
                                            </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Exits by Performance Rating</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                    <HighchartsReact highcharts={Highcharts} options={getOptions('column', '', data2, categories2)} />

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Exit by Years of Service</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                    <HighchartsReact highcharts={Highcharts} options={getOptions('column', '', data3, categories3)} />

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Exit by Age</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                    <HighchartsReact highcharts={Highcharts} options={getOptions('column', '', data4, categories4)} />

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Exit by Employee Level</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <HighchartsReact highcharts={Highcharts} options={getOptions('column', '', data, categories, size)} />

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Exit by Race</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                    <HighchartsReact highcharts={Highcharts} options={getOptions('column', '', data5, categories5, size5)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Exit by Gender</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                    <HighchartsReact highcharts={Highcharts} options={getOptions3(perGender)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
export default TurnOverAnalysis;
