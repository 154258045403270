import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Header from "../Header";
import TempFilters from "../../components/Filters/TempFilters";

const StaffComposition = () => {

  useEffect(() => {
    const fetchData = async () => {
      try {

      } catch (error) {

      }
    };

    fetchData();
  }, []);

  const chartProps1 = {
    type: 'column',
    title: '',
    categories: [ '00 - 02', '03 - 05', '06 - 10', '11 - 20', '21 - 30', '31+',],
    colorByPoint: false,
    colors: ['#5BBDCB'],
    creditsEnabled: false,
    credits:'',
    legendEnabled:false,
    series: [
      {
        data: [20.0, 28.3, 26.7, 13.3, 8.3],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#5BBDCB',
      },
    ]
  };

  const chartProps2 = {
    type: 'bar',
    title: '',
    data: [0.3, 3.3, 32.4, 63.4, 0.5, 0.1],
    categories: [ 'Top Management', 'Senior Management', 'Professional / Middle Management', 'Technical / Junior Management', 'Semi-Skilled', 'Unskilled'],
    colorByPoint: false,
    colors: ['#5BBDCB','#4D91B6'],
    creditsEnabled: false,
    credits:'',
    legendEnabled:true,
    series: [
      {
        name: 'EE Staff Categories',
        data: [0.3, 3.3, 32.4, 63.4, 0.5, 0.1],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#5BBDCB',
      }
    ]
  };

  const chartProps3 = {
    type: 'column',
    title: '',
    categories: ['African', 'Indian', 'Coloured', 'White'],
    colorByPoint: false,
    colors: ['#5BBDCB','#4D91B6'],
    creditsEnabled: false,
    credits:'',
    legendEnabled:false,
    series: [{
        name: 'Intakes',
        data: [52.8, 13.9, 16.0, 17.3],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#5BBDCB',
      },
    ]
  };

  const headcountByAge = {
    type: 'column',
    title: '',
    categories: [ '18 - 24', '25 - 29', '30 - 39', '40 - 49', '50 - 59', '60+', '40.5 yrs'],
    colorByPoint: true,
    colors: ['#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#E88B00',],
    creditsEnabled: false,
    credits:'',
    legendEnabled:false,
    series: [
      {
        data: [2.0, 11.6, 36.0, 28.6, 20.3, 1.7, 50],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#5BBDCB',
      },
    ]
  };

  const headcountByTenure = {
    type: 'column',
    title: '',
    categories: [ '00 - 02', '03 - 05', '06 - 10', '11 - 20', '21 - 30', '31+', '10 yrs'],
    colorByPoint: true,
    colors: ['#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#E88B00',],
    creditsEnabled: false,
    credits:'',
    legendEnabled:false,
    series: [
      {
        data: [20.9, 16.9, 23.1, 25.8, 8.9, 4.4, 50],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#5BBDCB',
      },
    ]
  };

  const headcountByBusinessArea = {
    type: 'column',
    title: '',
    categories: [ 'Client &<br/>Adviser<br/>Experience',  'Client &<br/>Adviser<br/>Service Center', 'Employee<br/>Benefits', 'Information<br/>Technology', 'Institutional', 'Business<br/>Development', 'Finance', 'Investment<br/>Solutions', 'Compliance &<br/> Forensic', 'Human<br/>Capital', 'Actuarial &<br />Risk', 'Marketing &<br/>Communications', 'Retail Risk<br/>Solutions', 'Properties', 'Asset<br/>Management', 'Legal', 'CEO', 'Strategic<br/>Planning&<br/>Transformation', ],
    colorByPoint: false,
    colors: ['#5BBDCB','#4D91B6'],
    creditsEnabled: false,
    credits:'',
    legendEnabled:false,
    yAxisTitle:'',
    series: [
        {
        name: 'Headcount',
        data: [1144,1067, 443, 394, 321, 252, 252, 133, 116, 108, 106, 72, 65, 32, 23, 22, 3, 1],
        dataLabels: {
          enabled: true,
          format: '{y}',
          fontSize: 9,
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#5BBDCB',
      },
    ]
  };

  const getOptions = (props) => ({
    chart: {
      type: props.type,
      /*width: props.width===undefined ? 500 : props.width,
      height: props.height===undefined ? 400 : props.height,*/
      backgroundColor: 'white',
    },
    plotOptions: {
      column: {
        colorByPoint: props.colorByPoint
      },
      series: {
        borderRadius: '25%'
      }
    },
    colors: props.colors,
    title: {
      text: props.title
    },
    credits: {
      enabled: props.creditsEnabled
    },
    legend: {
      enabled: props.legendEnabled
    },
    yAxis: {
      title: {
        text: props.yAxisTitle!==undefined ? props.yAxisTitle : 'Percentages(%)',
      },
    },
    xAxis: {
      categories: props.categories,
      labels: {
        style: {
          fontSize: '10px', // Adjust font size if needed
          whiteSpace: 'normal', // Allow labels to wrap when long
          overflow: 'justify',
        },
        tickWidth: 0,
      },
    },
    series: props.series,
  });


  const selectMonth = (id) => {

    return  (
        <>
          <div className='mx-3 mb-4'>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name={`inlineRadioOptions${id}`} id={`inlineRadio1`}
                     value="option1" checked/>
              <span className="form-check-label">Current Month</span>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name={`inlineRadioOptions${id}`} id={`inlineRadio2`}
                     value="option2" />
              <span className="form-check-label">Previous Month</span>
            </div>
          </div>
        </>
    );
  }


  return (
    <>
      <Header label={"Workforce Analysis / Staff Composition"} />

      <main id="main" className="main">
        <TempFilters />
        <div className='row'>
          <div className='col-lg-7 col-md-7 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4"}>Headcount Composition</span>
                </div>
                <div className={"d-flex justify-content-center my-3"}>

                  <div className="table-container">
                    <table className={"custom-table-wa"}>
                      <tbody>
                      <tr>
                        <td  className={" text-end"}><i className="fa-solid fa-percent fs-4 fw-bolder"></i></td>
                        <td  className={"text-white fw-bold heading4"}>Oct '22</td>
                        <td  className={"text-white fw-bold heading4"}>Nov '22</td>

                        <td  className={"text-white fw-bold heading4"}>Dec '22</td>
                        <td  className={"text-white fw-bold heading4"}>Jan '23</td>

                        <td  className={"text-white fw-bold heading4"}>Feb '23</td>
                        <td  className={"text-white fw-bold heading4"}>Mar '23</td>

                        <td  className={"text-white fw-bold heading4"}>Apr '23</td>
                        <td  className={"text-white fw-bold heading4"}>May '23</td>
                        <td  className={"text-white fw-bold heading4"}>Jun '23</td>

                      </tr>
                      <tr>
                        <td  className={" text-end"}>Top Management <i className="fa-solid fa-square text-sky-blue"></i></td>
                        <td  className={"text-black fst-normal bg-col-4"}>12</td>
                        <td  className={"text-black fst-normal bg-col-4"}>12</td>
                        <td  className={"text-black fst-normal bg-col-4"}>12</td>
                        <td  className={"text-black fst-normal bg-col-4"}>12</td>
                        <td  className={"text-black fst-normal bg-col-3"}>12</td>
                        <td  className={"text-black fst-normal bg-col-3"}>12</td>
                        <td  className={"text-black fst-normal bg-col-4"}>12</td>
                        <td  className={"text-black fst-normal bg-col-4"}>12</td>
                        <td  className={"text-black fst-normal bg-col-5"}>12</td>

                      </tr>
                      <tr>
                        <td  className={" text-end"}>Senior Management <i className="fa-solid fa-square text-sky-blue"></i></td>
                        <td  className={"text-black fst-normal bg-col-4"}>153</td>
                        <td  className={"text-black fst-normal bg-col-4"}>155</td>
                        <td  className={"text-black fst-normal bg-col-4"}>152</td>
                        <td  className={"text-black fst-normal bg-col-4"}>151</td>
                        <td  className={"text-black fst-normal bg-col-3"}>150</td>
                        <td  className={"text-black fst-normal bg-col-3"}>151</td>
                        <td  className={"text-black fst-normal bg-col-4"}>151</td>
                        <td  className={"text-black fst-normal bg-col-4"}>150</td>
                        <td  className={"text-black fst-normal bg-col-5"}>150</td>

                      </tr>
                      <tr>
                        <td  className={" text-end"}>Professional / Middle Management <i className="fa-solid fa-square text-sky-blue"></i></td>
                        <td  className={"text-black fst-normal bg-col-4"}>1 513</td>
                        <td  className={"text-black fst-normal bg-col-4"}>1 517</td>
                        <td  className={"text-black fst-normal bg-col-4"}>1 510</td>
                        <td  className={"text-black fst-normal bg-col-4"}>1 505</td>
                        <td  className={"text-black fst-normal bg-col-3"}>1 499</td>
                        <td  className={"text-black fst-normal bg-col-3"}>1 499</td>
                        <td  className={"text-black fst-normal bg-col-4"}>1 494</td>
                        <td  className={"text-black fst-normal bg-col-4"}>1 476</td>
                        <td  className={"text-black fst-normal bg-col-5"}> 1476</td>

                      </tr>
                      <tr>
                        <td  className={" text-end"}>
                          <div className={"d-flex align-items-center justify-content-end"}>
                            <span className={"col-first"}>Technical / Junior Management</span> <i className="fa-solid fa-square text-sky-blue"></i>
                          </div>
                        </td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 849</td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 823</td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 819</td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 844</td>
                        <td  className={"text-black fst-normal bg-col-3"}>2 883</td>
                        <td  className={"text-black fst-normal bg-col-3"}>2 894</td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 905</td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 901</td>
                        <td  className={"text-black fst-normal bg-col-5"}>2 889</td>
                      </tr>

                      <tr>
                        <td  className={" text-end"}>
                          <div className={"d-flex align-items-center justify-content-end"}>
                            <span className={"col-first"}>Semi - Skilled</span> <i className="fa-solid fa-square text-sky-blue"></i>
                          </div>
                        </td>
                        <td  className={"text-black fst-normal bg-col-4"}>27</td>
                        <td  className={"text-black fst-normal bg-col-4"}>26</td>
                        <td  className={"text-black fst-normal bg-col-4"}>27</td>
                        <td  className={"text-black fst-normal bg-col-4"}>26</td>
                        <td  className={"text-black fst-normal bg-col-4"}>25</td>
                        <td  className={"text-black fst-normal bg-col-4"}>24</td>
                        <td  className={"text-black fst-normal bg-col-4"}>24</td>
                        <td  className={"text-black fst-normal bg-col-4"}>24</td>
                        <td  className={"text-black fst-normal bg-col-5"}>24</td>

                      </tr>

                      <tr>
                        <td  className={" text-end"}>Unskilled <i className="fa-solid fa-square text-sky-blue"></i></td>
                        <td  className={"text-black fst-normal bg-col-4"}>5</td>
                        <td  className={"text-black fst-normal bg-col-4"}>5</td>
                        <td  className={"text-black fst-normal bg-col-4"}>5</td>
                        <td  className={"text-black fst-normal bg-col-4"}>5</td>
                        <td  className={"text-black fst-normal bg-col-4"}>5</td>
                        <td  className={"text-black fst-normal bg-col-4"}>5</td>
                        <td  className={"text-black fst-normal bg-col-4"}>5</td>
                        <td  className={"text-black fst-normal bg-col-4"}>5</td>
                        <td  className={"text-black fst-normal bg-col-5"}>5</td>

                      </tr>

                      <tr>
                        <td  className={""}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>

                      </tr>

                      <tr>
                        <td  className={" text-end"}>Total Permanent <i className="fa-solid fa-square-check"></i></td>
                        <td  className={"text-black fst-normal bg-col-4"}>4 559</td>
                        <td  className={"text-black fst-normal bg-col-4"}>4 524</td>
                        <td  className={"text-black fst-normal bg-col-4"}>4 587</td>
                        <td  className={"text-black fst-normal bg-col-4"}>4 543</td>
                        <td  className={"text-black fst-normal bg-col-3"}>4 574</td>
                        <td  className={"text-black fst-normal bg-col-3"}>4 585</td>
                        <td  className={"text-black fst-normal bg-col-4"}>4 591</td>
                        <td  className={"text-black fst-normal bg-col-4"}>4 568</td>
                        <td  className={"text-black fst-normal bg-col-5"}>4 554</td>

                      </tr>

                      <tr>
                        <td  className={""}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>

                      </tr>

                      <tr>
                        <td  className={" text-end"}>Total Temp / Contr.<i className="fa-regular fa-square"></i></td>
                        <td  className={"text-black fst-normal bg-col-4"}>428</td>
                        <td  className={"text-black fst-normal bg-col-4"}>430</td>
                        <td  className={"text-black fst-normal bg-col-4"}>417</td>
                        <td  className={"text-black fst-normal bg-col-4"}>398</td>
                        <td  className={"text-black fst-normal bg-col-3"}>398</td>
                        <td  className={"text-black fst-normal bg-col-3"}>406</td>
                        <td  className={"text-black fst-normal bg-col-4"}>401</td>
                        <td  className={"text-black fst-normal bg-col-4"}>388</td>
                        <td  className={"text-black fst-normal bg-col-5"}>392</td>

                      </tr>

                      <tr>
                        <td  className={""}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>

                      </tr>

                      <tr>
                        <td  className={"text-end"}>---</td>
                        <td  className={"text-black fst-normal empty-col"}>---</td>
                        <td  className={"text-black fst-normal empty-col"}>---</td>
                        <td  className={"text-black fst-normal empty-col"}>---</td>
                        <td  className={"text-black fst-normal empty-col"}>---</td>
                        <td  className={"text-black fst-normal empty-col"}>---</td>
                        <td  className={"text-black fst-normal empty-col"}>---</td>
                        <td  className={"text-black fst-normal empty-col"}>---</td>
                        <td  className={"text-black fst-normal empty-col"}>---</td>
                        <td  className={"text-black fst-normal empty-col"}>---</td>

                      </tr>

                      <tr>
                        <td  className={""}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>

                      </tr>


                      <tr>
                        <td  className={" text-end"}>African <i className="fa-solid fa-square text-light-blue"></i></td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 318</td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 309</td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 308</td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 327</td>
                        <td  className={"text-black fst-normal bg-col-3"}>2 360</td>
                        <td  className={"text-black fst-normal bg-col-3"}>2 379</td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 391</td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 394</td>
                        <td  className={"text-black fst-normal bg-col-5"}>2 403</td>

                      </tr>
                      <tr>
                        <td  className={" text-end"}>Indian <i className="fa-solid fa-square text-light-blue"></i></td>
                        <td  className={"text-black fst-normal bg-col-4"}>644</td>
                        <td  className={"text-black fst-normal bg-col-4"}>643</td>
                        <td  className={"text-black fst-normal bg-col-4"}>642</td>
                        <td  className={"text-black fst-normal bg-col-4"}>642</td>
                        <td  className={"text-black fst-normal bg-col-3"}>637</td>
                        <td  className={"text-black fst-normal bg-col-3"}>638</td>
                        <td  className={"text-black fst-normal bg-col-4"}>642</td>
                        <td  className={"text-black fst-normal bg-col-4"}>634</td>
                        <td  className={"text-black fst-normal bg-col-5"}>635</td>

                      </tr>
                      <tr>
                        <td  className={" text-end"}>Coloured <i className="fa-solid fa-square text-light-blue"></i></td>
                        <td  className={"text-black fst-normal bg-col-4"}>767</td>
                        <td  className={"text-black fst-normal bg-col-4"}>766</td>
                        <td  className={"text-black fst-normal bg-col-4"}>760</td>
                        <td  className={"text-black fst-normal bg-col-4"}>763</td>
                        <td  className={"text-black fst-normal bg-col-3"}>769</td>
                        <td  className={"text-black fst-normal bg-col-3"}>763</td>
                        <td  className={"text-black fst-normal bg-col-4"}>757</td>
                        <td  className={"text-black fst-normal bg-col-4"}>746</td>
                        <td  className={"text-black fst-normal bg-col-5"}>729</td>

                      </tr>
                      <tr>
                        <td  className={" text-end"}>White <i className="fa-solid fa-square text-light-blue"></i></td>
                        <td  className={"text-black fst-normal bg-col-4"}>830</td>
                        <td  className={"text-black fst-normal bg-col-4"}>820</td>
                        <td  className={"text-black fst-normal bg-col-4"}>815</td>
                        <td  className={"text-black fst-normal bg-col-4"}>811</td>
                        <td  className={"text-black fst-normal bg-col-3"}>808</td>
                        <td  className={"text-black fst-normal bg-col-3"}>805</td>
                        <td  className={"text-black fst-normal bg-col-4"}>801</td>
                        <td  className={"text-black fst-normal bg-col-4"}>794</td>
                        <td  className={"text-black fst-normal bg-col-5"}>787</td>

                      </tr>

                      <tr>
                        <td  className={""}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>

                      </tr>

                      <tr>
                        <td  className={" text-end"}>AIC</td>
                        <td  className={"text-black fst-normal bg-col-4"}>3 729</td>
                        <td  className={"text-black fst-normal bg-col-4"}>3 718</td>
                        <td  className={"text-black fst-normal bg-col-4"}>3 710</td>
                        <td  className={"text-black fst-normal bg-col-4"}>3 732</td>
                        <td  className={"text-black fst-normal bg-col-3"}>3 766</td>
                        <td  className={"text-black fst-normal bg-col-3"}>3 780</td>
                        <td  className={"text-black fst-normal bg-col-4"}>3 790</td>
                        <td  className={"text-black fst-normal bg-col-4"}>3 774</td>
                        <td  className={"text-black fst-normal bg-col-5"}>3 767</td>

                      </tr>

                      <tr>
                        <td  className={""}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>

                      </tr>

                      <tr>
                        <td  className={" text-end"}>Female <i className="fa-solid fa-square text-dark-blue"></i></td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 748</td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 731</td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 729</td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 752</td>
                        <td  className={"text-black fst-normal bg-col-3"}>2 767</td>
                        <td  className={"text-black fst-normal bg-col-3"}>2 776</td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 785</td>
                        <td  className={"text-black fst-normal bg-col-4"}>2 774</td>
                        <td  className={"text-black fst-normal bg-col-5"}>2 762</td>
                      </tr>
                      <tr>
                        <td  className={" text-end"}>Male  <i className="fa-solid fa-square text-dark-blue"></i></td>
                        <td  className={"text-black fst-normal bg-col-4"}>1 811</td>
                        <td  className={"text-black fst-normal bg-col-4"}>1 807</td>
                        <td  className={"text-black fst-normal bg-col-4"}>1 796</td>
                        <td  className={"text-black fst-normal bg-col-4"}>1 791</td>
                        <td  className={"text-black fst-normal bg-col-3"}>1 807</td>
                        <td  className={"text-black fst-normal bg-col-3"}>1 809</td>
                        <td  className={"text-black fst-normal bg-col-4"}>1 806</td>
                        <td  className={"text-black fst-normal bg-col-4"}>1 794</td>
                        <td  className={"text-black fst-normal bg-col-5"}>1 792</td>
                      </tr>

                      <tr>
                        <td  className={""}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>
                        <td  className={"text-black fst-normal empty-col"}></td>

                      </tr>

                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-5 col-md-5 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4"}> EE Staff Categories</span>
                </div>
                <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps2)} />
              </div>
            </div>

          </div>

        </div>

        <div className={"row"}>

          <div className='col-lg-6 offset-3 col-md-12 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4"}>Headcount by</span> <span className={"fs-4 fw-bold"}> Race</span>
                </div>
                <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps3)} />
              </div>
            </div>

            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4"}>Headcount by</span> <span className={"fs-4 fw-bold"}> Gender</span>
                </div>
                <div className={"d-flex flex-column justify-content-center my-3 mx-3"}>
                  <div className={"d-flex justify-content-between align-items-center"}>
                    <span className={"fw-1 fs-1"}><i className="fa-solid fa-person-dress fw-1 fs-1"></i></span> <span className={"fw-1 fs-3"}>60.6%</span>
                  </div>
                  <div className={"d-flex justify-content-between align-items-center"}>
                    <span className={"fw-1 fs-1"}><i className="fa-solid fa-person fw-1 fs-1"></i></span> <span className={"fw-1 fs-3"}>39.4%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4 fw-bold"}>Headcount</span> <span className={"fs-4"}> by Age</span>
                </div>
                {selectMonth('1')}
                <HighchartsReact highcharts={Highcharts} options={getOptions(headcountByAge)} />
              </div>
            </div>
          </div>

          <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4"}>Headcount by</span> <span className={"fs-4 fw-bold"}>Tenure</span>
                </div>
                {selectMonth('2')}
                <HighchartsReact highcharts={Highcharts} options={getOptions(headcountByTenure)} />
              </div>
            </div>
          </div>

        </div>

        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4"}>Headcount by</span> <span className={"fs-4 fw-bold"}>Business Area</span>
                </div>
                <HighchartsReact highcharts={Highcharts} options={getOptions(headcountByBusinessArea)} />
              </div>
            </div>
          </div>
        </div>
      </main>

    </>
  )
}
export default StaffComposition;
