import Dropdown from "../DropDown/Dropdown";
import React from "react";


const IssueLogFilters = () => {

    return (
        <div className="bg-secondary ps-1 mb-3 rounded-1 py-1  my-4 border-r  d-flex align-items-center justify-content-between dynamic-filters-container">
            <div className="m-0 my-1 d-flex">
                <div className=" d-flex flex-wrap">
                    <Dropdown label={"Issue Category"} myClass={"mx-2 my-3"} filterItems={[{id:1,label:"Termination"},{id:2,label:"Update Employee Information"}]}/>
                    <Dropdown label={"Logged By"} myClass={"mx-2 my-3"} filterItems={[{id:3,label:"Lerato Bopape"}]}/>
                    <Dropdown label={"Status"} myClass={"mx-2 my-3"}  filterItems={[{id:4,label:"Closed"},{id:5,label:"Not Started"}]}/>
    
                </div>
            </div>
        </div>
    );
}
export default IssueLogFilters