import { useEffect, useState } from "react";
import ChatHistory from "./ChatHistory";
import { Last } from "react-bootstrap/esm/PageItem";
// import bot from "../bot.png"

function Chatbot({ onClose, data, getConversation, conversation, onSubmit,onChange }) {
    const [interactWithHuman, setInteractWithHuman] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    var lastAnswer = null;

    const [typing, setTyping] = useState(false);
    const [showChats, setShowChats] = useState(false);
    const [value, setValue] = useState("");
    const [placeholder, setPlaceholder] = useState("Send A Question To Admin...")

    const onValueChange = (e) => {
        const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E6}-\u{1F1FF}]/u;

        if (!emojiRegex.test(e.target.value)) {
            // if ((validate(e.target.value, /^[a-zA-Z0-9\s.,!?"-]+$/) || e.target.value === "")) {
            setValue(e.target.value);
            onChange && onChange(e.target.value)
            // }
            setPlaceholder("Send A Question To Admin...")
        } else {
            setPlaceholder("Invalid message! Emojis are not allowed.")
            setValue("");
        }
    }


    const submitAnswer = (e) => {
        e.preventDefault();

        if (value.length > 1) {

            onSubmit && onSubmit()

            showUserInput(value);
            setPlaceholder("Send A Question To Admin...");
        } else {
            setValue("");
            setPlaceholder("Invalid message length! A minimum of 2 characters is allowed.");
        }
        setValue("");
    }


    const showUserInput = (input) => {
        var temp = chatHistory;
        setChatHistory(temp.concat([
            {
                placeholder: input,
                options: [],
                type: "userInput"
            }]
        ));
    };
    const showpillars = () => {
        var pillars = [];
        pillars = data.map(item => item.pillar);

        var temp = chatHistory;

        if (chatHistory.length != 0) {
            setTimeout(() => {
                setChatHistory(
                    temp.concat(lastAnswer ? [lastAnswer,
                        {
                            placeholder:
                                chatHistory.length == 0
                                    ? "Hi! How can I help you?"
                                    : "Thank you for your time. Would you like to enquire about something else?",
                            options: pillars,
                            type: "pillars"
                        }
                    ] : [
                        {
                            placeholder:
                                chatHistory.length == 0
                                    ? "Hi! How can I help you?"
                                    : "Thank you for your time. Would you like to enquire about something else?",
                            options: pillars,
                            type: "pillars"
                        }
                    ])
                );
            }, 1000);
            setTyping(true);
        } else {
            setTimeout(() => {
                setChatHistory(
                    temp.concat([
                        {
                            placeholder:
                                chatHistory.length == 0
                                    ? "Hi! How can I help you?"
                                    : "Thank you for your time. Would you like to enquire about something else?",
                            options: pillars,
                            type: "pillars"
                        }
                    ])
                );
            }, 1000);
            setTyping(true);
        }
    };

    const showGroups = name => {
        var groups = [];
        const pillar = data.find(item => item.pillar == name);

        groups = pillar.questionGroups.map(item => item.group);

        var temp = chatHistory;

        setTimeout(() => {
            setChatHistory(
                temp.concat([
                    {
                        placeholder: "What interests you here?",
                        options: groups,
                        type: "groups",
                        pillar: name
                    }
                ])
            );
        }, 1000);
        setTyping(true);
    };

    const showQuestions = (_pillar, group) => {
        console.log({ _pillar, group });
        var questions = [];
        const pillar = data.find(item => item.pillar == _pillar);

        const groups = pillar.questionGroups.find(item => item.group == group);

        questions = groups.questions.map(item => item.question);

        var temp = chatHistory;

        setTimeout(() => {
            setChatHistory(
                temp.concat([
                    {
                        placeholder: "What is your question?",
                        options: questions,
                        type: "questions",
                        pillar: _pillar,
                        group: group
                    }
                ])
            );
        }, 1000);
        setTyping(true);
    };
    const showAnswer = (_pillar, group, _question) => {
        const pillar = data.find(item => item.pillar == _pillar);

        const groups = pillar.questionGroups.find(item => item.group == group);

        var answer = groups.questions.find(
            item => item.question == _question
        );

        var temp = chatHistory;

        setTimeout(() => {
            answer = {
                placeholder: answer.render ? answer.render() : answer.answer,
                options: [],
                type: "answer"
            }

            setChatHistory(
                temp.concat([
                    answer
                ])
            );
            lastAnswer = answer;
            console.log(lastAnswer);
        }, 1000);
        setTyping(true);
        showpillars()
    };

    useEffect(() => {
        if (chatHistory.length == 0) {
            showpillars();
        }
    }, []);
    useEffect(() => {
        if (typing) {
            setTimeout(() => {
                setTyping(false);
            }, 1000);
        }
    }, [typing]);

    useEffect(()=>{
getConversation && getConversation();
    },[])
    return (
        <>
            <div className=" flex-column w-100 card mb-0  position-relative chatbot-main-container">
                {/* {answer && (
                    <>
                        <div className=" chatbot-popup card px-3 ">
                            <div className="position-relative">
                                <div className="blockquote-custom-icon shadow-sm position-absolute">
                                    <i className="fas fa-envelope messages-icon"></i>
                                </div>
                            </div>
                            <div className=" pt-4">
                                {answer.render?answer.render():answer.answer}
                            </div>
                            <hr className="p-0 my-0 my-2" />
                            <div className="d-flex">
                                <button
                                    className="btn btn-sm btn-danger ms-auto mx-2"
                                    onClick={() => {
                                        setAnswer(null);
                                        showpillars();
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </>
                )} */}
                {showChats && <ChatHistory getConversation={getConversation} conversation={conversation} setShowChats={setShowChats} />}

                <div>
                    {/* <div className="chat-bot-header">
                        <span className="header-label"> betterMe Chatbot</span>
                        <button onClick={() => { setShowChats(true); }} className="ms-auto btn btn-md message-btn" >
                            <span className="fas fa-envelope"></span>
                        </button>
                        <button onClick={() => { onClose(); }} className="ms-auto btn btn-sm btn-danger btn-close"> X </button>
                    </div> */}
                    <header className="chatbot-header bg-primary d-flex">
                        <h6 className="ms-2">Chatbot</h6>
                        <span className="ms-auto me-3" onClick={() => setInteractWithHuman(!interactWithHuman)}>
                        Inbox
                            <i className={`fa-solid fa-md ms-2  ${interactWithHuman?"fa-xmark":"fa-envelope"}`}></i>
                        </span>
                    </header>
                </div>
                <div className="chatbot-container ms-auto  mb-0 w-100 flex-grow-1 chatbot-content overflow-y-auto">

                    {interactWithHuman && (
                        <div className="row m-2 pb-5 chatbot-body d-flex">

                            {conversation.map((item,key) => (
                                <>
                                <div key={key}> 
                                <div className="d-flex justify-content-end">
                                    <div
                                        type="text"
                                        className="chatbot-message d-flex typing px-2 userInput text-end"
                                    >
                                       {item.question}

                                    </div>
                                    <div className="bot-icon-container ">
                                        <i class="fa-solid fa-user mt-auto mb-2 px-2"></i>
                                    </div>
                                </div>
                                {item.answer &&    <div className="icon-message-container">
                                    <div className="bot-icon-container">
                                        <i class="fa-regular fa-user mt-auto mb-2 px-2"></i>
                                    </div>


                                    <div
                                        type="text"
                                        className="chatbot-message d-flex typing px-2 text-start"
                                    >
                                       {item.answer}

                                    </div>
                                </div>}
                              
                               
                                </div>
                               
                            </>))}

                        </div>
                    )}

                    {!interactWithHuman && (
                        <div className="row m-2 pb-5 chatbot-body d-flex">
                            {chatHistory.map((item, key) => (
                                <>

                                    {
                                        item.type == "userInput" ? (
                                            <>
                                                <div className="d-flex justify-content-end">
                                                    <div
                                                        type="text"
                                                        className="chatbot-message d-flex typing px-2 userInput"
                                                    >
                                                        {item.placeholder}
                                                    </div>
                                                    <div className="bot-icon-container ">
                                                        <i class="fa-solid fa-user mt-auto mb-2 px-2"></i>
                                                    </div>
                                                </div>
                                            </>
                                        ) :
                                            <div className="icon-message-container">
                                                <div className="bot-icon-container">
                                                    <i class="fa-solid fa-robot"></i>
                                                </div>
                                                <div
                                                    type="text"
                                                    className="chatbot-message d-flex typing px-2 text-start"
                                                >
                                                    {item.placeholder}
                                                </div>
                                            </div>
                                    }

                                    <div className="buttons-container d-flex flex-wrap">
                                        {item.type == "pillars" ? (
                                            <>
                                                {item.options.map((item, id) => (
                                                    <>
                                                        <button
                                                            className="chatbot-button"
                                                            onClick={() =>
                                                                showGroups(item)
                                                            }
                                                        >
                                                            {item}
                                                        </button>
                                                    </>
                                                ))}
                                            </>
                                        ) : item.type == "groups" ? (
                                            <>
                                                {item.options.map(
                                                    (questionGroup, id) => (
                                                        <>
                                                            <button
                                                                className="chatbot-button"
                                                                onClick={() => {
                                                                    showQuestions(
                                                                        item.pillar,
                                                                        questionGroup
                                                                    );
                                                                }}
                                                            >
                                                                {questionGroup}
                                                            </button>
                                                        </>
                                                    )
                                                )}
                                            </>
                                        ) : item.type == "questions" ? (
                                            <>
                                                <div className="menu-options w-100 d-flex flex-wrap">
                                                    {item.options.map(
                                                        (question, id) => (
                                                            <>
                                                                <button
                                                                    className="chatbot-button text-start"
                                                                    onClick={() => {
                                                                        showAnswer(
                                                                            item.pillar,
                                                                            item.group,
                                                                            question
                                                                        );
                                                                    }}
                                                                >
                                                                    {question}
                                                                </button>
                                                            </>
                                                        )
                                                    )}
                                                </div>
                                            </>
                                        ) : ""}
                                    </div>
                                </>
                            ))}
                            <div className={`d-flex ${typing ? "" : "d-none"}`}>
                                <div className="bot-icon-container ">
                                    <i class="fa-solid fa-robot "></i>
                                </div>
                                <div
                                    type="text"
                                    className="chatbot-message  d-flex is-typing "
                                >
                                    {[1, 2, 3].map(() => (
                                        <span className="chatbot-typing p-1  my-auto "></span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                </div>

                <form className="chatbot-form" onSubmit={submitAnswer} >
                    <input
                        value={value}
                        onChange={onValueChange}
                        className="react-chatbot-kit-chat-input"
                        placeholder={placeholder}
                    />
                    <button type="submit" className="btn-primary">SEND</button>
                </form>
            </div>


        </>
    );
}

export default Chatbot;
