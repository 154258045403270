import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TempFilters from "../../components/Filters/TempFilters";

const LevelsOfWorkGradings = () => {

  const jobFamilites = [
    {
      jobFamily: "Actuarial",
      publishedJobsCount: 56,
      totalJobsCount: 4.6,
      linkedEmployeesCount: 215,
    },
    {
      jobFamily: "Brand, Marketing and Communications",
      publishedJobsCount: 79,
      totalJobsCount: 6.5,
      linkedEmployeesCount: 121,
    },
    {
      jobFamily: "Business Support Services",
      publishedJobsCount: 89,
      totalJobsCount: 7.3,
      linkedEmployeesCount: 303,
    },
    {
      jobFamily: "Finance",
      publishedJobsCount: 104,
      totalJobsCount: 8.5,
      linkedEmployeesCount: 407,
    },
    {
      jobFamily: "Human Capital",
      publishedJobsCount: 63,
      totalJobsCount: 5.2,
      linkedEmployeesCount: 226,
    },
    {
      jobFamily: "Information Technology",
      publishedJobsCount: 135,
      totalJobsCount: 11.1,
      linkedEmployeesCount: 527,
    },
    {
      jobFamily: "Investment Management",
      publishedJobsCount: 140,
      totalJobsCount: 11.5,
      linkedEmployeesCount: 270,
    },
    {
      jobFamily: "Operations",
      publishedJobsCount: 228,
      totalJobsCount: 18.8,
      linkedEmployeesCount: '1 716',
    },
    {
      jobFamily: "Portfolio Management",
      publishedJobsCount: 32,
      totalJobsCount: 2.6,
      linkedEmployeesCount: 89,
    },
    {
      jobFamily: "Risk Management",
      publishedJobsCount: 117,
      totalJobsCount: 9.6,
      linkedEmployeesCount: 364,
    },
    {
      jobFamily: "Sales and Distribution",
      publishedJobsCount: 99,
      totalJobsCount: 8.1,
      linkedEmployeesCount: 529,
    },
    {
      jobFamily: "Strategy and Business Performance Management",
      publishedJobsCount: 68,
      totalJobsCount: 5.6,
      linkedEmployeesCount: 145,
    }
  ];
  const categories2 = [
    '1 <br/> I Do',
    '1 <br/> I Manage',
    '1 <br/> I Drive Principles',
    '1 <br/> I Integrate',
    '1 <br/> I Influence',
  ];
  const data2 = [62.2, 20.3, 10.9, 3.2, 0.2];
  const getOptions = (type, title, data, categories, size) => ({
    chart: {
      type,
      backgroundColor: 'white',
    },
    plotOptions: {
      column: {
        colorByPoint: false
      },
      series: {
        borderRadius: '25%'
      }
    },
    title: {
      text: `${title}`,
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    yAxis: {
      title: {
        text: 'Percentages(%)',
      },
    },
    xAxis: {
      categories: categories,
      labels: {
        // reserveSpace: false,
        //rotation: -27,
        style: {
          fontSize: '12px', // Adjust font size if needed
          whiteSpace: 'normal', // Allow labels to wrap when long
          overflow: 'justify',
        },
        tickWidth: 0,
      },
    },
    series: [
      {
        data: data,
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        color: '#6C9BBC',
      },
    ],
  });

 

  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Work Architecture / Job Families, Levels of Work & Grading"} />

      <main id="main" className="main">
        <div className='d-flex flex-column'>
          <div>
            <button className='btn btn-primary btn-sm py-1 me-2'>RESET</button>
            <button className='btn btn-primary btn-sm py-1'>REAPPLY</button>
          </div>
          <div>
            <TempFilters />
          </div>
        </div>
        <section className="section ">
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <div className='card'>

                <div className='card-body'>
                <h5 className="">Job Families and Jobs</h5>

                  <div className="scroll-x mb-3">
                    <table className="table  custom-table">
                      <thead>
                        <tr className='text-center'>
                          <th scope="col" className='text-start'>Job Family</th>
                          <th scope="col" className='text-center'># of Published Jobs</th>
                          <th scope="col" className='text-center'>% of all Jobs</th>
                          <th scope="col" className='text-center'>Linked # of Employees</th>
                        </tr>
                      </thead>
                      <tbody>

                        {jobFamilites.map((item, key) => (<>
                          <tr key={key} >
                            <td className="text-start ps-2 text-nowrap pe-2" >{item.jobFamily}</td>
                            <td>{item.publishedJobsCount}</td>
                            <td>{item.totalJobsCount}</td>
                            <td>{item.linkedEmployeesCount}</td>
                          </tr>
                        </>))}

                        <tr className="row-empty">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr className="fw-bold ">
                          <td className='text-align-end'>TOTAL</td>
                          <td>1 210</td>
                          <td>100</td>
                          <td>4 912</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <div className='card'>



                <div className='card-body'>
                  <div className="mx-1 mb-4">
                    <div className="d-flex justify-content-between">
                      <h5 className="">% of Employees per Level of Work</h5>
                      <div className="d-flex justify-content-center align-items-center chart-controls">
                        <span className="py-1">
                          <i className="fa-solid fa-expand"></i>
                        </span>
                      </div>
                    </div>

                  </div>

                  <HighchartsReact highcharts={Highcharts} options={getOptions('column', '', data2, categories2)} />

                </div>

              </div>
            </div>

            <div className='col-lg-10 col-md-11 col-sm-12'>
              <div className='card'>
              <div className='card-body'>
              <h5 className="">Levels of Work</h5>

              <div className="scroll-x mb-3">
                  <table className="table levels-of-work">
                    <thead>
                      <tr className='text-center'>
                        <th className='border-0' colSpan={5}></th>
                        <th  colSpan={2} scope="col" className='text-start bg-black text-center'>Grading</th>
                      </tr>
                      <tr >
                        <th className='bg-tertiary'>Level of Work</th>
                        <th className='bg-tertiary'>Work Theme</th>
                        <th className='bg-tertiary'>EE Level</th>
                        <th className='bg-secondary'>Time Horizontal</th>
                        <th className='bg-secondary'># of Employees</th>
                        <th className='bg-grey'>Paterson</th>
                        <th className='bg-grey'>Hay Reward Level</th>
                      </tr>
                    </thead>
                    <tbody>
                      

                      <tr >
                        <td >5H</td>
                        <td className='text-start  text-nowrap'>Strategic Leadership (Divisional): Multi Geographical Clustered ‘Brands’</td>
                        <td className="bg-tertiary-light" rowSpan={3}>Top Management</td>
                        <td className="bg-secondary-light" rowSpan={3}>5 - 7 Years</td>



                        <td className="bg-secondary-light">1</td>
                        <td className='bg-light-grey' rowSpan={3}>F5, F4, F3</td>

                        <td className='bg-light-grey'>28</td>
                      </tr>

                      <tr >
                        <td >5L</td>
                        <td className='text-start  text-nowrap'>Organisational Strategic Leadership within a Division: ‘Brand Specific’</td>

                        <td className="bg-secondary-light">8</td>
                        <td className='bg-light-grey'>27, 26</td>
                      </tr>

                      <tr >
                        <td>5S</td>
                        <td className='text-start  text-nowrap'>Organisational Strategic Leadership Specialisation</td>

                        <td className="bg-secondary-light">0</td>
                        <td className='bg-light-grey'>25</td>
                      </tr>



                      {/* 2 */}

                      <tr >
                        <td >4MF</td>
                        <td className='text-start  text-nowrap'>Functional Horisontal Leadership: Multi Value Chain Integration</td>
                        <td className="bg-tertiary-light" rowSpan={3}>Senior Management</td>
                        <td rowSpan={3} className="bg-secondary-light">3 - 5 years</td>

                        <td className="bg-secondary-light">20</td>
                        <td className='bg-light-grey' rowSpan={3}>E5, E4, E3, E2, E1</td>

                        <td className='bg-light-grey' rowSpan={2}>24, 23. 22, 21, 20</td>
                      </tr>

                      <tr >
                        <td >4SF</td>
                        <td  className='text-start text-nowrap'>Functional Horisontal Leadership: Multi Value Chain Integration</td>

                        <td className="bg-secondary-light">52</td>
                      </tr>

                      <tr >
                        <td>4S</td>
                        <td className='text-start  text-nowrap'>Functional Value Chain Area Specialisation</td>

                        <td className="bg-secondary-light">46</td>
                        <td className='bg-light-grey'>22, 21, 20</td>
                      </tr>


                      {/* 3 */}
                      <tr >
                        <td >3MP</td>
                        <td className='text-start  text-nowrap'>Functional Horisontal Leadership: Multi Value Chain Integration</td>
                        <td className="bg-tertiary-light" rowSpan={4}>Professionally Qualified / Experienced Specialists / Middle Management</td>
                        <td rowSpan={3} className="bg-secondary-light">1 - 3 years</td>

                        <td className="bg-secondary-light">94</td>
                        <td className='bg-light-grey' rowSpan={3}>D5,D4,D3</td>

                        <td className='bg-light-grey' rowSpan={3}>19, 18</td>
                      </tr>

                      <tr >
                        <td >3SP</td>
                        <td  className='text-start  text-nowrap'>Organisational Strategic Leadership within a Division: ‘Brand Specific’</td>
                        <td className="bg-secondary-light">49</td>

                      </tr>

                      <tr >
                        <td>3S</td>
                        <td className='text-start  text-nowrap'>Organisational Strategic Leadership Specialisation</td>
                        <td className="bg-secondary-light">176</td>
                      </tr>

                      <tr>
                        <td>2</td>
                        <td className='text-start  text-nowrap'>Organisational Strategic Leadership Specialisation</td>
                        <td rowSpan={2} className="bg-secondary-light">1 - 12 Months</td>
                        <td className="bg-secondary-light">264</td>
                        <td className='bg-light-grey' rowSpan={2}>D2, D1, C5, C4, C3</td>
                        <td className='bg-light-grey' rowSpan={2}>17, 16, 15, 14</td>
                      </tr>

                      {/* 4 */}
                      <tr >
                        <td >2S</td>
                        <td className='text-start  text-nowrap'>Service Delivery Specialisation – Complex Processes with Discretion</td>
                        <td className="bg-tertiary-light" rowSpan={3}>Skilled, Technical & Qualified Employees, Junior Management, Supervisors, Foremen, Superintendants</td>
                        <td  className="bg-secondary-light">569</td>
                      </tr>

                      <tr >
                        <td >1H</td>
                        <td className='text-start  text-nowrap'>Service Delivery Co-ordination & Quality Orientation (Team Leader)</td>
                        <td className="bg-secondary-light" rowSpan={4}>Daily up to 12 Months</td>
                        <td className="bg-secondary-light">97</td>

                        <td className='bg-light-grey' rowSpan={2}>C1, B5, B4, B3</td>
                        <td className='bg-light-grey' rowSpan={2}>13, 12, 11, 10, 9</td>


                      </tr>
                      <tr >
                        <td>1S</td>
                        <td className='text-start  text-nowrap'>Transactional Specialisation – Complex with no Discretion</td>
                        <td className="bg-secondary-light">977</td>
                      </tr>


                      <tr>
                        <td>1M</td>
                        <td className='text-start  text-nowrap'>Quality Orientation: Transactional Value and Knowledge Worker</td>
                        <td className="bg-tertiary-light">Semi-Skilled</td>
                        <td className="bg-secondary-light">46</td>
                        <td className='bg-light-grey' rowSpan={2}>B2, B1, A3, A2, A1</td>
                        <td className='bg-light-grey' rowSpan={2}>8, 7, 6, 5, 4</td>
                      </tr>

                      <tr>
                        <td>1L</td>
                        <td className='text-start  text-nowrap'>Quality Orientation: General Worker</td>
                        <td className="bg-tertiary-light">Unskilled</td>
                        <td className="bg-secondary-light">11</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>

               
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default LevelsOfWorkGradings;
