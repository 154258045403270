import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Header from "../Header";
import TempFilters from "../../components/Filters/TempFilters";

Highcharts.Templating.helpers.abs = value => Math.abs(value);

const HeadcountParity = () => {

  useEffect(() => {
    const fetchData = async () => {
      try {

      } catch (error) {

      }
    };

    fetchData();
  }, []);

  const compaRatioGender = {
    type: 'column',
    title: '',
    categories: [
      'Client & Adviser<br/> Experience',
      'Business Development',
      'Investment Solutions',
      'Retail',
      'Finance',
      'Information Technology',
      'Retail Risk<br/>Solutions',
      'Legal',
      'Human Capital',
      'Marketing &<br/>Communications',
      'Operations',
    ],
    colorByPoint: false,
    colors: ['#4D91B6', '#009CAE'],
    creditsEnabled: false,
    credits: '',
    legendEnabled: true,
    yAxisTitleText:'',
    series: [
      {
        name: 'Female',
        data: [40,45,20,50, 50, 55,10, 70, 5, 75, 65],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#009CAE',
      },
      {
        name: 'Male',
        data: [60,55, 80, 50,50, 40,90, 30, 95, 25, 35],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#4D91B6',
      }
    ]
  };

  const getOptions3 = (props) => ({
    chart: {
      type: props.type,
      backgroundColor: 'white',
    },
    plotOptions: {
      column: {
        colorByPoint: props.colorByPoint
      },
      series: {
        borderRadius: '25%'
      }
    },
    colors: props.colors,
    title: {
      text: props.title
    },
    credits: {
      enabled: props.creditsEnabled
    },
    legend: {
      enabled: props.legendEnabled
    },
    yAxis: {
      title: {
        text: props.yAxisTitleText === undefined ? 'Percentages(%)' : props.yAxisTitleText,
      },
    },
    xAxis: {
      categories: props.categories,
      labels: {
        style: {
          fontSize: '12px', // Adjust font size if needed
          whiteSpace: 'normal', // Allow labels to wrap when long
          overflow: 'scroll',
        },
        tickWidth: 0,
      },
    },
    series: props.series,
  });

  const categories = ['Unskilled', 'Semi-Skilled', 'Technical / Junior Management', 'Professional / Middle Management', 'Senior Management', 'Top Management'];

  const getOptions = (categories) => ({
    chart: {
      type: 'bar'
    },
    title: {
      text: '',
      align: 'left'
    },
    subtitle: {},
    accessibility: {
      point: {
        valueDescriptionFormat: '{index}. Employee Level {xDescription}, {value}%.'
      }
    },
    xAxis: [{
      categories: categories,
      reversed: false,
      labels: {
        step: 1
      },
      accessibility: {
        description: 'Employee Level (male)'
      }
    }, { // mirror axis on right side
      opposite: true,
      reversed: false,
      categories: categories,
      linkedTo: 0,
      labels: {
        step: 1
      },
      accessibility: {
        description: 'Employee Level (female)'
      }
    }],
    yAxis: {
      title: {
        text: null
      },
      labels: {
        format: '{abs value}%'
      },
      accessibility: {
        description: 'Percentage Employee Level',
        // rangeDescription: 'Range: 0 to 5%'
      }
    },

    plotOptions: {
      series: {
        stacking: 'normal',
        // borderRadius: '50%'
      }
    },

    tooltip: {
      format: '<b>{series.name}, {point.category}</b><br/>' +
          'Population: {(abs point.y):.1f}%'
    },
    series: [{
      name: 'Male',
      dataLabels: {
        enabled: true,
        format: '{y}%',
        style: {
          color: 'black',
          textOutline: 'none'
        }
      },
      color: '#4D91B6',
      data: [0, 41.7, 32.9, 49.5, 60.7, 91.7]
    }, {
      name: 'Female',
      dataLabels: {
        enabled: true,
        format: '{y}%',
        style: {
          color: 'black',
          textOutline: 'none'
        }
      },
      color: '#009CAE',
      data: [-100, -58.3, -67.1, -50.5, -39.3, -8.3]
    }]
  });

  const getOptions2 = (categories) => ({
    chart: {
      type: 'bar'
    },
    title: {
      text: '',
      align: 'left'
    },
    subtitle: {},
    accessibility: {
      point: {
        valueDescriptionFormat: '{index}. Employee Level {xDescription}, {value}%.'
      }
    },
    xAxis: [{
      categories: categories,
      reversed: false,
      labels: {
        step: 1
      },
      accessibility: {
        description: 'Employee Level (male)'
      }
    }, { // mirror axis on right side
      opposite: true,
      reversed: false,
      categories: categories,
      linkedTo: 0,
      labels: {
        step: 1
      },
      accessibility: {
        description: 'Employee Level (female)'
      }
    }],
    yAxis: {
      title: {
        text: null
      },
      labels: {
        format: '{abs value}%'
      },
      accessibility: {
        description: 'Percentage Employee Level',
        // rangeDescription: 'Range: 0 to 5%'
      }
    },

    plotOptions: {
      series: {
        stacking: 'normal',
        // borderRadius: '50%'
      }
    },

    tooltip: {
      format: '<b>{series.name}, {point.category}</b><br/>' +
          'Population: {(abs point.y):.1f}%'
    },
    series: [
      {
        name: 'Male - White',
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#3F5B6D',
        data: [0, 0, 0.9, 13.0, 35.7, 70.7]
      },
      {
        name: 'Male - Coloured',
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'white',
            textOutline: 'none'
          }
        },
        color: '#01164E',
        data: [0, 0, 3, 7.1, 5, 0]
      },
      {
        name: 'Male - Indian',
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#449AAB',
        data: [0, 0, 1, 9.0, 10, 21]
      },
      {
        name: 'Male - African',
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#4D91B6',
        data: [0, 41.7, 28, 20, 10, 0]
      },












      {
        name: 'Female - White',
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#ACD6E0',
        data: [-0, -0, -7.1, -14.5, -16.3, -0]
      },
      {
        name: 'Female - Coloured',
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'white',
            textOutline: 'none'
          }
        },
        color: '#612889',
        data: [-50, -7.3, -15, -5, -3, -0]
      },
      {
        name: 'Female - Indian',
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#DB8E18',
        data: [-1, -3.3, -4.9, -10, -12, -0]
      },
      {
        name: 'Female - African',
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#67904B',
        data: [-49, -47.7, -40.1, -21, -10, -8.3]
      },

    ]
  });
  return (
    <>
      <Header label={"Workforce Analysis / Staff Analysis"} />

      <main id="main" className="main">
        <TempFilters />
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-1 mb-4">
                  <div className="d-flex justify-content-between">
                    <h5 className="">Gender Parity per Business Unit</h5>
                    <div className="d-flex justify-content-center align-items-center chart-controls">
                      <span className="py-1">
                          <i className="fa-solid fa-expand"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <HighchartsReact highcharts={Highcharts} options={getOptions3(compaRatioGender)} />

              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4"}>Gender Parity per Employee Level</span>
                </div>
                <HighchartsReact highcharts={Highcharts} options={getOptions(categories)} />
              </div>
            </div>
          </div>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4"}>Race Parity per Employee Level, by Gender</span>
                </div>
                <HighchartsReact highcharts={Highcharts} options={getOptions2(categories)} />
              </div>
            </div>
          </div>
        </div>
      </main>

    </>
  )
}
export default HeadcountParity;
