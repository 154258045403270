import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TempFilters from "../../components/Filters/TempFilters";

import HCMore from 'highcharts/highcharts-more'; // If you need additional chart types
import HighchartsExporting from 'highcharts/modules/exporting'; // For exporting the chart as an image or PDF
import HighchartsExportData from 'highcharts/modules/export-data'; // For exporting data as CSV or XLSX

// Initialize the additional modules
HCMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const files = [
    {id:1, name:"Export-Employment_equity_Detailed_EE_Report.xlsx", path:"./reports/Export-Employment_equity_Detailed_EE_Report.xlsx"},
    {id:2, name:"Compact-EE-Report-Employment-Equity-Export.xlsx", path:"./reports/Compact-EE-Report-Employment-Equity-Export.xlsx"},
];

const EmploymentEquity = () => {
    const [selectedOption, setSelectedOption] = useState('option1');

    const handleRadioChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleDownload = () => {
        const obj  = selectedOption === 'option2'? files[0]:files[1];

        const fileUrl = obj.path;

        // Create a hidden anchor element
        const a = document.createElement('a');
        a.href = fileUrl;

        // Specify the file name for download
        a.download =  obj.name;

        // Trigger a click event on the anchor element to initiate the download
        a.click();

        // Remove the anchor element from the DOM
        a.remove();
    };

    return (
        <>
            <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Workforce Analysis / Employment Equity"} />
            <main id="main" className="main">
                <div className='d-flex flex-column'>
                    <div>
                        <button className='btn btn-primary btn-sm py-1 me-2'>RESET</button>
                        <button className='btn btn-primary btn-sm py-1'>REAPPLY</button>
                    </div>
                    <div>
                        <TempFilters />
                    </div>
                </div>

                <section className="section ">
                    <div className='row'>

                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className="mx-1 mb-3">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Staff Profile vs. EAP Targets</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between align-content-center">
                                        <div className="">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio1"
                                                    value="option1"
                                                    checked={selectedOption === 'option1'}
                                                    onChange={handleRadioChange}
                                                />
                                                <label className="form-check-label" htmlFor="inlineRadio1">
                                                    Compact Report
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio2"
                                                    value="option2"
                                                    checked={selectedOption === 'option2'}
                                                    onChange={handleRadioChange}
                                                />
                                                <label className="form-check-label" htmlFor="inlineRadio2">
                                                    Detail Report
                                                </label>
                                            </div>

                                        </div>
                                        <button className="btn btn-primary" onClick={handleDownload}>
                                            <i className="fa-solid fa-table text-white"></i> Export Report
                                        </button>
                                    </div>

                                    <div className={"my-3"}>
                                        <div className="table-container">
                                            <table className={"ee-table"}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2} rowSpan={2} className="ee-header no-border "></th>
                                                        <th colSpan={5} className="ee-header bg-blue-grey font-weight-600 text-black">Male</th>
                                                        <th colSpan={5} className="ee-header bg-light-blue font-weight-600 text-black">Female</th>
                                                        <th rowSpan={2} className="ee-header bg-primary font-weight-600 ">TOTAL</th>
                                                        <th rowSpan={2} className="ee-header bg-primary font-weight-600 ">
                                                            <div className="d-flex flex-column"><span className="font-weight-600">% </span> <span className="font-weight-600">BLACK</span></div>
                                                        </th>
                                                        <th rowSpan={2} className="ee-header bg-primary font-weight-600 ">
                                                            <div className="d-flex flex-column"><span className="font-weight-600">% </span> <span className="font-weight-600">FEMALE</span></div>
                                                        </th>
                                                        <th rowSpan={2} className="ee-header bg-primary font-weight-600 ">
                                                            <div className="d-flex flex-column"><span className="font-weight-600">% </span> <span className="font-weight-600">AFRICAN</span></div>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th className="sub-header bg-blue-grey font-weight-600 text-black">African</th>
                                                        <th className="sub-header bg-blue-grey font-weight-600 text-black">Indian</th>
                                                        <th className="sub-header bg-blue-grey font-weight-600 text-black">Coloured</th>
                                                        <th className="sub-header bg-blue-grey font-weight-600 text-black">White</th>
                                                        <th className="sub-header bg-blue-grey font-weight-600 text-black">
                                                            <div className="d-flex flex-column"><span className="font-weight-600">Foreign </span> <span className="font-weight-600">National</span></div>
                                                        </th>

                                                        <th className="sub-header bg-light-blue font-weight-600 text-black">Africa</th>
                                                        <th className="sub-header bg-light-blue font-weight-600 text-black">Indian</th>
                                                        <th className="sub-header bg-light-blue font-weight-600 text-black">Coloured</th>
                                                        <th className="sub-header bg-light-blue font-weight-600 text-black">White</th>
                                                        <th className="sub-header bg-light-blue font-weight-600 text-black">
                                                            <div className="d-flex flex-column"><span className="font-weight-600">Foreign </span> <span className="font-weight-600">National</span></div>
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                <tr className={"empty-row"}></tr>

                                                <tr>
                                                    <td colSpan={10} className={"no-border"}></td>
                                                    <td colSpan={3} className={"bg-warning txt-align-end"}>FSC Target</td>
                                                    <td className={"bg-warning"}>60.0%</td>
                                                    <td className={"bg-warning"}>30.0%</td>
                                                    <td className={"bg-warning"}>52.0%</td>
                                                </tr>
                                                <tr>
                                                    <td rowSpan={3} className="bg-white">
                                                       <div className="d-flex flex-column">
                                                           <span className="fw-bold">Top</span>
                                                           <span className="fw-bold">Management</span>
                                                       </div>
                                                    </td>
                                                    <td className={"bg-white"}>Actual (number)</td>
                                                    <td className={"bg-male"}>1</td>
                                                    <td className={"bg-male"}>0</td>
                                                    <td className={"bg-male"}>3</td>
                                                    <td className={"bg-male"}>7</td>
                                                    <td className={"bg-male"}>2</td>
                                                    <td className={"bg-female"}>1</td>
                                                    <td className={"bg-female"}>1</td>
                                                    <td className={"bg-female"}>0</td>
                                                    <td className={"bg-female"}>1</td>
                                                    <td className={"bg-female"}>0</td>
                                                    <td className={"bg-primary text-white fw-bold"}>16</td>
                                                    <td className={"bg-primary text-white fw-bold"}>6</td>
                                                    <td className={"bg-primary text-white fw-bold"}>2</td>
                                                    <td className={"bg-primary text-white fw-bold"}>2</td>
                                                </tr>
                                                <tr>

                                                    <td className={"bg-white"}>Representation</td>
                                                    <td className={"bg-male"}>6.3%</td>
                                                    <td className={"bg-male"}>9.0%</td>
                                                    <td className={"bg-male"}>18.3%</td>
                                                    <td className={"bg-male"}>43.8%</td>
                                                    <td className={"bg-male"}>12.5%</td>
                                                    <td className={"bg-female"}>6.3%</td>
                                                    <td className={"bg-female"}>6.3%</td>
                                                    <td className={"bg-female"}>0.0%</td>
                                                    <td className={"bg-female"}>6.3%</td>
                                                    <td className={"bg-female"}>0.0%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>100%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>37.5%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>12.5%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>12.5%</td>
                                                </tr>
                                                <tr>

                                                    <td colSpan={12} className="txt-align-end fw-bold bg-light-gray">Variance</td>
                                                    <td className="fw-bold text-danger">- 22.5%</td>
                                                    <td className="fw-bold text-danger">- 17.5%</td>
                                                    <td className="fw-bold text-danger">39.5%</td>
                                                </tr>

                                                <tr className={"empty-row"}></tr>

                                                <tr>
                                                    <td colSpan={10} className={"no-border"}></td>
                                                    <td colSpan={3} className={"bg-warning txt-align-end"}>FSC Target</td>
                                                    <td className={"bg-warning"}>60.0%</td>
                                                    <td className={"bg-warning"}>30.0%</td>
                                                    <td className={"bg-warning"}>52.0%</td>
                                                </tr>
                                                <tr>
                                                    <td rowSpan={3} className="bg-white">
                                                       <div className="d-flex flex-column">
                                                           <span className="fw-bold">Senior</span>
                                                           <span className="fw-bold">Management</span>
                                                       </div>
                                                    </td>
                                                    <td className={"bg-white"}>Actual (number)</td>
                                                    <td className={"bg-male"}>20</td>
                                                    <td className={"bg-male"}>24</td>
                                                    <td className={"bg-male"}>9</td>
                                                    <td className={"bg-male"}>48</td>
                                                    <td className={"bg-male"}>2</td>
                                                    <td className={"bg-female"}>17</td>
                                                    <td className={"bg-female"}>14</td>
                                                    <td className={"bg-female"}>6</td>
                                                    <td className={"bg-female"}>21</td>
                                                    <td className={"bg-female"}>2</td>
                                                    <td className={"bg-primary text-white fw-bold"}>163</td>
                                                    <td className={"bg-primary text-white fw-bold"}>90</td>
                                                    <td className={"bg-primary text-white fw-bold"}>37</td>
                                                    <td className={"bg-primary text-white fw-bold"}>37</td>
                                                </tr>
                                                <tr>

                                                    <td className={"bg-white"}>Representation</td>
                                                    <td className={"bg-male"}>12.3%</td>
                                                    <td className={"bg-male"}>14.7%</td>
                                                    <td className={"bg-male"}>5.5%</td>
                                                    <td className={"bg-male"}>29.4%</td>
                                                    <td className={"bg-male"}>1.2%</td>
                                                    <td className={"bg-female"}>10.4%</td>
                                                    <td className={"bg-female"}>8.6%</td>
                                                    <td className={"bg-female"}>3.7%</td>
                                                    <td className={"bg-female"}>12.9%</td>
                                                    <td className={"bg-female"}>1.2%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>100%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>55.2%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>22.7%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>22.7%</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={12} className="txt-align-end fw-bold bg-light-gray">Variance</td>
                                                    <td className="fw-bold text-danger">- 4.8%</td>
                                                    <td className="fw-bold text-danger">- 7.3%</td>
                                                    <td className="fw-bold text-danger">- 29.3%</td>
                                                </tr>

                                                <tr className={"empty-row"}></tr>

                                                <tr>
                                                    <td colSpan={10} className={"no-border"}></td>
                                                    <td colSpan={3} className={"bg-warning txt-align-end"}>FSC Target</td>
                                                    <td className={"bg-warning"}>75.0%</td>
                                                    <td className={"bg-warning"}>38.0%</td>
                                                    <td className={"bg-warning"}>65.0%</td>
                                                </tr>
                                                <tr>
                                                    <td rowSpan={3} className="bg-white">
                                                       <div className="d-flex flex-column">
                                                           <span className="fw-bold">Professional /</span>
                                                           <span className="fw-bold">Middle</span>
                                                           <span className="fw-bold">Management</span>
                                                       </div>
                                                    </td>
                                                    <td className={"bg-white"}>Actual (number)</td>
                                                    <td className={"bg-male"}>237</td>
                                                    <td className={"bg-male"}>145</td>
                                                    <td className={"bg-male"}>76</td>
                                                    <td className={"bg-male"}>219</td>
                                                    <td className={"bg-male"}>13</td>
                                                    <td className={"bg-female"}>269</td>
                                                    <td className={"bg-female"}>142</td>
                                                    <td className={"bg-female"}>83</td>
                                                    <td className={"bg-female"}>173</td>
                                                    <td className={"bg-female"}>12</td>
                                                    <td className={"bg-primary text-white fw-bold"}>1 369</td>
                                                    <td className={"bg-primary text-white fw-bold"}>952</td>
                                                    <td className={"bg-primary text-white fw-bold"}>494</td>
                                                    <td className={"bg-primary text-white fw-bold"}>506</td>
                                                </tr>
                                                <tr>

                                                    <td className={"bg-white"}>Representation</td>
                                                    <td className={"bg-male"}>17.3%</td>
                                                    <td className={"bg-male"}>10.6%</td>
                                                    <td className={"bg-male"}>5.6%</td>
                                                    <td className={"bg-male"}>16.0%</td>
                                                    <td className={"bg-male"}>0.9%</td>
                                                    <td className={"bg-female"}>19.6%</td>
                                                    <td className={"bg-female"}>10.4%</td>
                                                    <td className={"bg-female"}>6.1%</td>
                                                    <td className={"bg-female"}>12.6%</td>
                                                    <td className={"bg-female"}>0.9%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>100%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>69.5%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>36.1%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>37.0%</td>
                                                </tr>
                                                <tr>

                                                    <td colSpan={12} className="txt-align-end fw-bold bg-light-gray">Variance</td>
                                                    <td className="fw-bold text-danger">- 4.8%</td>
                                                    <td className="fw-bold text-danger">- 7.3%</td>
                                                    <td className="fw-bold text-danger">- 29.3%</td>
                                                </tr>

                                                <tr className={"empty-row"}></tr>

                                                <tr>
                                                    <td colSpan={10} className={"no-border"}></td>
                                                    <td colSpan={3} className={"bg-warning txt-align-end"}>FSC Target</td>
                                                    <td className={"bg-warning"}>75.0%</td>
                                                    <td className={"bg-warning"}>38.0%</td>
                                                    <td className={"bg-warning"}>65.0%</td>
                                                </tr>
                                                <tr>
                                                    <td rowSpan={3} className="bg-white">
                                                       <div className="d-flex flex-column">
                                                           <span className="fw-bold">Technical /</span>
                                                           <span className="fw-bold">Junior</span>
                                                           <span className="fw-bold">Management</span>
                                                       </div>
                                                    </td>
                                                    <td className={"bg-white"}>Actual (number)</td>
                                                    <td className={"bg-male"}>567</td>
                                                    <td className={"bg-male"}>106</td>
                                                    <td className={"bg-male"}>170</td>
                                                    <td className={"bg-male"}>76</td>
                                                    <td className={"bg-male"}>15</td>
                                                    <td className={"bg-female"}>1 015</td>
                                                    <td className={"bg-female"}>190</td>
                                                    <td className={"bg-female"}>342</td>
                                                    <td className={"bg-female"}>245</td>
                                                    <td className={"bg-female"}>7</td>
                                                    <td className={"bg-primary text-white fw-bold"}>2 733</td>
                                                    <td className={"bg-primary text-white fw-bold"}>2 390</td>
                                                    <td className={"bg-primary text-white fw-bold"}>1 547</td>
                                                    <td className={"bg-primary text-white fw-bold"}>1 582</td>
                                                </tr>
                                                <tr>

                                                    <td className={"bg-white"}>Representation</td>
                                                    <td className={"bg-male"}>17.3%</td>
                                                    <td className={"bg-male"}>10.6%</td>
                                                    <td className={"bg-male"}>5.6%</td>
                                                    <td className={"bg-male"}>16.0%</td>
                                                    <td className={"bg-male"}>0.9%</td>
                                                    <td className={"bg-female"}>19.6%</td>
                                                    <td className={"bg-female"}>10.4%</td>
                                                    <td className={"bg-female"}>6.1%</td>
                                                    <td className={"bg-female"}>12.6%</td>
                                                    <td className={"bg-female"}>0.9%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>100%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>87.4%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>56.6%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>57.9%</td>
                                                </tr>
                                                <tr>

                                                    <td colSpan={12} className="txt-align-end fw-bold bg-light-gray">Variance</td>
                                                    <td className="fw-bold text-danger">- 0.6%</td>
                                                    <td className="fw-bold text-success">12.6%</td>
                                                    <td className="fw-bold text-danger">- 18.1%</td>
                                                </tr>

                                                <tr className={"empty-row"}></tr>

                                                <tr>
                                                    <td colSpan={10} className={"no-border"}></td>
                                                    <td colSpan={3} className={"bg-warning txt-align-end"}>FSC Target</td>
                                                    <td className={"bg-warning"}>88.0%</td>
                                                    <td className={"bg-warning"}>44.0%</td>
                                                    <td className={"bg-warning"}>76.0%</td>
                                                </tr>
                                                <tr>
                                                    <td rowSpan={3} className="bg-white">
                                                       <div className="d-flex flex-column">
                                                           <span className="fw-bold">Semi - Skilled</span>
                                                       </div>
                                                    </td>
                                                    <td className={"bg-white"}>Actual (number)</td>
                                                    <td className={"bg-male"}>71</td>
                                                    <td className={"bg-male"}>1</td>
                                                    <td className={"bg-male"}>23</td>
                                                    <td className={"bg-male"}>1</td>
                                                    <td className={"bg-male"}>3</td>
                                                    <td className={"bg-female"}>157</td>
                                                    <td className={"bg-female"}>20</td>
                                                    <td className={"bg-female"}>60</td>
                                                    <td className={"bg-female"}>21</td>
                                                    <td className={"bg-female"}>1</td>
                                                    <td className={"bg-primary text-white fw-bold"}>358</td>
                                                    <td className={"bg-primary text-white fw-bold"}>332</td>
                                                    <td className={"bg-primary text-white fw-bold"}>237</td>
                                                    <td className={"bg-primary text-white fw-bold"}>228</td>
                                                </tr>
                                                <tr>

                                                    <td className={"bg-white"}>Representation</td>
                                                    <td className={"bg-male"}>19.8%</td>
                                                    <td className={"bg-male"}>0.3%</td>
                                                    <td className={"bg-male"}>6.4%</td>
                                                    <td className={"bg-male"}>0.3%</td>
                                                    <td className={"bg-male"}>0.8%</td>
                                                    <td className={"bg-female"}>43.9%</td>
                                                    <td className={"bg-female"}>5.6%</td>
                                                    <td className={"bg-female"}>16.8%</td>
                                                    <td className={"bg-female"}>5.9%</td>
                                                    <td className={"bg-female"}>0.3%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>100%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>92.7%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>66.2%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>63.7%</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={12} className="txt-align-end fw-bold bg-light-gray">Variance</td>
                                                    <td className="fw-bold text-success">4.7%</td>
                                                    <td className="fw-bold text-success">22.2%</td>
                                                    <td className="fw-bold text-danger">- 12..3%</td>
                                                </tr>

                                                <tr className={"empty-row"}></tr>

                                                <tr>
                                                    <td colSpan={10} className={"no-border"}></td>
                                                    <td colSpan={3} className={"bg-warning txt-align-end"}>FSC Target</td>
                                                    <td className={"bg-warning"}>88.0%</td>
                                                    <td className={"bg-warning"}>44.0%</td>
                                                    <td className={"bg-warning"}>76.0%</td>
                                                </tr>
                                                <tr>
                                                    <td rowSpan={3} className="bg-white">
                                                       <div className="d-flex flex-column">
                                                           <span className="fw-bold">Unskilled</span>
                                                       </div>
                                                    </td>
                                                    <td className={"bg-white"}>Actual (number)</td>
                                                    <td className={"bg-male"}>11</td>
                                                    <td className={"bg-male"}>1</td>
                                                    <td className={"bg-male"}>0</td>
                                                    <td className={"bg-male"}>0</td>
                                                    <td className={"bg-male"}>0</td>
                                                    <td className={"bg-female"}>16</td>
                                                    <td className={"bg-female"}>2</td>
                                                    <td className={"bg-female"}>6</td>
                                                    <td className={"bg-female"}>2</td>
                                                    <td className={"bg-female"}>0</td>
                                                    <td className={"bg-primary text-white fw-bold"}>38</td>
                                                    <td className={"bg-primary text-white fw-bold"}>36</td>
                                                    <td className={"bg-primary text-white fw-bold"}>24</td>
                                                    <td className={"bg-primary text-white fw-bold"}>27</td>
                                                </tr>
                                                <tr>

                                                    <td className={"bg-white"}>Representation</td>
                                                    <td className={"bg-male"}>28.9%</td>
                                                    <td className={"bg-male"}>2.6%</td>
                                                    <td className={"bg-male"}>0.0%</td>
                                                    <td className={"bg-male"}>0.0%</td>
                                                    <td className={"bg-male"}>0.0%</td>
                                                    <td className={"bg-female"}>42.1%</td>
                                                    <td className={"bg-female"}>5.3%</td>
                                                    <td className={"bg-female"}>15.8%</td>
                                                    <td className={"bg-female"}>5.3%</td>
                                                    <td className={"bg-female"}>0.0%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>100%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>97.7%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>63.2%</td>
                                                    <td className={"bg-primary text-white fw-bold"}>71.1%</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={12} className="txt-align-end fw-bold bg-light-gray">Variance</td>
                                                    <td className="fw-bold text-success">9.7%</td>
                                                    <td className="fw-bold text-success">19.2%</td>
                                                    <td className="fw-bold text-danger">- 4.9%</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
export default EmploymentEquity;
