import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import TempFilters from '../../components/Filters/TempFilters';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import HCMore from 'highcharts/highcharts-more'; // If you need additional chart types
import HighchartsExporting from 'highcharts/modules/exporting'; // For exporting the chart as an image or PDF
import HighchartsExportData from 'highcharts/modules/export-data'; // For exporting data as CSV or XLSX

// Initialize the additional modules
HCMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);


const IncreaseAllocation = () => {

    const averagePerBand = {
        type: 'column',
        title: '',
        categories: ["B1","B2","B3","B4","B5", ],
        colorByPoint: false,
        colors: ['#5BBDCB', '#4D91B6', '#00164E'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: true,
        series: [
            {
                name: '2020-21',
                data: [3.0,3.0,3.0,3.0,3.0,],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#4D91B6',
            },
            {
                name: '2021-22',
                data: [3.0,3.0,3.9,4.6,6.0],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#5BBDCB',
            },
            {
                name: '2022-23',
                data: [5.5,5.6,5.6,7.0,6.6],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#00164E',
            }
        ]
    };

    const averagePerRating = {
        type: 'column',
        title: '',
        categories: ["1 - Not Achieved", "2 - Partially Achieved", "3 - Fully Achieved", "4 - Exceptionally Achieved", "TEA - Too Early to Assess"],
        colorByPoint: false,
        colors: ['#00164E'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: false,
        series: [
            {
                name: '2022-23',
                data: [0.0,3.4,6.3,7.9,2.6,],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#00164E',
            }
        ]
    };

    const averagePerRace = {
        type: 'column',
        title: '',
        categories: ["African","Indian","Coloured","White" ],
        colorByPoint: false,
        colors: ['#4D91B6', '#00164E'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: true,
        series: [
            {
                name: '2021-22',
                data: [4.3,4.0,4.2,3.8,],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#5BBDCB',
            },
            {
                name: '2022-23',
                data: [6.4,6.1,6.3,5.9,],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#00164E',
            }
        ]
    };

    const perGender = {
        type: 'column',
        title: '',
        categories: ["Female","Male" ],
        colorByPoint: false,
        colors: ['#4D91B6', '#00164E'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: true,
        series: [
            {
                name: '2021-22',
                data: [4.2,6.3],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#5BBDCB',
            },
            {
                name: '2022-23',
                data: [3.9,5.9],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#00164E',
            }
        ]
    };

    const getOptions = (props) => ({
        chart: {
            type: props.type,
            backgroundColor: 'white',
        },
        plotOptions: {
            column: {
                colorByPoint: props.colorByPoint
            },
            series: {
                borderRadius: '25%'
            }
        },
        colors: props.colors,
        title: {
            text: props.title
        },
        credits: {
            enabled: props.creditsEnabled
        },
        legend: {
            enabled: props.legendEnabled
        },
        yAxis: {
            title: {
                text: props.yAxisTitleText === undefined ? 'Percentages(%)' : props.yAxisTitleText,
            },
        },
        xAxis: {
            categories: props.categories,
            labels: {
                style: {
                    fontSize: '12px', // Adjust font size if needed
                    whiteSpace: 'normal', // Allow labels to wrap when long
                    overflow: 'scroll',
                },
                tickWidth: 0,
            },
        },
        series: props.series,
    });

    return (
        <>
            <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Remuneration/Annual Increase Allocation"} />
            <main id="main" className="main">
                <div className='d-flex flex-column'>
                    <div>
                        <button className='btn btn-primary btn-sm py-1 me-2'>RESET</button>
                        <button className='btn btn-primary btn-sm py-1'>REAPPLY</button>
                    </div>
                    <div>
                        <TempFilters />
                    </div>
                </div>
                <section className="section mt-3">
                    <div className='row'>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Total Salary Increase per Band</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="scroll-x mb-3">
                                        <table className="table  band-table">
                                            <thead>
                                            <tr className='text-center'>
                                                <th scope="col" className='text-center'></th>
                                                <th scope="col" className='text-center'>Band 1 & 2</th>
                                                <th scope="col" className='text-center'>Band 3</th>
                                                <th scope="col" className='text-center'>Band 4 & 5</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="">
                                                <td>Target</td>
                                                <td>5.5%</td>
                                                <td>5.5%</td>
                                                <td>7%</td>

                                            </tr>
                                            <tr className="">
                                                <td>Actual</td>
                                                <td>5.7%</td>
                                                <td>5.6</td>
                                                <td>7%</td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>


                                    <div className="scroll-x mb-3">
                                        <table className="table  custom2-table">
                                            <thead>
                                            <tr className='text-center'>
                                                <th scope="col" className='text-start'>Band</th>
                                                <th scope="col" className='text-center'>Headcount</th>
                                                <th scope="col" className='text-center'>Annual Total Salary Prior Increase</th>
                                                <th scope="col" className='text-center'>Increase Allocation Amount</th>
                                                <th scope="col" className='text-center'>New Salary Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="">
                                                <td>B1 & B2</td>
                                                <td>603</td>
                                                <td>1 006 906 609</td>
                                                <td>55 309 112</td>
                                                <td>1 062 215 721</td>
                                            </tr>
                                            <tr className="">
                                                <td>B3</td>
                                                <td>1 617</td>
                                                <td>1 144 297 027</td>
                                                <td>1 144 297 027</td>
                                                <td>1 206 747 188</td>
                                            </tr>
                                            <tr className="">
                                                <td>B4 & B5</td>
                                                <td>1 776</td>
                                                <td>604 012 481</td>
                                                <td>41 910 318</td>
                                                <td>645 922 799</td>
                                            </tr>

                                            {/*last row start*/}
                                            <tr className="last-row2">
                                                <td>TOTAL</td>
                                                <td>3 996</td>
                                                <td>2 755 216 117</td>
                                                <td>159 669 591</td>
                                                <td>2 914 885 708</td>
                                            </tr>
                                            {/*last row end*/}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="form-check ms-2">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckDefault" checked/>
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                               Merge Bands
                                            </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Average Salary Increase per Band</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="my-2">
                                            <span className="me-2">Show History:</span>
                                            <select className="chart-select" aria-label="Default select example">
                                                <option selected>Current + 2 yrs</option>
                                                {/*<option selected>Current Only</option>*/}
                                            </select>
                                        </div>
                                    </div>

                                    <HighchartsReact highcharts={Highcharts} options={getOptions(averagePerBand)} />
                                    <div className="form-check ms-2">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckDefault" checked/>
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Merge Bands
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Average Salary Increase per Band</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="my-2">
                                            <span className="me-2">Show History:</span>
                                            <select className="chart-select" aria-label="Default select example">
                                                <option >Current + 2 yrs</option>
                                                <option selected>Current Only</option>
                                            </select>
                                        </div>
                                    </div>

                                    <HighchartsReact highcharts={Highcharts} options={getOptions(averagePerRating)} />

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Average Salary Increase per Race</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="my-2">
                                            <span className="me-2">Show History:</span>
                                            <select className="chart-select" aria-label="Default select example">
                                                <option >Current Only</option>
                                                <option selected>Current + 1 yrs</option>
                                                <option >Current + 2 yrs</option>
                                            </select>
                                        </div>
                                    </div>

                                    <HighchartsReact highcharts={Highcharts} options={getOptions(averagePerRace)} />

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-5 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Rating / Average Increase </h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={"d-flex justify-content-center my-3"}>

                                        <div className="table-container">
                                            <table className={"custom-table-wa"}>
                                                <tbody>
                                                <tr>
                                                    <th  className={" text-end"}><i className="fa-solid fa-percent fs-4 fw-bolder"></i></th>
                                                    <th  className={"text-white fw-bold heading4"}>Not Achieved</th>
                                                    <th  className={"text-white fw-bold heading4"}>Partially Achieved</th>
                                                    <th  className={"text-white fw-bold heading4"}>Fully Achieved</th>
                                                    <th  className={"text-white fw-bold heading4"}>Exceptionally Achieved</th>
                                                    <th  className={"text-white fw-bold heading4"}>TEA</th>
                                                </tr>

                                                <tr>
                                                    <td  className={" text-end"}>B1 <i className="fa-solid fa-square text-sky-blue"></i></td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>0.0</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>3.8%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>5.2%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>6.6%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>2.5%</td>

                                                </tr>
                                                <tr>
                                                    <td  className={" text-end"}>B2 <i className="fa-solid fa-square text-sky-blue"></i></td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>0.0</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>2.5%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>5.3%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>6.4%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>2.3%</td>

                                                </tr>
                                                <tr>
                                                    <td  className={" text-end"}>B3 <i className="fa-solid fa-square text-sky-blue"></i></td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>0.0</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>2.8%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>5.4%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>7.1%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>2.0%</td>

                                                </tr>
                                                <tr>
                                                    <td  className={" text-end"}>B4 <i className="fa-solid fa-square text-sky-blue"></i></td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>0.0</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>3.7%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>7.0%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>8.6%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>2.7%</td>

                                                </tr>
                                                <tr>
                                                    <td  className={" text-end"}>B5 <i className="fa-solid fa-square text-sky-blue"></i></td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>0.0</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>3.3%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>6.8%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>9.3%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>0.0%</td>

                                                </tr>

                                                <tr>
                                                    <td  className={""}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>

                                                </tr>


                                                <tr>
                                                    <td  className={" text-end"}>African <i className="fa-solid fa-square text-light-blue"></i></td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>0.0</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>3.3%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>6.4%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>8.0%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>2.0%</td>

                                                </tr>
                                                <tr>
                                                    <td  className={" text-end"}>Indian <i className="fa-solid fa-square text-light-blue"></i></td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>0.0</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>3.5%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>5.8%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>7.4%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>1.8%</td>

                                                </tr>
                                                <tr>
                                                    <td  className={" text-end"}>Coloured <i className="fa-solid fa-square text-light-blue"></i></td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>0.0</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>3.7%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>6.1%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>7.6%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>4.3%</td>

                                                </tr>
                                                <tr>
                                                    <td  className={" text-end"}>White <i className="fa-solid fa-square text-light-blue"></i></td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>0.0</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>3.2%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>5.5%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>7.0%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>0.0%</td>

                                                </tr>

                                                <tr>
                                                    <td  className={""}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>

                                                </tr>

                                                <tr>
                                                    <td  className={" text-end"}>Female  <i className="fa-solid fa-square text-dark-blue"></i></td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>0.0</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>3.4%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>6.2%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>7.8%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>2.2%</td>
                                                </tr>
                                                <tr>
                                                    <td  className={" text-end"}>Male <i className="fa-solid fa-square text-dark-blue"></i></td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>0.0</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>3.4%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>6.0%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>7.3%</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>2.4%</td>
                                                </tr>

                                                <tr>
                                                    <td  className={""}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>

                                                </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className=""></h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={"d-flex justify-content-center my-3"}>
                                        <div className="table-container">
                                            <table className={"custom-table-avg-salary"}>
                                                <tbody>
                                                <tr>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>Highest Increase - Male</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>23.5%</td>
                                                </tr>
                                                <tr>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>Highest Increase - Female</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>20.0%</td>
                                                </tr>
                                                <tr>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>Lowest Increase - Male</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>0.4%</td>
                                                </tr>
                                                <tr>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>Lowest Increase - Female</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>0.4%</td>
                                                </tr>

                                                <tr>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                </tr>

                                                <tr>
                                                    <td  className={"text-black fst-normal avg-inc-gender"}>Average Increase Amount - Male</td>
                                                    <td  className={"text-black fst-normal avg-inc-gender"}>R 47 585</td>
                                                </tr>
                                                <tr>
                                                    <td  className={"text-black fst-normal avg-inc-gender"}>Average Increase Amount - Female</td>
                                                    <td  className={"text-black fst-normal avg-inc-gender"}>R 35 093</td>
                                                </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className={"d-flex justify-content-center my-3"}>
                                        <div className="table-container">
                                            <table className={"custom-table-avg-salary"}>
                                                <tbody>
                                                <tr>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>Highest Increase - White</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>16.4%</td>
                                                </tr>
                                                <tr>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>Highest Increase - AIC</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>23.5%</td>
                                                </tr>
                                                <tr>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>Lowest Increase - White</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>0.4%</td>
                                                </tr>
                                                <tr>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>Lowest Increase - AIC</td>
                                                    <td  className={"text-black fst-normal avg-inc-rating"}>0.4%</td>
                                                </tr>

                                                <tr>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                    <td  className={"text-black fst-normal empty-col"}></td>
                                                </tr>

                                                <tr>
                                                    <td  className={"text-black fst-normal avg-inc-gender"}>Average Increase Amount - White</td>
                                                    <td  className={"text-black fst-normal avg-inc-gender"}>R 63 385</td>
                                                </tr>
                                                <tr>
                                                    <td  className={"text-black fst-normal avg-inc-gender"}>Average Increase Amount - AIC</td>
                                                    <td  className={"text-black fst-normal avg-inc-gender"}>R 34 770</td>
                                                </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Average Salary Increase per Gender</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="my-2">
                                            <span className="me-2">Show History:</span>
                                            <select className="chart-select" aria-label="Default select example">
                                                <option >Current Only</option>
                                                <option selected>Current + 1 yrs</option>
                                                <option >Current + 2 yrs</option>
                                            </select>
                                        </div>
                                    </div>
                                    <HighchartsReact highcharts={Highcharts} options={getOptions(perGender)} />
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </>
    )
}
export default IncreaseAllocation;
