import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import TempFilters from '../../components/Filters/TempFilters';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

HCMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

/*Salaries Component*/
const Salaries = () => {

    const perBand = {
        type: 'column',
        title: '',
        categories: [ 'B1', 'B2', 'B3', 'B4', 'B5',],
        colorByPoint: true,
        colors: ['#547A91','#547A91','#547A91','#547A91','#547A91'],
        creditsEnabled: false,
        credits:'',
        legendEnabled:false,
        yAxisTitleText:'',
        series: [
            {
                // data: [37210116, 50262430, 119031856, 55372758, 912877],
                data: [
                    {y:37210116,currency:'R :val'},
                    {y:50262430,currency:'R :val'},
                    {y:119031856,currency:'R :val'},
                    {y:55372758,currency:'R :val'},
                    {y:912877,currency:'R :val'},
                ],
                dataLabels: {
                    enabled: true,
                    // format: 'R {y}',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    },
                    formatter: function() {
                        return this.point.currency.replace(':val', this.y.toLocaleString());
                    }
                },
                color: '#5BBDCB',
            },
        ]
    };

    const perGender = {
        type: 'column',
        title: '',
        categories: [ 'Female', 'Male'],
        colorByPoint: true,
        colors: ['#547A91','#547A91'],
        creditsEnabled: false,
        credits:'',
        legendEnabled:false,
        yAxisTitleText:'',
        series: [
            {
                // data: [136377839, 126412200,],
                data: [
                    {y:136377839,currency:'R :val'},
                    {y:126412200,currency:'R :val'},
                ],
                dataLabels: {
                    enabled: true,
                    // format: 'R {y}',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    },
                    formatter: function() {
                        return this.point.currency.replace(':val', this.y.toLocaleString());
                    }
                },
                color: '#5BBDCB',
            },
        ]
    };

    const perRace = {
        type: 'column',
        title: '',
        categories: [ 'African', 'Indian', 'Coloured', 'White',],
        colorByPoint: true,
        colors: ['#547A91','#547A91','#547A91','#547A91','#547A91'],
        creditsEnabled: false,
        credits:'',
        legendEnabled:false,
        yAxisTitleText:'',
        series: [
            {
                // data: [104177262, 49170191, 34058482, 75384103],
                data: [
                    {y:104177262,currency:'R :val'},
                    {y:49170191,currency:'R :val'},
                    {y:34058482,currency:'R :val'},
                    {y:75384103,currency:'R :val'},
                ],
                dataLabels: {
                    enabled: true,
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    },
                    formatter: function() {
                        return this.point.currency.replace(':val', this.y.toLocaleString());
                    }
                },

                color: '#5BBDCB',
            },
        ]
    };

    const getOptions = (props) => ({
        lang: {
            thousandsSep: " "
        },
        chart: {
            type: props.type,
            backgroundColor: 'white',
        },
        plotOptions: {
            column: {
                colorByPoint: props.colorByPoint
            },
            series: {
                borderRadius: '25%'
            }
        },
        colors: props.colors,
        title: {
            text: props.title
        },
        credits: {
            enabled: props.creditsEnabled
        },
        legend: {
            enabled: props.legendEnabled
        },
        yAxis: {
            title: {
                text: props.yAxisTitleText === undefined ? 'Percentages(%)' : props.yAxisTitleText,
            },
        },
        xAxis: {
            categories: props.categories,
            labels: {
                style: {
                    fontSize: '12px', // Adjust font size if needed
                    whiteSpace: 'normal', // Allow labels to wrap when long
                    overflow: 'scroll',
                },
                tickWidth: 0,
            },
        },
        series: props.series,
    });

    const widget = ({title, number, arrow, count}) => {
        return (
            <div className={"widget"}>
                <div className='card bg-primary'>
                    <div className='card-body'>
                        <div className={"d-flex flex-column justify-content-center align-items-center mb-2"}>
                            <span className={"h5 fw-bold text-white"}>{title}</span>
                            <hr className="widget-sep w-75 rounded-2 py-1 my-2" />
                            <span className={"h6 fw-bold text-white m-2"}>{number}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <>
            <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Remuneration/Salaries"} />
            <main id="main" className="main">
                <div className='d-flex flex-column'>
                    <div>
                        <button className='btn btn-primary btn-sm py-1 me-2'>RESET</button>
                        <button className='btn btn-primary btn-sm py-1'>REAPPLY</button>
                    </div>
                    <div>
                        <TempFilters />
                    </div>
                </div>
                <section className="section mt-3">
                    <div className='row'>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-3 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Monthly Salaries Distribution</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="my-2">
                                            <span className="me-2">Show As:</span>
                                            <select className="chart-select" aria-label="Default select example">
                                                <option selected>Total Package</option>
                                                <option value="1">CTC</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="scroll-x mb-3">
                                        <table className="table  custom-table">
                                            <thead>
                                            <tr className='text-center'>
                                                <th scope="col" className='text-start'>Month</th>
                                                <th scope="col" className='text-start'>Total Monthly</th>
                                                <th scope="col" className='text-start'>Average Salary</th>
                                                <th scope="col" className='text-start'>Lowest Salary</th>
                                                <th scope="col" className='text-start'>Highest Salary</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="">
                                                <td>January</td>
                                                <td>R 252 187 258</td>
                                                <td>R 55 511</td>
                                                <td>R 5 499</td>
                                                <td>R 661 589</td>
                                            </tr>
                                            <tr className="">
                                                <td>February</td>
                                                <td>R 252 057 695</td>
                                                <td>R 55 106</td>
                                                <td>R 5 499</td>
                                                <td>R 661 589</td>
                                            </tr>
                                            <tr className="">
                                                <td>March</td>
                                                <td>R 252 182 032</td>
                                                <td>R 50 507</td>
                                                <td>R 5 499</td>
                                                <td>R 661 589</td>
                                            </tr>
                                            <tr className="">
                                                <td>April</td>
                                                <td>R 264 300 317</td>
                                                <td>R 57 606</td>
                                                <td>R 5 499</td>
                                                <td>R 701 284</td>
                                            </tr>

                                            <tr className="">
                                                <td>May</td>
                                                <td>R 262 696 671</td>
                                                <td>R 57 508</td>
                                                <td>R 5 499</td>
                                                <td>R 701 284</td>
                                            </tr>

                                            <tr className="">
                                                <td>June</td>
                                                <td>R 262 790 040</td>
                                                <td>R 57 730</td>
                                                <td>R 5 499</td>
                                                <td>R 701 284</td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    {widget({title:'Accumulative YTD', number:'R1 546 214 013', arrow:'down', count:'14'})}
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-3 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Monthly Salaries per Band</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="my-2">
                                            <span className="me-2">Show As:</span>
                                            <select className="chart-select" aria-label="Default select example">
                                                <option selected>Total Package</option>
                                                <option value="1">CTC</option>
                                            </select>
                                        </div>
                                    </div>
                                    <HighchartsReact highcharts={Highcharts} options={getOptions(perBand)} />
                                    <div className="scroll-x mb-3">
                                        <table className="table  custom-table">
                                            <thead>
                                            <tr className='text-center'>
                                                <th scope="col" className='text-start'>Band</th>
                                                <th scope="col" className='text-start'>Highest Salary</th>
                                                <th scope="col" className='text-start'>Lowest Salary</th>
                                                <th scope="col" className='text-start'>Average Salary</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="">
                                                <td>B1</td>
                                                <td>R 701 284</td>
                                                <td>R 100 686</td>
                                                <td>R 209 045</td>
                                            </tr>
                                            <tr className="">
                                                <td>B2</td>
                                                <td>R 290 286</td>
                                                <td>R 40 511</td>
                                                <td>R 120 245</td>
                                            </tr>

                                            <tr className="">
                                                <td>B3</td>
                                                <td>R 229 166</td>
                                                <td>R 17 228</td>
                                                <td>R 60 483</td>
                                            </tr>
                                            <tr className="">
                                                <td>B4</td>
                                                <td>R 131 720</td>
                                                <td>R 5 499</td>
                                                <td>R 28 483</td>
                                            </tr>
                                            <tr className="">
                                                <td>B5</td>
                                                <td>R 35 633</td>
                                                <td>R 13 372</td>
                                                <td>R 20 747</td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-3 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Monthly Salaries per Gender</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="my-2">
                                            <span className="me-2">Show As:</span>
                                            <select className="chart-select" aria-label="Default select example">
                                                <option selected>Total Package</option>
                                                <option value="1">CTC</option>
                                            </select>
                                        </div>
                                    </div>

                                    <HighchartsReact highcharts={Highcharts} options={getOptions(perGender)} />
                                    <div className="scroll-x mb-3">
                                        <table className="table  custom-table">
                                            <thead>
                                            <tr className='text-center'>
                                                <th scope="col" className='text-start'>Gender</th>
                                                <th scope="col" className='text-start'>Highest Salary</th>
                                                <th scope="col" className='text-start'>Lowest Salary</th>
                                                <th scope="col" className='text-start'>Average Salary</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="">
                                                <td>Female</td>
                                                <td>R 375 000</td>
                                                <td>5 499</td>
                                                <td>R 49 412</td>
                                            </tr>
                                            <tr className="">
                                                <td>Male</td>
                                                <td>R 701 284</td>
                                                <td>R 7 667</td>
                                                <td>R 70 542</td>
                                            </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-3 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Monthly Salaries per Race</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="my-2">
                                            <span className="me-2">Show As:</span>
                                            <select className="chart-select" aria-label="Default select example">
                                                <option selected>Total Package</option>
                                                <option value="1">CTC</option>
                                            </select>
                                        </div>
                                    </div>

                                    <HighchartsReact highcharts={Highcharts} options={getOptions(perRace)} />
                                    <div className="scroll-x mb-3">
                                        <table className="table  custom-table">
                                            <thead>
                                            <tr className='text-center'>
                                                <th scope="col" className='text-start'>Race</th>
                                                <th scope="col" className='text-start'>Highest Salary</th>
                                                <th scope="col" className='text-start'>Lowest Salary</th>
                                                <th scope="col" className='text-start'>Average Salary</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="">
                                                <td>African</td>
                                                <td>R 439 118</td>
                                                <td>R 5 499</td>
                                                <td>R 43 389</td>
                                            </tr>
                                            <tr className="">
                                                <td>Indian</td>
                                                <td>R 701 284</td>
                                                <td>R 14 187</td>
                                                <td>R 77 433</td>
                                            </tr>
                                            <tr className="">
                                                <td>Coloured</td>
                                                <td>R 312 500</td>
                                                <td>R 6 086</td>
                                                <td>R 46 338</td>
                                            </tr>
                                            <tr className="">
                                                <td>White</td>
                                                <td>R 380 310</td>
                                                <td>R 18 221</td>
                                                <td>R 96 522</td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </>
    )
}
export default Salaries;
