import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Dashboard = () => {

  useEffect(() => {
    const fetchData = async () => {
      try {

      } catch (error) {

      }
    };

    fetchData();
  }, []);

  const chartProps1 = {
    type: 'column',
    title: '',
    categories: [ '00 - 02', '03 - 05', '06 - 10', '11 - 20', '21 - 30', '31+','9 yrs',],
    colorByPoint: true,
    colors: ['#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#E88B00',],
    creditsEnabled: false,
    credits:'',
    legendEnabled:false,
    series: [
      {
        data: [20.0, 28.3, 26.7, 13.3, 3.3, 8.3, 50],
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        color: '#5BBDCB',
      },
    ]
  };

  const chartProps2 = {
    type: 'column',
    title: '',
    data: [20.0, 28.3, 26.7, 13.3, 8.3],
    categories: [ 'Top <br/> Management', 'Senior <br/> Management', 'Professional /<br/>  Middle<br/>  Management', 'Technical / <br/> Junior <br/> Management', 'Semi-Skilled', 'Unskilled'],
    colorByPoint: false,
    colors: ['#5BBDCB','#4D91B6'],
    creditsEnabled: false,
    credits:'',
    legendEnabled:true,
    series: [
      {
        name: 'Exits',
        data: [0.0, 1, 20.0, 39.0, 0, 0],
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        color: '#5BBDCB',
      },
      {
        name: 'Intakes',
        data: [0.0, 0.0, 12.0, 34.0, 0, 0],
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        color: '#4D91B6',
      },
    ]
  };

  const chartProps3 = {
    type: 'column',
    title: '',
    categories: [  '18 - 24',
      '25 - 29',
      '30 - 39',
      '40 - 49',
      '50 - 59',
      '60+', '32.5 yrs'],
    colorByPoint: true,
    colors: ['#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#E88B00',],
    creditsEnabled: false,
    credits:'',
    legendEnabled:false,
    series: [{
        name: 'Intakes',
        data: [4.3, 28.3, 50.0, 17.4, 0, 0, 50],
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        color: '#5BBDCB',
      },
    ]
  };

  const chartProps4 = {
    type: 'column',
    title: '',
    categories: [ 'Top <br/> Management', 'Senior <br/> Management', 'Professional /<br/>  Middle<br/>  Management', 'Technical / <br/> Junior <br/> Management', 'Semi-Skilled', 'Unskilled', 'Average <br/>Headcount'],
    colorByPoint: true,
    colors: ['#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#5BBDCB','#E88B00',],
    creditsEnabled: false,
    credits:'',
    legendEnabled:false,
    series: [{
        name: 'Retirements',
        data: [0.5, 5.7, 38.2, 54.7, 0.5, 0.5, 4.7],
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        color: '#5BBDCB',
      },
    ]
  };

  const chartProps5 = {
    type: 'column',
    title: '',
    categories: [ 'Actuarial & <br/>Risk', 'Asset <br/>Management  <br/>/ Institutional', 'Business<br/>Development', 'Client &<br/> Adviser<br/> Experience', 'Client &<br/> Adviser<br/> Service Centre', 'Employee<br/>Benefits', 'Finance', 'Compliance &<br/> Forensic', 'Human<br/>Capital', 'Information<br />Technology', 'Investment<br/>Solutions', 'Legal', 'Marketing &<br/> Communications', 'Retail Risk<br/> Solutions', 'Asset<br/>Management', 'Stonehouse<br/>Management', 'Unskilled', 'Unskilled'],
    colorByPoint: false,
    colors: ['#5BBDCB','#4D91B6'],
    creditsEnabled: false,
    credits:'',
    legendEnabled:true,
    series: [
        {
        name: 'Intakes',
        data: [0, 2, 2, 20, 7, 5, 2, 2, 1, 2, 0, 0, 1, 2, 0, 0],
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        color: '#5BBDCB',
      },
        {
        name: 'Exits',
          data: [-1, -3, 0, -29, -12, -3, -1, -1, -2, -2, -2, -1, 0, 0, -1, -2],
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        color: '#4D91B6',
      },
      {
        type: 'line',
        name: 'Net Movement',
        data: [-1, -1, 2, -10, -5, 5, 2, 2, -2, 0, -2, -1, 1, 2, -1, -2,],
        color: '#E88B00',
        marker: {
          lineWidth: 2,
          lineColor: '#E88B00',
          fillColor: '#E88B00'
        }
      }
    ]
  };

  const getOptions = (props) => ({
    chart: {
      type: props.type,
      /*width: props.width===undefined ? 500 : props.width,
      height: props.height===undefined ? 400 : props.height,*/
      backgroundColor: 'white',
    },
    plotOptions: {
      column: {
        colorByPoint: props.colorByPoint
      },
      series: {
        borderRadius: '25%'
      }
    },
    colors: props.colors,
    title: {
      text: props.title
    },
    credits: {
      enabled: props.creditsEnabled
    },
    legend: {
      enabled: props.legendEnabled
    },
    yAxis: {
      title: {
        text: 'Percentages(%)',
      },
    },
    xAxis: {
      categories: props.categories,
      labels: {
        style: {
          fontSize: '12px', // Adjust font size if needed
          whiteSpace: 'normal', // Allow labels to wrap when long
          overflow: 'justify',
        },
        tickWidth: 0,
      },
    },
    series: props.series,
  });


  const selectMonth = (id, name) => {

    return  (
        <>
          <div className='mx-3 mb-4'>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name={`${name}${id}`} id={`inlineRadio1`}
                     value="option1" checked/>
              <span className="form-check-label">Current Month</span>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name={`${name}${id}`} id={`inlineRadio2`}
                     value="option2" />
              <span className="form-check-label">Previous Month</span>
            </div>
          </div>
        </>
    );
  }
  const widget = ({title, number, arrow, count}) => {
    return (
      <div className={"widget"}>
        <div className='card'>
          <div className='card-body'>
            <div className={"d-flex flex-column justify-content-center align-items-center mb-2"}>
              <span className={"h6 fw-bold"}>{title}</span>
              <hr className="widget-sep w-75 rounded-2 py-1 my-2" />
              <span className={"h5 fw-bold"}>{number}</span>
            </div>
            <div className={"d-flex justify-content-start align-items-baseline"}>
              <div className={"d-flex align-items-baseline"}>
                 <span className={"fs-5 fw-bold me-2"}>
                    <i className={`fa-solid fa-arrow-${arrow} fs-5 fw-bold text-light-blue`}></i>
                  </span>
                <span className={"fs-6 fw-bold text-orange"}>{count}</span>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  };
  const quickLinWidget = ({title, icon, url}) => {
    return (
        <div className="card quick-link-item">
          <div className={"d-flex justify-content-center"}>
            <div className="round-icon">
              <i className={`${icon} text-white fs-5`}></i>
            </div>
          </div>
          <div className="card-body">
            <div className={"d-flex justify-content-center"}>
              <a className='q-link' href={url} >
                <span className="fs-6 fw-bold mx-3">{title}</span>
              </a>

            </div>
          </div>
        </div>
    );
  };

  return (
    <>
      <Header label={
        <div className={"d-flex justify-content-between align-items-center"}>
          <span className='py-0 my-0'>This Month in a snapshot</span>
          <span className='py-0 my-0'>Data refreshes in: 7 days</span>
        </div>
      } />

      <main id="main" className="main">

        <div className='row'>
          <div className='d-flex flex-sm-column flex-md-row justify-content-evenly'>
            {widget({title:'Permanent Employees', number:'4 540', arrow:'down', count:'14'})}
            {widget({title:'Exit', number:'60', arrow:'down', count:'04'})}
            {widget({title:'Intakes', number:'46', arrow:'up', count:'05'})}
            {widget({title:'Turnover', number:'1.3%', arrow:'down', count:'0.1%'})}
            {widget({title:'Total Package (Monthly)', number:'R261 813 251', arrow:'up', count:'R93 369'})}
          </div>
        </div>
        <div className=''>

          <div className='card quick-links-card bg-sky-blue'>
            <div className='card-body'>
              <div className={"d-flex-column mx-5"}>
                <span className={"h4 text-white mb-3"}>QUICK LINKS</span>
                <br/><br/><br/>
                <div className='d-flex flex-sm-column flex-md-row justify-content-evenly'>
                  {quickLinWidget  ({title:'STAFF ANALYSIS', icon:'fa-solid fa-users', url:'staff-analysis'})}
                  {quickLinWidget  ({title:'TURNOVER ANALYSIS', icon:'fa-solid fa-user-gear', url:'staff-analysis'})}
                  {quickLinWidget  ({title:'REWARD PARITY', icon:'fa-solid fa-coins', url:'staff-analysis'})}
                  {quickLinWidget  ({title:'EQUAL WORK FOR EQUAL PAY', icon:'fa-solid fa-scale-balanced', url:'staff-analysis'})}
                </div>




              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-6 col-md-12 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4 fw-bold"}>Exit</span> <span className={"fs-4"}> by Tenure</span>
                </div>
                {selectMonth('1', 'tenure')}
                <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps1)} />
              </div>
            </div>
          </div>

          <div className='col-lg-6 col-md-12 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4 fw-bold"}>Staff Movement</span> <span className={"fs-4"}> by Employee Level</span>
                </div>
                {selectMonth('2', 'el')}
                <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps2)} />
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-6'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4 fw-bold"}>Intakes</span> <span className={"fs-4"}> by Tenure</span>
                </div>
                {selectMonth('1','intakes')}
                <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps3)} />
              </div>
            </div>
          </div>

          <div className='col-lg-6 col-md-6 col-sm-6'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4"}>Retirement Age (59+ in 12 Months) = </span> <span className={"fs-4 fw-bold"}>212</span>
                </div>
                {selectMonth('2', 'retirement')}
                <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps4)} />
              </div>
            </div>
          </div>

        </div>

        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4"}>Staff Movements by Business Area </span>
                </div>
                {selectMonth('2','ba')}
                <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps5)} />
              </div>
            </div>
          </div>
        </div>
      </main>

    </>
  )
}
export default Dashboard;
