import React from "react";
import { useMsal } from "@azure/msal-react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

/**
 * Renders a sign out button
 */
export const SignOutButton = ({show=false}) => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            localStorage.removeItem('token');
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/",
            });
        } else if (logoutType === "redirect") {
            localStorage.removeItem('token');
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    };

    return (
        <>
            {show && (
                <a className="nav-link main-link" onClick={() => { handleLogout("popup") }}>
                    <span className="icon-shape icon ps-1">
                        <i className="fa-solid fa-right-from-bracket"></i>
                    </span>
                    <span className='ms-2 link-label text-dark d-flex w-100'> Signout</span>
                </a>
            )}

        </>

    );
};