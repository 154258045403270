import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import TempFilters from '../../components/Filters/TempFilters';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


const ParityPay = () => {


    const data1 = {
        type: 'column',
        title: '',
        categories: ["African", "Indian", "Coloured", "White", "Race Gap <br/> (IAC)"],
        colorByPoint: true,
        colors: ['#DC8E18', "#547A91", '#DC8E18', "#547A91", "#7A0200"],
        creditsEnabled: false,
        credits: '',
        legendEnabled: false,
        series: [{
            data: [-25, 29, -19, 63, 78],
            dataLabels: {
                enabled: true,
                format: '{y}%',
            },
            color: '#5BBDCB',
        },
        ]
    };


    const data2 = {
        type: 'column',
        title: '',
        categories: ["Female", "Male", "Gender Gap <br/> (Female)"],
        colorByPoint: true,
        colors: ['#DC8E18', "#547A91", "#7A0200"],
        creditsEnabled: false,
        credits: '',
        legendEnabled: false,
        series: [{
            data: [-25, 29, 54],
            dataLabels: {
                enabled: true,
                format: '{y}%',
            },
            color: '#5BBDCB',
        },
        ]
    };


    const data3 = {
        type: 'column',
        title: '',
        categories: ["African", "Indian", "Coloured", "White", "Race Gap <br/> (IAC)"],
        colorByPoint: true,
        colors: ['#DC8E18', "#547A91", '#DC8E18', "#547A91", "#7A0200"],
        creditsEnabled: false,
        credits: '',
        legendEnabled: false,
        series: [{
            data: [-47, 40, -50, 142, 78],
            dataLabels: {
                enabled: true,
                format: '{y}%',
            },
            color: '#5BBDCB',
        },
        ]
    };


    const data5 = {
        type: 'column',
        title: '',
        categories: ["Female", "Male", "Gender Gap <br/> (Female)"],
        colorByPoint: true,
        colors: ['#DC8E18', "#547A91", "#7A0200"],
        creditsEnabled: false,
        credits: '',
        legendEnabled: false,
        series: [{
            data: [-42, 66, 108],
            dataLabels: {
                enabled: true,
                format: '{y}%',
            },
            color: '#5BBDCB',
        },
        ]
    };
    const getOptions = (props) => ({
        chart: {
            type: props.type,
            /* width: props.width === undefined ? 500 : props.width,
             height: props.height === undefined ? 400 : props.height,*/
            backgroundColor: 'white',
        },
        plotOptions: {
            column: {
                colorByPoint: props.colorByPoint
            },
            series: {
                borderRadius: '25%'
            }
        },
        colors: props.colors,
        title: {
            text: props.title
        },
        credits: {
            enabled: props.creditsEnabled
        },
        legend: {
            enabled: props.legendEnabled
        },
        yAxis: {
            title: {
                text: props.yAxisTitleText === undefined ? 'Percentages(%)' : props.yAxisTitleText,
            },
        },
        xAxis: {
            categories: props.categories,
            labels: {
                style: {
                    fontSize: '12px', // Adjust font size if needed
                    whiteSpace: 'normal', // Allow labels to wrap when long
                    overflow: 'scroll',
                },
                tickWidth: 0,
            },
        },
        series: props.series,
    });



    return (
        <>
            <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Parity / Pay Parity"} />
            <main id="main" className="main">
                <div className='d-flex flex-column'>
                    <div>
                        <button className='btn btn-primary btn-sm py-1 me-2'>RESET</button>
                        <button className='btn btn-primary btn-sm py-1'>REAPPLY</button>
                    </div>
                    <div>
                        <TempFilters />
                    </div>
                </div>
                <section className="section mt-3">
                    <div className='row'>

                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <div className=''>
                                                <span class="fs-4 fw-bold"> Remuneration: </span>
                                                <span class="fs-4"> Race Pay Disparity</span>

                                            </div>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <HighchartsReact highcharts={Highcharts} options={getOptions(data1)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <div className=''>
                                                <span class="fs-4 fw-bold"> Remuneration: </span>
                                                <span class="fs-4"> Gender Pay Disparity</span>

                                            </div>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <HighchartsReact highcharts={Highcharts} options={getOptions(data2)} />

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className="mx-1 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <div className=''>
                                                <span class="fs-4 fw-bold"> Bonus / Incentive: </span>
                                                <span class="fs-4"> Race Disparity</span>

                                            </div>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <HighchartsReact highcharts={Highcharts} options={getOptions(data3)} />

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                   
                                        <div className='card-body'>
                                            <div className="mx-1 mb-4">
                                                <div className="d-flex justify-content-between">
                                                    <div className=''>
                                                    <span class="fs-4 fw-bold"> Bonus / Incentive: </span>
                                                <span class="fs-4">  Gender Disparity</span>

                                                    </div>
                                                    <div className="d-flex justify-content-center align-items-center chart-controls">
                                                        <span className="py-1">
                                                            <i className="fa-solid fa-expand"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <HighchartsReact highcharts={Highcharts} options={getOptions(data5)} />

                                        </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </section>
            </main>
        </>
    )
}
export default ParityPay;
