
function ChatHistoryItem({ name, response, question, opened, closed, status }) {

    return (
        <>
            <div className="">
                <div className="max-height-vh-70">
                    <div className="card-body overflow-auto overflow-x-hidden">
                        <div className="row justify-content-start mb-2 mt-2">
                            <div className="col-auto">
                                <div className="card ">
                                    <div className=" py-2 px-3">
                                        <h6>{name}</h6>
                                        <p className="mb-1 p-w100">
                                            {question}
                                        </p>
                                        <div className="d-flex align-items-center text-sm opacity-6">
                                            {status === "Seen" || status === "Closed" ?<i className="message-seen bi bi-check-all text-sm me-1"></i>:<i className="bi bi-check-all text-sm me-1"></i>}
                                            <small className="font-italic">{opened}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-end text-right mb-2 mt-2">
                            <div className="col-auto">
                                <div className="card bg-gray-200">
                                    <div className=" py-2 px-3">
                                        <h6 className="d-flex align-items-center justify-content-end">Admin</h6>
                                        <p className="mb-1 p-w100">
                                            {response === ""
                                                ? "response pending..."
                                                : response}
                                        </p>
                                        <div className="d-flex align-items-center justify-content-end text-sm opacity-6">
                                            {response === ""
                                                ? ""
                                                : <>
                                                    <i className="bi bi-check-all text-sm me-1"></i>
                                                    <small className="font-italic">{closed}</small>
                                                </> }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{" "}
            </div>
        </>

    );
}

export default ChatHistoryItem;
