import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Header from "../Header";
import TempFilters from "../../components/Filters/TempFilters";

const RemVsBenchmarking = () => {

  useEffect(() => {
    const fetchData = async () => {
      try {

      } catch (error) {

      }
    };

    fetchData();
  }, []);

  const netMovement = {
    type: 'column',
    width: 1050,
    height: 500,
    title: '',
    categories: ['Oct \'22', 'Nov \'22', 'Dec \'22', 'Jan \'23', 'Feb \'23', 'Mar \'23', 'Apr \'23', 'May \'23', 'Jun \'23', ],
    colorByPoint: false,
    colors: ['#5BBDCB','#4D91B6'],
    creditsEnabled: false,
    credits:'',
    legendEnabled:true,
    series: [
      {
        name: 'Intakes',
        data: [55, 29, 29, 56, 69, 34, 52, 41, 46],
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        color: '#5BBDCB',
      },
      {
        name: 'Exits',
        data: [-62, -50, -42, -38, -23, -46, -64, -60],
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        color: '#4D91B6',
      },
      {
        type: 'line',
        name: 'Net Movement',
        data: [-7, -21, -13, 18, 11, 6, -23, -14],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#E88B00',
        marker: {
          lineWidth: 2,
          lineColor: '#E88B00',
          fillColor: '#E88B00'
        }
      }
    ]
  };

  const getOptions = (props) => ({
    chart: {
      type: props.type,
      backgroundColor: 'white',
    },
    plotOptions: {
      column: {
        colorByPoint: props.colorByPoint
      },
      series: {
        borderRadius: '25%'
      }
    },
    colors: props.colors,
    title: {
      text: props.title
    },
    credits: {
      enabled: props.creditsEnabled
    },
    legend: {
      enabled: props.legendEnabled
    },
    yAxis: {
      title: {
        text: props.yAxisTitle!==undefined ? props.yAxisTitle : 'Percentages(%)',
      },
    },
    xAxis: {
      categories: props.categories,
      labels: {
        style: {
          fontSize: '10px', // Adjust font size if needed
          whiteSpace: 'normal', // Allow labels to wrap when long
          overflow: 'justify',
        },
        tickWidth: 0,
      },
    },
    series: props.series,
  });

  const businessUnit = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
      align: 'left'
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: [
          'Client & Adviser<br/> Experience',
          'Business Development',
          'Investment Solutions',
          'Retail',
          'Finance',
          'Information Technology',
          'Actuarial & <br/> Risk',
          'Retail Risk<br/>Solutions',
          'Legal',
          'Human Capital',
          'Marketing &<br/>Communications',
          'Operations',
      ],
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Percentage (%)'
      },
      stackLabels: {
        shadow: true,
        enabled: false
      }
    },

    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
      column: {
        colorByPoint:false,
        stacking: 'normal',
        dataLabels: {
          shadow: false,
          enabled: false,
          style: {
            color: 'black',
            textOutline: 'none'
          }
        }
      }
    },
    series: [
        {
          dataLabels: {
            enabled: true,
            shadow: false,
            format: '{y}%',
            color: '#FFFFFF',
          },
          name: 'Above',
          data: [5, 20,10, 0, 0, 15,0,25,0,0,0],
          color: '#612889'
        },
      {
        dataLabels: {
          enabled: true,
          shadow: false,
          format: '{y}%',
          color: '#FFFFFF',
        },
        name: 'Below',
        data: [34, 30, 49,72,73, 58,75,10,90,10,50,60],
        color: '#DB8E18'
      },
        {
          dataLabels: {
            enabled: true,
            shadow: false,
            format: '{y}%',
            color:'#000000',
            borderColor:'#FFFFFF',
            borderWidth:'0px',
            style: {
              fontSize: '10px',


              padding:0,
            }
          },
          name: 'Aligned',
          data: [61,50,41,28,27,27,25,65,10,90,50, 40],
          color: '#67904B',
        },
      ]
  }

  const gender = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
      align: 'left'
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: [ 'Top<br/>Management', 'Senior<br/>Management', 'Professional /<br/> Middle Management', 'Technical /<br/> Junior Management', 'Semi-Skilled', 'Unskilled']
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Percentage (%)'
      },
      stackLabels: {
        shadow: false,
        enabled: false
      }
    },

    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
      column: {
        colorByPoint:false,
        stacking: 'normal',
        dataLabels: {
          shadow: false,
          enabled: false,
          style: {
            color: 'black',
            textOutline: 'none'
          }
        }
      }
    },
    series: [

      {
        dataLabels: {
          enabled: true,
          shadow: false,
          format: '{y}%',
          color:'#000000',
          borderColor:'#FFFFFF',
          borderWidth:'0px',
          style: {
            fontSize: '10px',
            padding:0,
          }
        },
        name: 'Male',
        data: [91.7, 60.7, 49.5, 32.9, 41.7, 0],
        color: '#5BBDCB',
      },
      {
        dataLabels: {
          enabled: true,
          shadow: false,
          format: '{y}%',
          color: '#FFFFFF',
        },
        name: 'Female',
        data: [8.3, 39.3, 50.5, 67.1, 58.3, 100],
        color: '#4D91B6'
      },
      ]
  }





  const averageDistribution = {
    type: 'column',
    title: '',
    categories: [
      'Top Management',
      'Senior Management',
      'Professional / Middle <br/> Management',
      'Technical / Junior <br/>  Management',
      'Semi-Skilled',
      'Unskilled',
    ],
    colorByPoint: false,
    colors: ['#4D91B6', '#009CAE'],
    creditsEnabled: false,
    credits: '',
    legendEnabled: true,
    yAxisTitleText:'',
    series: [
      {
        name: 'Compa-Ratio Salary',
        data: [28,23, 19,16,16,12,],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#4D91B6',
      },
      {
        name: 'Compa-Ratio Bonus',
        data: [31,24,12,11,16,12,],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#009CAE',
      },
      {
        type: 'line',
        name: 'Attrition',
        data: [5,9,20,28,3,2],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#E88B00',
        marker: {
          lineWidth: 2,
          lineColor: '#E88B00',
          fillColor: '#E88B00'
        }
      }
    ]
  };

  const compaRatioGender = {
    type: 'column',
    title: '',
    categories: ['African','Indian','Coloured','White'],
    colorByPoint: false,
    colors: ['#4D91B6', '#009CAE'],
    creditsEnabled: false,
    credits: '',
    legendEnabled: true,
    yAxisTitleText:'',
    series: [
      {
        name: 'Male',
        data: [0.9,1.2, 0.6, 1.5],
        dataLabels: {
          enabled: true,
          format: '{y}',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#4D91B6',
      },
      {
        name: 'Female',
        data: [0.8,0.8,0.7,1.3],
        dataLabels: {
          enabled: true,
          format: '{y}',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#009CAE',
      }
    ]
  };

  const getOptions3 = (props) => ({
    chart: {
      type: props.type,
      backgroundColor: 'white',
    },
    plotOptions: {
      column: {
        colorByPoint: props.colorByPoint
      },
      series: {
        borderRadius: '25%'
      }
    },
    colors: props.colors,
    title: {
      text: props.title
    },
    credits: {
      enabled: props.creditsEnabled
    },
    legend: {
      enabled: props.legendEnabled
    },
    yAxis: {
      title: {
        text: props.yAxisTitleText === undefined ? 'Percentages(%)' : props.yAxisTitleText,
      },
    },
    xAxis: {
      categories: props.categories,
      labels: {
        style: {
          fontSize: '12px', // Adjust font size if needed
          whiteSpace: 'normal', // Allow labels to wrap when long
          overflow: 'scroll',
        },
        tickWidth: 0,
      },
    },
    series: props.series,
  });

  return (
    <>
      <Header label={"Workforce Analysis / Staff Analysis"} />

      <main id="main" className="main">
        <TempFilters />
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-1 mb-4">
                  <div className="d-flex justify-content-between">
                    <h5 className="">Compa-Ratio & Attrition rate</h5>
                    <div className="d-flex justify-content-center align-items-center chart-controls">
                      <span className="py-1">
                          <i className="fa-solid fa-expand"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <HighchartsReact highcharts={Highcharts} options={getOptions3(averageDistribution)} />

              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-1 mb-4">
                  <div className="d-flex justify-content-between">
                    <h5 className="">Compa-Ratio by Race & Gender</h5>
                    <div className="d-flex justify-content-center align-items-center chart-controls">
                      <span className="py-1">
                          <i className="fa-solid fa-expand"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <HighchartsReact highcharts={Highcharts} options={getOptions3(compaRatioGender)} />

              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4"}> Compa-Ratio per Business Unit</span>
                </div>
                <HighchartsReact highcharts={Highcharts} options={businessUnit} />
              </div>
            </div>
          </div>
        </div>
      </main>

    </>
  )
}
export default RemVsBenchmarking;
