import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import TempFilters from '../../components/Filters/TempFilters';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


const PerformanceVReward = () => {

    const chartProps1 = {
        type: 'column',
        width: 500,
        height: 500,
        title: '',
        categories: ["1 - Not Achieveed", "2 - Partially Achieved", "3 - Fully Achieved", "4 - Exceptionally Achieved"],
        colorByPoint: false,
        colors: ['#547A91', '#74BBC9','#00164E',"#243D4C",'#DC8E18'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: true,
        series: [
            {
                name: 'African',
                data: [0.0, 3, 6, 10],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#547A91',
            },
            {
                name: 'Indian',
                data: [0.0, 5,6,11],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color:'#74BBC9',
            },
            {
                name: 'Coloured',
                data: [0.0,2.5,5,8],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#00164E',
            },
            {
                name: 'White',
                data: [0.0, 7,10,15],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: "#243D4C",
            },
            {
                name: 'Average',
                data: [0.0, 4,6.5,11],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color:'#DC8E18',
            },

        ]
    };

    const chartProps2 = {
        type: 'column',
        width: 500,
        height: 500,
        title: '',
        categories: ["1 - Not Achieveed", "2 - Partially Achieved", "3 - Fully Achieved", "4 - Exceptionally Achieved"],
        colorByPoint: false,
        colors: ['#547A91', '#74BBC9','#00164E',"#243D4C",'#DC8E18'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: true,
        series: [
            {
                name: 'Female',
                data:[0.0, 4, 6, 12],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#547A91',
            },
            {
                name: 'Male',
                data: [0.0, 6,9,15],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color:'#74BBC9',
            },
            {
                name: 'Average',
                data: [0.0,5,7.5,13.5],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#DC8E18',
            }

        ]
    };


    const chartProps3 = {
        type: 'column',
        width: 500,
        height: 500,
        title: '',
        categories: ["1 - Not Achieveed", "2 - Partially Achieved", "3 - Fully Achieved", "4 - Exceptionally Achieved"],
        colorByPoint: false,
        colors: ['#547A91', '#74BBC9','#00164E',"#243D4C",'#DC8E18'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: true,
        series: [
            {
                name: 'African',
                data: [0.0, 8,15,30],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#547A91',
            },
            {
                name: 'Indian',
                data: [0.0,16,25,40],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color:'#74BBC9',
            },
            {
                name: 'Coloured',
                data: [0.0,8,14,25],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#00164E',
            },
            {
                name: 'White',
                data: [0.0,20,40,60],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: "#243D4C",
            },
            {
                name: 'Average',
                data: [0.0,13,23.5,39],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color:'#DC8E18',
            },

        ]
    };

    const chartProps4 = {
        type: 'column',
        width: 500,
        height: 500,
        title: '',
        categories: ["1 - Not Achieveed", "2 - Partially Achieved", "3 - Fully Achieved", "4 - Exceptionally Achieved"],
        colorByPoint: false,
        colors: ['#547A91', '#74BBC9','#00164E',"#243D4C",'#DC8E18'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: true,
        series: [
            {
                name: 'Female',
                data:[0.0,15,32,50],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#547A91',
            },
            {
                name: 'Male',
                data: [0.0, 22,44,67],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color:'#74BBC9',
            },
            {
                name: 'Average',
                data: [0.0,18.5,38,58.5],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#DC8E18',
            }

        ]
    };
    const getOptions = (props) => ({
        chart: {
            type: props.type,

            backgroundColor: 'white',
        },
        plotOptions: {
            column: {
                colorByPoint: props.colorByPoint
            },
            series: {
                borderRadius: '25%'
            }
        },
        colors: props.colors,
        title: {
            text: props.title
        },
        credits: {
            enabled: props.creditsEnabled
        },
        legend: {
            enabled: props.legendEnabled
        },
        yAxis: {
            title: {
                text: 'Percentages(%)',
            },
        },
        xAxis: {
            categories: props.categories,
            labels: {
                style: {
                    fontSize: '12px', // Adjust font size if needed
                    whiteSpace: 'normal', // Allow labels to wrap when long
                    overflow: 'scroll',
                },
                // tickWidth: 0,
            },
        },
        series: props.series,
    });


    const getOptions2 = (props) => ({
        chart: {
            type: 'column'
        },
        width: 500,
        height: 600,
        title: {
            text: '',
            align: 'left'
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: ["1 - Not<br/>Achieved", "2 - Partially<br/>Achieved", "3 - Fully<br/>Achieved", "4 - Exceptionally<br/>Achieved", "TEA - <br/>Too Early to Assess"]
        },
        colors: ["#67904B", "#547A91", "#DC8E18", "#A6CBDB", "#00164E"],
        yAxis: {
            min: 0,
            title: {
                text: 'Percentage (%)'
            },
            stackLabels: {
                shadow: false,
                enabled: false
            }
        },
        legend: {
            align: 'center',
            floating: false,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            series: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true,
                    style: {
                        backgroundColor: 'transparent'
                    }
                }
            }
        },
        series: [
            {
                name: 'B5',
                data: [0, 0.7, 1.0, 0.4, 2.3],
                dataLabels: {
                    enabled: true,
                    shadow: false,
                    format: '{y}%',
                    color: '#000000',
                    padding: 0,
                    textOutline: 'none',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                }
            },
            {
                name: 'B4',
                data: [0, 61.6, 42.2, 42.6, 34.9],
                dataLabels: {
                    enabled: true,
                    shadow: false,
                    format: '{y}%',
                    color: '#000000',
                    padding: 0,
                    textOutline: 'none',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                }
            },
            {
                name: 'B3',
                data: [0, 31.7, 41.9, 37.3, 51.2],
                dataLabels: {
                    enabled: true,
                    shadow: false,
                    format: '{y}%',
                    color: '#000000',
                    padding: 0,
                    textOutline: 'none',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                }
            },
            {
                name: 'B2',
                data: [0, 5.0, 10.1, 15.2, 9.3],
                dataLabels: {
                    enabled: true,
                    shadow: false,
                    format: '{y}%',
                    color: '#000000',
                    padding: 0,
                    textOutline: 'none',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                }
            },
            {
                name: 'B1',
                data: [0.0, 1.1, 4.8, 4.5, 2.3],
                dataLabels: {
                    enabled: true,
                    shadow: false,
                    format: '{y}%',
                    color: '#000000',
                    padding: 0,
                    textOutline: 'none',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                }
            }]

    });

    return (
        <>
            <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Parity / Performance vs. Reward"} />
            <main id="main" className="main">
                <div className='d-flex flex-column'>
                    <div>
                        <button className='btn btn-primary btn-sm py-1 me-2'>RESET</button>
                        <button className='btn btn-primary btn-sm py-1'>REAPPLY</button>
                    </div>
                    <div>
                        <TempFilters />
                    </div>
                </div>
                <section className="section mt-3">
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>
                                <div className="">
                                    <h4 className="header-title">Race: Performance vs. Average Increase %</h4>
                                </div>
                                <div className='card-body'>
                                    <div className="scroll-x">
                                        <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps1)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>
                                <div className="">
                                    <h4 className="header-title">Gender: Performance vs. Average Increase %</h4>
                                </div>
                                <div className='card-body'>
                                    <div className="scroll-x">
                                        <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps2)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>
                                <div className="">
                                    <h4 className="header-title">Race: Performance vs. Average Incentive as Salary %</h4>
                                </div>
                                <div className='card-body'>
                                    <div className="scroll-x">
                                        <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps3)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>
                                <div className="">
                                    <h4 className="header-title">Gender: Performance vs. Average Incentive as Salary %</h4>
                                </div>
                                <div className='card-body'>
                                    <div className="scroll-x">
                                        <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps4)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
export default PerformanceVReward;
