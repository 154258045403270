// export const baseUrl="https://localhost:7029/api/";
export const baseUrl="http://test.apexsoftware.click/api/";



export const usersApi = {
    getAllUsers: 'user',
}
export const chatbotData =
    [
    {
        pillar: "Issue log",
        questionGroups: [
            {
                group: "Issue Logging",
                questions: [
                    {
                        question: "How to log an issue?",
                        placeholder: "",
                        answer: "Go to the Issue log functionality and click the Add button, fields to capture will appear then you click the save button."
                    },
                    {
                        question: "What is the aim of an Issue log?",
                        placeholder: "",
                        answer: "The aim of an Issue log is for you/user to report any difficulties that you may encounter within the system."
                    },
                    {
                        question: "What happens after logging an Issue?",
                        placeholder: "",
                        answer: "You get a direct feedback that tells you that your logged issue has been successfully logged and your Admin/Leader attends to it."
                    },
                    {
                        question: "How do I know if my logged issue has been resolved?",
                        placeholder: "",
                        answer: "By checking the Status from your Issue log table, it will state whether your logged issue is still pending or resolved."
                    }
                ]
            }
        ]
    },
    {
        pillar: "Audit trail",
        questionGroups: [
            {
                group: "Audit Trails",
                questions: [
                    {
                        question: "What is the aim of an Audit trail?",
                        placeholder: "",
                        answer: "The aim of an Audit trail is to record every interaction made by the user within the system."
                    }
                ]
            }
        ]
    },
    {
        pillar: "Login Tracker",
        questionGroups: [
            {
                group: "Login Tracker",
                questions: [
                    {
                        question: "What is the aim of a Login tracker?",
                        placeholder: "",
                        answer: "The aim of a Login tracker is to keep records of users that have logged into a system. It also keeps records of login error attempts, whether successful or not."
                    }
                ]
            }
        ]
    },
    {
        pillar: "UAC",
        questionGroups: [
            {
                group: "UAC",
                questions: [
                    {
                        question: "What is UAC?",
                        placeholder: "",
                        answer: "UAC stands for User Access Control, a functionality whereby an Admin/Leader controls the permissions of a user within the system platform."
                    },
                    {
                        question: "What happens when permissions within the system are limited?",
                        placeholder: "",
                        answer: "If certain permissions are disabled for you to access within the platform, you will only be able to see what has been granted access to."
                    },
                    {
                        question: "Where can one generate permissions for a user within the system?",
                        placeholder: "",
                        answer: "Simply go to the UAC functionality and access permission settings for that specific user."
                    },
                    {
                        question: "Can I change a user's role within the system?",
                        placeholder: "",
                        answer: "Absolutely! Simply go to the UAC functionality, search for the user you want to change the role for, and make the necessary changes."
                    }
                ]
            }
        ]
    },
    {
        pillar: "Users",
        questionGroups: [
            {
                group: "Users",
                questions: [
                    {
                        question: "Where can I update a user's profile information?",
                        placeholder: "",
                        answer: "You can update user profiles by clicking the Users functionality. A list of users within the system will display, and you can update them as required, or you can perform bulk uploads."
                    }
                ]
            }
        ]
    },
    {
        pillar: "Email template",
        questionGroups: [
            {
                group: "Email",
                questions: [
                    {
                        question: "How do I know how the email will display to the users?",
                        placeholder: "",
                        answer: "You can preview your email before saving it by clicking the preview button."
                    },
                    {
                        question: "What is the aim of the Email template?",
                        placeholder: "",
                        answer: "The aim of the Email template is to manage the messages sent out to the users."
                    }
                ]
            }
        ]
    },
    {
        pillar: "Input Field",
        questionGroups: [
            {
                group: "Input fields",
                questions: [
                    {
                        question: "What is an input field?",
                        placeholder: "",
                        answer: "This is where you manage access in certain fields."
                    },
                    {
                        question: "Should there be no access granted in input fields what happens?",
                        placeholder: "",
                        answer: "Fields become disabled and non-capturable."
                    }
                ]
            }
        ]
    },
    {
        pillar: "Help & Support",
        questionGroups: [
            {
                group: "Help & Support",
                questions: [
                    {
                        question: "Where can I find more information about the system?",
                        placeholder: "",
                        answer: "The help and support functionality will give you more in-depth information, and you can also be able to download help guides."
                    },
                    {
                        question: "How does the help & support assist me?",
                        placeholder: "",
                        answer: "Firstly, the help & support gives you more clarity about the system. In this instance, when you click the prepopulated questions, you will get an answer feedback below the question."
                    }
                ]
            }
        ]
    }
];
