import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';

const SystemLinks = () => {

  return (
    <>
      <Header icon={"fa-solid fa-link fa-xl mx-2"} label={"System Links"} />
      <main id="main" className="main">
        <div className='row mb-5'>
          {[{
            url: "/employee-recognition",
            label: "Employee Recognition"
          },
          {
            url: "/employee-wellness",
            label: "Employee Wellness"
          },
          {
            url: "/Issue-logging-resolution",
            label: "Issue Logging resolution"
          },
          {
            url: "/engagement-surveys",
            label: "Engagement Surveys"
          },

          {
            url: "/recruitment-onboarding",
            label: "Recruitment Onboarding"
          },
          {
            url: "/new-hire-surveys",
            label: "New Hire Surveys"
          },
          {
            url: "https://demo-exit.concisesolutions.co.za",
            label: "Exit Interviews"
          },
          {
            url: "/lms",
            label: "LMS"
          },
          {
            url: "/work-architecture-job-benchmark",
            label: "Work Architecture & Job Benchmarking"
          },
          {
            url: "/wsp-atr-reporting",
            label: "WSP / ATR Reporting"
          },
          {
            url: "/performance-management",
            label: "Performance Management"
          },
          {
            url: "/increase-bonus-allocation",
            label: "Increase & Bonus Allocation"
          },
          {
            url: "/incentive-scheme-contracting",
            label: "Incentive Scheme Contracting"
          },
          ].map((item, key) => (
            <>
              <div key={key} className='col-lg-3'>
               <a className='' href={item.url} target='_blanks'>
               <div className='card rounded-2'>
                  <div className='card-header text-center bg-primary text-center py-2'>
                  <i class="fa-solid fa-link fa-xl text-light"></i>
                  </div>
                  <div className='card-body text-center text-dark'>
                    {item.label}
                  </div>
                </div>
               </a>
              </div>
            </>
          ))}
        </div>



      </main>
    </>
  )
}
export default SystemLinks;
