import ChatHistoryItem from "./ChatHistoryItem";
import {useEffect, useState} from "react";

function ChatHistory({ setShowChats,conversation ,getConversation}) {
    const [logs,setLogs ] = useState(conversation?conversation:[]);
useEffect(()=>{
    if(getConversation){
        getConversation();
    }
    setLogs(conversation?conversation:[])
},[conversation])

    return (
        <>
            <div className=' chatbot-popup card chat-history'>
                <div className="position-relative">
                    <div className="blockquote-custom-icon shadow-sm position-absolute">
                        <i className="fas fa-envelope messages-icon"></i>
                    </div>
                </div>

                <div>
                    <h5 className="pt-4 ps-3 pb-1 mt-2">Chat History</h5>
                </div>
                <div className="chats-container">
                    {logs.map((item,key) => (
                        <ChatHistoryItem
                            key={key}
                            name={item.user}
                            question={item.question}
                            response={item.response}
                            closed={item.date_closed}
                            opened={item.date_opened}
                            status={item.status}
                        />))
                    }
                </div>

                <hr  className="p-0 my-0 my-2"/>
                <div className='d-flex'>
                    <button className="btn btn-sm btn-danger ms-auto mx-2 me-3" onClick={() => { setShowChats(false); }}>Close</button>
                </div>
            </div>
        </>

    );
}

export default ChatHistory;
