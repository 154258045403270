import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Header from "../Header";
import TempFilters from "../../components/Filters/TempFilters";

const StaffAnalysis = () => {

  useEffect(() => {
    const fetchData = async () => {
      try {

      } catch (error) {

      }
    };

    fetchData();
  }, []);

  const netMovement = {
    type: 'column',
    width: 1050,
    height: 500,
    title: '',
    categories: ['Oct \'22', 'Nov \'22', 'Dec \'22', 'Jan \'23', 'Feb \'23', 'Mar \'23', 'Apr \'23', 'May \'23', 'Jun \'23', ],
    colorByPoint: false,
    colors: ['#5BBDCB','#4D91B6'],
    creditsEnabled: false,
    credits:'',
    legendEnabled:true,
    series: [
      {
        name: 'Intakes',
        data: [55, 29, 29, 56, 69, 34, 52, 41, 46],
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        color: '#5BBDCB',
      },
      {
        name: 'Exits',
        data: [-62, -50, -42, -38, -23, -46, -64, -60],
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        color: '#4D91B6',
      },
      {
        type: 'line',
        name: 'Net Movement',
        data: [-7, -21, -13, 18, 11, 6, -23, -14],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#E88B00',
        marker: {
          lineWidth: 2,
          lineColor: '#E88B00',
          fillColor: '#E88B00'
        }
      }
    ]
  };

  const getOptions = (props) => ({
    chart: {
      type: props.type,
      backgroundColor: 'white',
    },
    plotOptions: {
      column: {
        colorByPoint: props.colorByPoint
      },
      series: {
        borderRadius: '25%'
      }
    },
    colors: props.colors,
    title: {
      text: props.title
    },
    credits: {
      enabled: props.creditsEnabled
    },
    legend: {
      enabled: props.legendEnabled
    },
    yAxis: {
      title: {
        text: props.yAxisTitle!==undefined ? props.yAxisTitle : 'Percentages(%)',
      },
    },
    xAxis: {
      categories: props.categories,
      labels: {
        style: {
          fontSize: '10px', // Adjust font size if needed
          whiteSpace: 'normal', // Allow labels to wrap when long
          overflow: 'justify',
        },
        tickWidth: 0,
      },
    },
    series: props.series,
  });

  const race = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
      align: 'left'
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: [ 'Top<br/>Management', 'Senior<br/>Management', 'Professional /<br/> Middle Management', 'Technical /<br/> Junior Management', 'Semi-Skilled', 'Unskilled'],
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Percentage (%)'
      },
      stackLabels: {
        shadow: true,
        enabled: false
      }
    },

    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
      column: {
        colorByPoint:false,
        stacking: 'normal',
        dataLabels: {
          shadow: false,
          enabled: false,
          style: {
            color: 'black',
            textOutline: 'none'
          }
        }
      }
    },
    series: [

        {
          dataLabels: {
            enabled: true,
            shadow: false,
            format: '{y}%'
          },
          name: 'White',
          data: [66.7, 46.7, 28.1, 10.1, 12.5, 0],
          color: '#4D91B6',

        },
        {
          dataLabels: {
            enabled: true,
            shadow: false,
            format: '{y}%',
            color: '#FFFFFF',
          },
          name: 'Coloured',
          data: [0, 8.7, 12.2, 18.3, 12.5, 40],
          color: '#497A94'
        },
      {
        dataLabels: {
          enabled: true,
          shadow: false,
          format: '{y}%',
          color: '#FFFFFF',
        },
        name: 'Indian',
        data: [25, 22.7, 21, 10, 4.2, 0],
        color: '#009CAE'
      },
        {
          dataLabels: {
            enabled: true,
            shadow: false,
            format: '{y}%',
            color:'#000000',
            borderColor:'#FFFFFF',
            borderWidth:'0px',
            style: {
              fontSize: '10px',


              padding:0,
            }
          },
          name: 'African',
          data: [8.3, 22, 38.7, 61.6, 70.8, 60.0],
          color: '#5BBDCB',
        },
      ]
  }

  const gender = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
      align: 'left'
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: [ 'Top<br/>Management', 'Senior<br/>Management', 'Professional /<br/> Middle Management', 'Technical /<br/> Junior Management', 'Semi-Skilled', 'Unskilled']
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Percentage (%)'
      },
      stackLabels: {
        shadow: false,
        enabled: false
      }
    },

    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
      column: {
        colorByPoint:false,
        stacking: 'normal',
        dataLabels: {
          shadow: false,
          enabled: false,
          style: {
            color: 'black',
            textOutline: 'none'
          }
        }
      }
    },
    series: [

      {
        dataLabels: {
          enabled: true,
          shadow: false,
          format: '{y}%',
          color:'#000000',
          borderColor:'#FFFFFF',
          borderWidth:'0px',
          style: {
            fontSize: '10px',
            padding:0,
          }
        },
        name: 'Male',
        data: [91.7, 60.7, 49.5, 32.9, 41.7, 0],
        color: '#5BBDCB',
      },
      {
        dataLabels: {
          enabled: true,
          shadow: false,
          format: '{y}%',
          color: '#FFFFFF',
        },
        name: 'Female',
        data: [8.3, 39.3, 50.5, 67.1, 58.3, 100],
        color: '#4D91B6'
      },
      ]
  }

  return (
    <>
      <Header label={"Workforce Analysis / Staff Analysis"} />

      <main id="main" className="main">
        <TempFilters />
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4"}>Headcount Distribution by Employee Level</span>
                </div>
                <div className="table-container">
                  <table className={"custom-table-wa"}>
                    <thead>
                    <tr>
                      <th className={""}></th>
                      <th colSpan="2" className={"heading heading1"}>Top <br/> Management</th>
                      <th colSpan="2" className={"heading heading2"}>Senior<br/>Management</th>
                      <th colSpan="2" className={"heading heading3"}>Professional /<br/>Middle<br/>Management</th>
                      <th colSpan="2" className={"heading heading4"}>Technical /<br/> Junior<br/> Management</th>
                      <th colSpan="2" className={"heading heading5"}>Semi - Skilled</th>
                      <th colSpan="2" className={"heading heading6"}>Unskilled</th>
                      <th className={""}></th>
                      <th colSpan="2" className={"heading7"}>TOTAL</th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr>
                      <td className={"fw-bold text-end"}>Total</td>
                      <td colSpan="2" className={"totHead totHead1"}>12</td>
                      <td colSpan="2" className={"totHead totHead2"}>150</td>
                      <td colSpan="2" className={"totHead totHead3"}>1 474</td>
                      <td colSpan="2" className={"totHead totHead4"}>2 889</td>
                      <td colSpan="2" className={"totHead totHead5"}>24</td>
                      <td colSpan="2" className={"totHead totHead6"}>5</td>

                      <td  className={"empty-col"}></td>
                      <td colSpan="2" className={"totHead totHead7"}>4 554</td>
                    </tr>

                    <tr>
                      <td  className={" text-end"}></td>
                      <td  className={"text-white fw-bold heading8"}>#</td>
                      <td  className={"text-white fw-bold heading8"}>%</td>

                      <td  className={"text-white fw-bold heading2"}>#</td>
                      <td  className={"text-white fw-bold heading2"}>%</td>

                      <td  className={"text-white fw-bold heading3"}>#</td>
                      <td  className={"text-white fw-bold heading3"}>%</td>

                      <td  className={"text-white fw-bold heading4"}>#</td>
                      <td  className={"text-white fw-bold heading4"}>%</td>

                      <td  className={"text-white fw-bold heading5"}>#</td>
                      <td  className={"text-white fw-bold heading5"}>%</td>

                      <td  className={"text-white fw-bold heading6"}>#</td>
                      <td  className={"text-white fw-bold heading6"}>%</td>

                        <td  className={"empty-col"}></td>

                      <td  className={"text-white fw-bold heading7"}>#</td>
                      <td  className={"text-white fw-bold heading7"}>%</td>

                    </tr>

                    <tr>
                      <td  className={" text-end"}>African</td>
                      <td  className={"text-black fst-normal bg-col-8"}>1</td>
                      <td  className={"text-black fst-normal bg-col-8"}>8.3%</td>

                      <td  className={"text-black fst-normal bg-col-2"}>33</td>
                      <td  className={"text-black fst-normal bg-col-2"}>22.0%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>571</td>
                      <td  className={"text-black fst-normal bg-col-3"}>38.7%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>1 779</td>
                      <td  className={"text-black fst-normal bg-col-4"}>61.6%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>17</td>
                      <td  className={"text-black fst-normal bg-col-5"}>70.8%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>2</td>
                      <td  className={"text-black fst-normal bg-col-6"}>40.0%</td>

                        <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>2 403</td>
                      <td  className={"text-black fst-normal bg-col-7"}>52.8%</td>

                    </tr>
                    <tr>
                      <td  className={" text-end"}>Indian</td>
                      <td  className={"text-black fst-normal bg-col-8"}>3</td>
                      <td  className={"text-black fst-normal bg-col-8"}>25.0%</td>

                      <td  className={"text-black fst-normal bg-col-2"}>34</td>
                      <td  className={"text-black fst-normal bg-col-2"}>22.7%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>309</td>
                      <td  className={"text-black fst-normal bg-col-3"}>21.0%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>288</td>
                      <td  className={"text-black fst-normal bg-col-4"}>10.0%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>1</td>
                      <td  className={"text-black fst-normal bg-col-5"}>4.2%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>0</td>
                      <td  className={"text-black fst-normal bg-col-6"}>0.0%</td>

                        <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>635</td>
                      <td  className={"text-black fst-normal bg-col-7"}>13.9%</td>

                    </tr>
                    <tr>
                      <td  className={" text-end"}>Coloured</td>
                      <td  className={"text-black fst-normal bg-col-8"}>0</td>
                      <td  className={"text-black fst-normal bg-col-8"}>0.0%</td>

                      <td  className={"text-black fst-normal bg-col-2"}>13</td>
                      <td  className={"text-black fst-normal bg-col-2"}>8.7%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>180</td>
                      <td  className={"text-black fst-normal bg-col-3"}>12.2%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>530</td>
                      <td  className={"text-black fst-normal bg-col-4"}>18.3%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>3</td>
                      <td  className={"text-black fst-normal bg-col-5"}>12.5%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>3</td>
                      <td  className={"text-black fst-normal bg-col-6"}>60.0%</td>

                        <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>729</td>
                      <td  className={"text-black fst-normal bg-col-7"}>16.0%</td>

                    </tr>
                    <tr>
                      <td  className={" text-end"}>White</td>
                      <td  className={"text-black fst-normal bg-col-8"}>8</td>
                      <td  className={"text-black fst-normal bg-col-8"}>66.7%</td>

                      <td  className={"text-black fst-normal bg-col-2"}>70</td>
                      <td  className={"text-black fst-normal bg-col-2"}>46.7%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>414</td>
                      <td  className={"text-black fst-normal bg-col-3"}>28.1%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>292</td>
                      <td  className={"text-black fst-normal bg-col-4"}>10.1%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>3</td>
                      <td  className={"text-black fst-normal bg-col-5"}>12.5%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>0</td>
                      <td  className={"text-black fst-normal bg-col-6"}>0.0%</td>

                        <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>787</td>
                      <td  className={"text-black fst-normal bg-col-7"}>17.3%</td>

                    </tr>

                    <tr>
                      <td  className={""}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>

                        <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>

                    </tr>

                    <tr>
                      <td  className={" text-end"}>Female</td>
                      <td  className={"text-black fst-normal bg-col-8"}>1</td>
                      <td  className={"text-black fst-normal bg-col-8"}>8.3%</td>

                      <td  className={"text-black fst-normal bg-col-2"}>31</td>
                      <td  className={"text-black fst-normal bg-col-2"}>39.3%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>745</td>
                      <td  className={"text-black fst-normal bg-col-3"}>50.5%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>1 938</td>
                      <td  className={"text-black fst-normal bg-col-4"}>67.1%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>14</td>
                      <td  className={"text-black fst-normal bg-col-5"}>58.3%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>5</td>
                      <td  className={"text-black fst-normal bg-col-6"}>100.0%</td>

                      <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>2 762</td>
                      <td  className={"text-black fst-normal bg-col-7"}>100%</td>

                    </tr>
                    <tr>
                      <td  className={" text-end"}>Male</td>
                      <td  className={"text-black fst-normal bg-col-8"}>11</td>
                      <td  className={"text-black fst-normal bg-col-8"}>91.7%</td>

                      <td  className={"text-black fst-normal bg-col-2"}>48</td>
                      <td  className={"text-black fst-normal bg-col-2"}>60.7%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>729</td>
                      <td  className={"text-black fst-normal bg-col-3"}>49.5%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>951</td>
                      <td  className={"text-black fst-normal bg-col-4"}>32.9%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>10</td>
                      <td  className={"text-black fst-normal bg-col-5"}>41.7%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>0</td>
                      <td  className={"text-black fst-normal bg-col-6"}>0.0%</td>

                      <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>1 792</td>
                      <td  className={"text-black fst-normal bg-col-7"}>0.0%</td>

                    </tr>

                    <tr>
                      <td  className={""}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>

                      <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>

                    </tr>

                    <tr>
                      <td  className={" text-end"}>00 - 02</td>
                      <td  className={"text-black fst-normal bg-col-8"}>0</td>
                      <td  className={"text-black fst-normal bg-col-8"}>0.0%</td>

                      <td  className={"text-black fst-normal bg-col-2"}>19</td>
                      <td  className={"text-black fst-normal bg-col-2"}>12.7%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>247</td>
                      <td  className={"text-black fst-normal bg-col-3"}>16.8%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>687</td>
                      <td  className={"text-black fst-normal bg-col-4"}>23.8%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>1</td>
                      <td  className={"text-black fst-normal bg-col-5"}>4.2%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>0</td>
                      <td  className={"text-black fst-normal bg-col-6"}>0.0%</td>

                      <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>954</td>
                      <td  className={"text-black fst-normal bg-col-7"}>20.9%</td>

                    </tr>
                    <tr>
                      <td  className={" text-end"}>03 - 05</td>
                      <td  className={"text-black fst-normal bg-col-8"}>0</td>
                      <td  className={"text-black fst-normal bg-col-8"}>0.0%</td>

                      <td  className={"text-black fst-normal bg-col-2"}>20</td>
                      <td  className={"text-black fst-normal bg-col-2"}>13.3%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>216</td>
                      <td  className={"text-black fst-normal bg-col-3"}>14.7%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>530</td>
                      <td  className={"text-black fst-normal bg-col-4"}>18.3%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>2</td>
                      <td  className={"text-black fst-normal bg-col-5"}>8.3%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>0</td>
                      <td  className={"text-black fst-normal bg-col-6"}>0.0%</td>

                      <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>768</td>
                      <td  className={"text-black fst-normal bg-col-7"}>16.9%</td>

                    </tr>
                    <tr>
                      <td  className={" text-end"}>06 - 10</td>
                      <td  className={"text-black fst-normal bg-col-8"}>3</td>
                      <td  className={"text-black fst-normal bg-col-8"}>25.0%</td>

                      <td  className={"text-black fst-normal bg-col-2"}>45</td>
                      <td  className={"text-black fst-normal bg-col-2"}>30.0%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>350</td>
                      <td  className={"text-black fst-normal bg-col-3"}>23.7%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>646</td>
                      <td  className={"text-black fst-normal bg-col-4"}>22.4%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>6</td>
                      <td  className={"text-black fst-normal bg-col-5"}>25.0%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>2</td>
                      <td  className={"text-black fst-normal bg-col-6"}>40.0%</td>

                      <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>1 052</td>
                      <td  className={"text-black fst-normal bg-col-7"}>23.1%</td>

                    </tr>
                    <tr>
                      <td  className={" text-end"}>11 - 20</td>
                      <td  className={"text-black fst-normal bg-col-8"}>6</td>
                      <td  className={"text-black fst-normal bg-col-8"}>50.0</td>

                      <td  className={"text-black fst-normal bg-col-2"}>40</td>
                      <td  className={"text-black fst-normal bg-col-2"}>26.7%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>386</td>
                      <td  className={"text-black fst-normal bg-col-3"}>26.2%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>731</td>
                      <td  className={"text-black fst-normal bg-col-4"}>25.3%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>11</td>
                      <td  className={"text-black fst-normal bg-col-5"}>45.8%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>2</td>
                      <td  className={"text-black fst-normal bg-col-6"}>40.0%</td>

                      <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>1 176</td>
                      <td  className={"text-black fst-normal bg-col-7"}>25.8%</td>

                    </tr>
                    <tr>
                      <td  className={" text-end"}>21 - 30</td>
                      <td  className={"text-black fst-normal bg-col-8"}>3</td>
                      <td  className={"text-black fst-normal bg-col-8"}>25.0%</td>

                      <td  className={"text-black fst-normal bg-col-2"}>15</td>
                      <td  className={"text-black fst-normal bg-col-2"}>10.0%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>179</td>
                      <td  className={"text-black fst-normal bg-col-3"}>11.9%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>207</td>
                      <td  className={"text-black fst-normal bg-col-4"}>7.2%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>3</td>
                      <td  className={"text-black fst-normal bg-col-5"}>12.5%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>1</td>
                      <td  className={"text-black fst-normal bg-col-6"}>20.0%</td>

                      <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>405</td>
                      <td  className={"text-black fst-normal bg-col-7"}>8.9%</td>

                    </tr>
                    <tr>
                      <td  className={" text-end"}>31 +</td>
                      <td  className={"text-black fst-normal bg-col-8"}>0</td>
                      <td  className={"text-black fst-normal bg-col-8"}>0.0%</td>

                      <td  className={"text-black fst-normal bg-col-2"}>11</td>
                      <td  className={"text-black fst-normal bg-col-2"}>7.3%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>99</td>
                      <td  className={"text-black fst-normal bg-col-3"}>6.7%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>88</td>
                      <td  className={"text-black fst-normal bg-col-4"}>3.0%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>1</td>
                      <td  className={"text-black fst-normal bg-col-5"}>4.2%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>0</td>
                      <td  className={"text-black fst-normal bg-col-6"}>0.0%</td>

                      <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>199</td>
                      <td  className={"text-black fst-normal bg-col-7"}>4.4%</td>

                    </tr>

                    <tr>
                      <td  className={""}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>

                      <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal empty-col"}></td>
                      <td  className={"text-black fst-normal empty-col"}></td>

                    </tr>


                    <tr>
                      <td  className={" text-end"}>18 - 24</td>
                      <td  className={"text-black fst-normal bg-col-8"}>0</td>
                      <td  className={"text-black fst-normal bg-col-8"}>0.0%</td>

                      <td  className={"text-black fst-normal bg-col-2"}>20</td>
                      <td  className={"text-black fst-normal bg-col-2"}>0.0%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>0</td>
                      <td  className={"text-black fst-normal bg-col-4"}>0.0%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>92</td>
                      <td  className={"text-black fst-normal bg-col-3"}>3.2%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>0</td>
                      <td  className={"text-black fst-normal bg-col-5"}>0.0%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>0</td>
                      <td  className={"text-black fst-normal bg-col-6"}>0.0%</td>

                      <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>92</td>
                      <td  className={"text-black fst-normal bg-col-7"}>2.0%</td>

                    </tr>
                    <tr>
                      <td  className={" text-end"}>25 - 29</td>
                      <td  className={"text-black fst-normal bg-col-8"}>0</td>
                      <td  className={"text-black fst-normal bg-col-8"}>0.0%</td>

                      <td  className={"text-black fst-normal bg-col-2"}>23</td>
                      <td  className={"text-black fst-normal bg-col-2"}>0.0%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>32</td>
                      <td  className={"text-black fst-normal bg-col-3"}>2.2%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>494</td>
                      <td  className={"text-black fst-normal bg-col-4"}>17.1%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>2</td>
                      <td  className={"text-black fst-normal bg-col-5"}>8.3%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>0</td>
                      <td  className={"text-black fst-normal bg-col-6"}>0.0%</td>

                      <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>528</td>
                      <td  className={"text-black fst-normal bg-col-7"}>11.6%</td>

                    </tr>
                    <tr>
                      <td  className={" text-end"}>30 - 39</td>
                      <td  className={"text-black fst-normal bg-col-8"}>0</td>
                      <td  className={"text-black fst-normal bg-col-8"}>0.0%</td>

                      <td  className={"text-black fst-normal bg-col-2"}>31</td>
                      <td  className={"text-black fst-normal bg-col-2"}>11.3%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>458</td>
                      <td  className={"text-black fst-normal bg-col-3"}>31.1%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>1 157</td>
                      <td  className={"text-black fst-normal bg-col-4"}>40.0%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>5</td>
                      <td  className={"text-black fst-normal bg-col-5"}>20.8%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>1</td>
                      <td  className={"text-black fst-normal bg-col-6"}>20.0%</td>

                      <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>1 638</td>
                      <td  className={"text-black fst-normal bg-col-7"}>36.0%</td>

                    </tr>
                    <tr>
                      <td  className={" text-end"}>40 - 49</td>
                      <td  className={"text-black fst-normal bg-col-8"}>5</td>
                      <td  className={"text-black fst-normal bg-col-8"}>41.7%</td>

                      <td  className={"text-black fst-normal bg-col-2"}>48</td>
                      <td  className={"text-black fst-normal bg-col-2"}>51.3%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>561</td>
                      <td  className={"text-black fst-normal bg-col-3"}>38.1%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>643</td>
                      <td  className={"text-black fst-normal bg-col-4"}>22.3%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>12</td>
                      <td  className={"text-black fst-normal bg-col-5"}>50.0%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>0</td>
                      <td  className={"text-black fst-normal bg-col-6"}>0.0%</td>

                      <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>1 298</td>
                      <td  className={"text-black fst-normal bg-col-7"}>28.5%</td>

                    </tr>
                    <tr>
                      <td  className={" text-end"}>50 - 59</td>
                      <td  className={"text-black fst-normal bg-col-8"}>7</td>
                      <td  className={"text-black fst-normal bg-col-8"}>58.3%</td>

                      <td  className={"text-black fst-normal bg-col-2"}>15</td>
                      <td  className={"text-black fst-normal bg-col-2"}>34.0%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>391</td>
                      <td  className={"text-black fst-normal bg-col-3"}>26.5%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>467</td>
                      <td  className={"text-black fst-normal bg-col-4"}>16.2%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>5</td>
                      <td  className={"text-black fst-normal bg-col-5"}>20.8%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>3</td>
                      <td  className={"text-black fst-normal bg-col-6"}>60.0%</td>

                      <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>924</td>
                      <td  className={"text-black fst-normal bg-col-7"}>20.3%</td>

                    </tr>
                    <tr>
                      <td  className={" text-end"}>60+</td>
                      <td  className={"text-black fst-normal bg-col-8"}>0</td>
                      <td  className={"text-black fst-normal bg-col-8"}>0.0%</td>

                      <td  className={"text-black fst-normal bg-col-2"}>12</td>
                      <td  className={"text-black fst-normal bg-col-2"}>3.3%</td>

                      <td  className={"text-black fst-normal bg-col-3"}>32</td>
                      <td  className={"text-black fst-normal bg-col-3"}>2.2%</td>

                      <td  className={"text-black fst-normal bg-col-4"}>36</td>
                      <td  className={"text-black fst-normal bg-col-4"}>1.2%</td>

                      <td  className={"text-black fst-normal bg-col-5"}>0</td>
                      <td  className={"text-black fst-normal bg-col-5"}>0.0%</td>

                      <td  className={"text-black fst-normal bg-col-6"}>1</td>
                      <td  className={"text-black fst-normal bg-col-6"}>20.0%</td>

                      <td  className={"empty-col"}></td>

                      <td  className={"text-black fst-normal bg-col-7"}>74</td>
                      <td  className={"text-black fst-normal bg-col-7"}>1.6%</td>

                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4 fw-bold"}>Race</span> <span className={"fs-4"}> Composition per Employee Level</span>
                </div>
                <HighchartsReact highcharts={Highcharts} options={race} />
              </div>
            </div>
          </div>

          <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4 fw-bold"}>Gender</span> <span className={"fs-4"}> Composition per Employee Level</span>
                </div>
                <HighchartsReact highcharts={Highcharts} options={gender} />
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className="mx-3 my-3">
                  <span className={"fs-4"}>Net Movement of Staff Over Time</span>
                </div>
                <HighchartsReact highcharts={Highcharts} options={getOptions(netMovement)} />
              </div>
            </div>
          </div>
        </div>
      </main>

    </>
  )
}
export default StaffAnalysis;
