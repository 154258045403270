import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';

const WorkforceAnalysis = () => {

    const quickLinWidget = ({title, icon, url}) => {
        return (
            <div className="card quick-link-item">
                <div className={"d-flex justify-content-center"}>
                    <div className="round-icon">
                        <i className={`${icon} text-white fs-5`}></i>
                    </div>
                </div>
                <div className="card-body">
                    <div className={"d-flex justify-content-center"}>
                        <a className='q-link' href={url} >
                            <span className="fs-6 fw-bold mx-3">{title}</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    };
  return (
    <>
      <Header icon={"fa-solid fa-link fa-xl mx-2"} label={"Workforce Analysis"} />
      <main id="main" className="main">
          <div className='card  quick-links-card bg-sky-blue'>
              <div className='card-body'>
                  <br/><br/><br/>
                  <div className='row mb-5'>
                      {[{
                          url: "/staff-composition",
                          label: "Staff Composition"
                      },
                          {
                              url: "/staff-analysis",
                              label: "Staff Analysis"
                          },
                          {
                              url: "/retirement",
                              label: "Retirement"
                          },
                          {
                              url: "/employment-equity",
                              label: "Employment Equity"
                          },

                          {
                              url: "/recruitment-onboarding",
                              label: "Recruitment Onboarding"
                          },
                          {
                              url: "/turn-over-analysis",
                              label: "Turn Over Analysis"
                          },
                          {
                              url: "promotions-vs-new-hires",
                              label: "Promotions vs. New Hires"
                          },
                          {
                              url: "/no-perms-contractors",
                              label: "Non-Perms / Contractors"
                          },
                      ].map((item, key) => (
                          <>
                              <div key={key} className='col-lg-3'>
                                  {/*<a className='' href={item.url}>
                                      <div className='card rounded-2'>
                                          <div className='card-header text-center bg-primary text-center py-2'>
                                              <i className="fa-solid fa-link fa-xl text-light"></i>
                                          </div>
                                          <div className='card-body text-center text-dark'>
                                              {item.label}
                                          </div>
                                      </div>
                                  </a>*/}
                                  {quickLinWidget({title:item.label, icon:'fa-solid fa-link', url:item.url})}
                              </div>

                          </>
                      ))}
                  </div>
              </div>
          </div>
      </main>
    </>
  )
}
export default WorkforceAnalysis;
