import React, { useRef, useEffect, useState } from 'react';
import './dropdown.css'

const checkboxes = [{id: 1, label: "Top Management"}, {id: 2, label: "Senior Management"}, {id: 3, label: "Professional / Middle Management"}, {id: 4, label: "Technical / Junior Management"}, {id: 5, label: "Semi-Skilled"}, {id: 6, label: "Unskilled"}];

const Dropdown = ({ label = "Filter", myClass, filterItems }) => {
    const [isOpen, setOpen] = useState(false);
    const [items, setItem] = useState(checkboxes);
    const [selectedItem, setSelectedItem] = useState(null);
    const [count, setCount] = useState('All');
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [randomNumber, setRandomNumber] = useState(null);
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const targetRef = useRef(null);

    useEffect(() => {
        const newRandomNumber = Math.floor(Math.random() * 100);
        setRandomNumber(newRandomNumber);
    }, []);

    const toggleDropdown = () => setOpen(!isOpen);

    const handleItemClick = (id) => {
        // selectedItem == id ? setSelectedItem(null) : setSelectedItem(id);
    }

    const handleCheckboxChange = (checkboxId) => {
        if (checkboxId === 'checkAll') {
            setCheckAll(!checkAll);
            setSelectedCheckboxes(checkAll ? [] : checkboxes.map(checkbox => checkbox.id));
        } else {
            setCheckAll(checkAll ? !checkAll : checkAll);
            if (selectedCheckboxes.includes(checkboxId)) {
                setSelectedCheckboxes(selectedCheckboxes.filter(id => id !== checkboxId));
            } else {
                setSelectedCheckboxes([...selectedCheckboxes, checkboxId]);
            }
        }
    };

  
    useEffect(() => {
      // Function to handle click events
      const handleClickOutside = (event) => {
        if (targetRef.current && !targetRef.current.contains(event.target)) {
            setOpen(false)
        }
      };
  
      // Attach the click event listener to the document
      document.addEventListener('click', handleClickOutside);
  
      // Cleanup: Remove the event listener when the component unmounts
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, []);
  

    return (
        <div id={randomNumber}  ref={targetRef}>
            <div className={` dropdown-filter-container ${isOpen && "open"}  dropdown ${myClass}`}>
                <div className={`dropdown-header  ${isOpen && "open"}`} onClick={toggleDropdown}>
                    <div className={"dropdown-label"}>{label}</div>
                    <div className={"dropdown-icon"}>
                        <i className="fa-solid fa-filter"></i> {count}
                    </div>
                </div>

                <div className={`dropdown-filter-body dropdown-body border ${isOpen && 'open'}`}>


                    {filterItems ? <>
                    
                        {filterItems.map(item => (
                        <div className="dropdown-item">
                            <div className="d-flex align-content-center">
                                <input
                                    type="checkbox"
                                    checked={selectedCheckboxes.includes(item.id)}
                                    onChange={() => handleCheckboxChange(item.id)}
                                />
                                <span
                                    className="dropdown-item-label ms-1"
                                    onClick={() => handleCheckboxChange(item.id)}
                                >
                                    {item.label}
                                </span>
                            </div>

                        </div>
                    ))}
                    </> :
                        <>
                    
                        {items.map(item => (
                        <div className="dropdown-item">
                            <div className="d-flex align-content-center">
                                <input
                                    type="checkbox"
                                    checked={selectedCheckboxes.includes(item.id)}
                                    onChange={() => handleCheckboxChange(item.id)}
                                />
                                <span
                                    className="dropdown-item-label ms-1"
                                    onClick={() => handleCheckboxChange(item.id)}
                                >
                                    {item.label}
                                </span>
                            </div>

                        </div>
                    ))}
                    </>
                    }
                   
                </div>
            </div>
        </div>
    )
}
export default Dropdown;