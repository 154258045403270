// src/config.js
const env = process.env.NODE_ENV || 'development';

const config = {
    development: {
        apiUrl: process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000/api/',
    },
    production: {
        apiUrl: process.env.REACT_APP_API_BASE_URL || 'https://cf-hrm.concisesolutions.co.za/api/',
    },
};

export default config[env];
