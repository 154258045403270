import React, {useEffect, useState} from "react";
import axios from "axios";
/**
 * Renders information about the user obtained from MS Graph
 * @param props
 */
export const ProfileData = (props) => {
    const [message, setMessage] = useState('')
    const [error, setError] = useState(false)

    const signInMSAADAuthenticatedUser = () => {
        axios.post(`signInMSAADAuthenticatedUser`, {email:props.graphData.userPrincipalName}).
        then(res => {
            setMessage(res.data.message)
            console.log(" res.data.token: ",  res.data.token)
            localStorage.setItem("token", res.data.token);
            window.location.href = '/';
        }).catch(err => {
            console.log("err: ", err.response.data);
            setMessage(err.response.data.message)
            setError(true)
        })
    }
    useEffect(()=>{
        signInMSAADAuthenticatedUser()
    }, [])
    return (
        <div id="profile-div">
            <div className="card rounded-2">
                <div className="card-body pt-4 p-3">
                    <div className="alert alert-success" role="alert">
                        Successfully authenticated with Microsoft Identity Platform.
                    </div>
                    {error &&
                        <div className="alert alert-danger" role="alert">
                            {message}
                        </div>
                    }

                    {/*<ul className="list-group">
                        <li className="list-group-item border-4 d-flex p-4 mb-2 bg-gray-100 rounded-2">
                            <div className="d-flex flex-column">
                                <span className="mb-2 text-xs">First Name: <span className="text-dark font-weight-bold ms-sm-2">{props.graphData.givenName}</span></span>
                                <span className="mb-2 text-xs">Last Name: <span className="text-dark ms-sm-2 font-weight-bold">{props.graphData.surname}</span></span>
                                <span className="text-xs">Email: <span className="text-dark ms-sm-2 font-weight-bold">{props.graphData.userPrincipalName}</span></span>
                                <span className="text-xs">Id: <span className="text-dark ms-sm-2 font-weight-bold">{props.graphData.id}</span></span>
                            </div>
                        </li>
                    </ul>*/}
                </div>
            </div>
        </div>
    );
};