import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import TempFilters from '../../components/Filters/TempFilters';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


const EqualPayEqualWork = () => {


    const chartProps1 = {
        type: 'column',
        width: 500,
        height: 500,
        title: '',
        categories: ["Client &  <br/>Adviser   <br/>Experience", "Business <br/> Development", "Investment <br/> Solutions", "Retail", "Finance", "Information <br/> Technology", "Retail  <br/>Risk  <br/>Solutions", "Legal", "Human <br/> Capital", "Marketing &  <br/> Communications", "Operations"],
        colorByPoint: false,
        colors: ['#5BBDCB', '#4D91B6'],
        creditsEnabled: false,
        credits: '',
        legendEnabled: true,
        series: [
            {
                type: 'column',
                name: '',
                data: [55, 53, 80, 50, 50, 45, 88, 90, 30, 40, 40],
                dataLabels: {
                    enabled: false,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#547A91',
                marker: {
                    lineWidth: 2,
                    lineColor: '#5BBDCB',
                    fillColor: '#5BBDCB'
                }
            },
            {
                type: 'line',
                name: 'Female',
                data: [34, 39, 44, 32, 37, 43, 33, 42, 35, 38, 45],
                dataLabels: {
                    enabled: false,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#602889',
                marker: {
                    lineWidth: 2,
                    lineColor: '#602889',
                    fillColor: '#602889',
                }
            },

            {
                type: 'line',
                name: 'Male',
                data: [44, 69, 74, 62, 67, 73, 53, 72, 65, 78, 75,],
                dataLabels: {
                    enabled: false,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#DC8E18',
                marker: {
                    lineWidth: 2,
                    lineColor: '#DC8E18',
                    fillColor: '#DC8E18'
                }
            }
        ]
    };


    const getOptions = (props) => ({
        chart: {
            type: props.type,

            backgroundColor: 'white',
        },
        plotOptions: {
            column: {
                colorByPoint: props.colorByPoint
            },
            series: {
                borderRadius: '25%'
            }
        },
        colors: props.colors,
        title: {
            text: props.title
        },
        credits: {
            enabled: props.creditsEnabled
        },
        legend: {
            enabled: props.legendEnabled
        },
        yAxis: {
            title: {
                text: 'Percentages(%)',
            },
        },
        xAxis: {
            categories: props.categories,
            labels: {
                style: {
                    fontSize: '12px', // Adjust font size if needed
                    whiteSpace: 'normal', // Allow labels to wrap when long
                    overflow: 'scroll',
                },
                // tickWidth: 0,
            },
        },
        series: props.series,
    });

    const data = [
        { name: { label: "Branch Manager", number: "# 52" }, payVariance: "< / = 25%", female: 5, male: 0, african: 1, indian: 8, coloured: 12, white: 4 },
        { name: null, payVariance: "> 25%", female: 13, male: 10, african: 6, indian: 10, coloured: 3, white: 7 },
        { name: null, payVariance: "> / = 50%", female: 7, male: 2, african: 9, indian: 2, coloured: 11, white: 1 },
        { name: null, payVariance: "> / = 80%", female: 16, male: 47, african: 13, indian: 4, coloured: 6, white: 3 },

        { name: { label: "Team Leader: Operational Services ", number: "# 97" }, payVariance: "< / = 25%", female: 12, male: 8, african: 5, indian: 8, coloured: 12, white: 5 },
        { name: null, payVariance: "> 25%", female: 15, male: 9, african: 4, indian: 6, coloured: 9, white: 7 },
        { name: null, payVariance: "> / = 50%", female: 11, male: 10, african: 3, indian: 5, coloured: 11, white: 2 },
        { name: null, payVariance: "> / = 80%", female: 14, male: 21, african: 10, indian: 6, coloured: 2, white: 5 },

        { name: { label: "Senior Specialist: Legal", number: "# 14" }, payVariance: "< / = 25%", female: 18, male: 10, african: 10, indian: 7, coloured: 11, white: 6 },
        { name: null, payVariance: "> 25%", female: 7, male: 14, african: 5, indian: 2, coloured: 4, white: 9 },
        { name: null, payVariance: "> / = 50%", female: 6, male: 11, african: 8, indian: 3, coloured: 7, white: 3 },
        { name: null, payVariance: "> / = 80%", female: 16, male: 18, african: 4, indian: 2, coloured: 8, white: 11 },

        { name: { label: "Call Centre Agent", number: "# 87" }, payVariance: "< / = 25%", female: 22, male: 12, african: 8, indian: 3, coloured: 10, white: 6 },
        { name: null, payVariance: "> 25%", female: 5, male: 8, african: 7, indian: 9, coloured: 1, white: 5 },
        { name: null, payVariance: "> / = 50%", female: 9, male: 13, african: 3, indian: 7, coloured: 5, white: 4 },
        { name: null, payVariance: "> / = 80%", female: 7, male: 24, african: 11, indian: 14, coloured: 4, white: 3 },

        { name: { label: "HR Business Partner", number: "# 37" }, payVariance: "< / = 25%", female: 13, male: 17, african: 7, indian: 5, coloured: 9, white: 8 },
        { name: null, payVariance: "> 25%", female: 19, male: 4, african: 10, indian: 2, coloured: 6, white: 4 },
        { name: null, payVariance: "> / = 50%", female: 15, male: 9, african: 5, indian: 7, coloured: 3, white: 4 },
        { name: null, payVariance: "> / = 80%", female: 8, male: 15, african: 6, indian: 14, coloured: 7, white: 3 }
    ];

    return (
        <>
            <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Parity / Equal Work of Equal Pay"} />
            <main id="main" className="main">
                <div className='d-flex flex-column'>
                    <div>
                        <button className='btn btn-primary btn-sm py-1 me-2'>RESET</button>
                        <button className='btn btn-primary btn-sm py-1'>REAPPLY</button>
                    </div>
                    <div>
                        <TempFilters />
                    </div>
                </div>
                <section className="section mt-3">
                    <div className='row'>
                        <div className='col-lg-8 col-md-12 col-sm-12'>
                            <div className='card'>
                                <div className=" d-flex">
                                    <h5 className="header-title my-3 py-0">Top 5 Jobs Variance</h5>
                                    <button className='btn btn-sm btn-secondary my-2 py-0 ms-auto me-2'>
                                        <i class="fa-regular fa-file-excel fa-xl me-2"></i>
                                        <span className='fw-bold'> Detailed Report</span></button>
                                </div>
                                <div className='card-body'>
                                    <table className="table border-collape">
                                        <thead>
                                            <tr>
                                                <th scope="col" className='border-0' colSpan={2}>#</th>
                                                <th scope="col" className='bg-secondary border-right' colSpan={2}>Gender</th>
                                                <th scope="col" className='bg-secondary' colSpan={4}>Race</th>
                                            </tr>
                                            <tr>
                                                <th scope="col" className='border-0'></th>
                                                <th scope="col" className='fw-bold text-black'>Pay Variance</th>
                                                <th scope="col" className='bg-secondary'>Female</th>
                                                <th scope="col" className='bg-secondary border-right'>Male</th>
                                                <th scope="col" className='bg-tertiary'>African</th>
                                                <th scope="col" className='bg-tertiary'>Indian</th>
                                                <th scope="col" className='bg-tertiary'>Coloured</th>
                                                <th scope="col" className='bg-tertiary'>White</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, key) => (
                                                <>
                                                    <tr className={[0, 4, 8, 12, 16].includes(key) ? "border-top" : key == 19 ? "border-bottom" : ""}>
                                                        {item.name && <td rowSpan={4}>{item.name.label}<br /> {item.name.number}</td>}
                                                        <td className='bg-white'>{item.payVariance}</td>
                                                        <td className='bg-secondary-light'>{item.female}%</td>
                                                        <td className='bg-secondary-light border-right'>{item.male}%</td>
                                                        <td className='bg-tertiary-light'>{item.african}%</td>
                                                        <td className='bg-tertiary-light'>{item.indian}%</td>
                                                        <td className='bg-tertiary-light'>{item.coloured}%</td>
                                                        <td className='bg-tertiary-light'> {item.white}%</td>
                                                    </tr>
                                                </>
                                            )
                                            )}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='card'>
                                <div className="">
                                    <h5 className="header-title">Business Unit: Average Job Disparity</h5>
                                    <div className='ms-2 ps-2'>
                                        {["Gender", "Race"].map(item => (
                                            <>
                                                <input defaultChecked={item=="Gender"} type='radio' name = "sdf" className='pb-4' />
                                                <span className='pb-4 mx-2'>{item}</span></>
                                        ))}
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className="scroll-x">
                                        <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps1)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
export default EqualPayEqualWork;


