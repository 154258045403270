import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import TempFilters from '../../components/Filters/TempFilters';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import HCMore from 'highcharts/highcharts-more'; // If you need additional chart types
import HighchartsExporting from 'highcharts/modules/exporting'; // For exporting the chart as an image or PDF
import HighchartsExportData from 'highcharts/modules/export-data'; // For exporting data as CSV or XLSX

// Initialize the additional modules
HCMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);


const BonusAllocation = () => {

    const perGender = {
        type: 'column',
        title: '',
        categories: [ 'Female', 'Male'],
        colorByPoint: true,
        colors: ['#74BBC9','#74BBC9'],
        creditsEnabled: false,
        credits:'',
        legendEnabled:false,
        yAxisTitleText:'',
        series: [
            {
                data: [13.4, 23.2],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#5BBDCB',
            },
        ]
    };

    const perRace = {
        type: 'column',
        title: '',
        categories: [ 'African', 'Indian', 'Coloured', 'White'],
        colorByPoint: true,
        colors: ['#74BBC9','#74BBC9','#74BBC9','#74BBC9'],
        creditsEnabled: false,
        credits:'',
        legendEnabled:false,
        yAxisTitleText:'',
        series: [
            {
                data: [12.8, 22.1, 12.7, 29.6],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#5BBDCB',
            },
        ]
    };

    const perBand = {
        type: 'column',
        title: '',
        categories: [ 'B1', 'B2', 'B3', 'B4', 'B5',],
        colorByPoint: true,
        colors: ['#74BBC9','#74BBC9','#74BBC9','#74BBC9','#74BBC9'],
        creditsEnabled: false,
        credits:'',
        legendEnabled:false,
        yAxisTitleText:'',
        series: [
            {
                data: [79.5, 40.2, 14.1, 7.8, 7.6],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#5BBDCB',
            },
        ]
    };

    const perBA = {
        type: 'column',
        title: '',
        categories: [ 'Asset<br/>Management', 'Client & Adviser<br/>Experience', 'Business<br/>Development', 'Investment<br/>Solutions', 'Holdings', 'Client<br/>Adviser', 'Finance', 'Information<br/>Technology', 'Actuarial&<br/>Risk', 'Properties', 'Retail Risk<br/>Solutions', 'Compliance,<br/>Forensic & Legal', 'Human<br/>Capital', 'Marketing & <br/> Communications', 'Strategic Planning &<br/>Transformation'],
        colorByPoint: true,
        colors: ['#74BBC9','#74BBC9'],
        creditsEnabled: false,
        credits:'',
        legendEnabled:false,
        yAxisTitleText:'',
        series: [
            {
                data: [282320036, 102558798, 84821209, 73208484, 64590892, 49309358, 47684823, 47183075, 33953673, 22842601, 17914220, 17004401, 15861206, 12002174, 5051960],
                dataLabels: {
                    enabled: true,
                    format: 'R {y}',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#5BBDCB',
            },
        ]
    };

    const getOptions = (props) => ({
        chart: {
            type: props.type,
            backgroundColor: 'white',
        },
        plotOptions: {
            column: {
                colorByPoint: props.colorByPoint
            },
            series: {
                borderRadius: '25%'
            }
        },
        colors: props.colors,
        title: {
            text: props.title
        },
        credits: {
            enabled: props.creditsEnabled
        },
        legend: {
            enabled: props.legendEnabled
        },
        yAxis: {
            title: {
                text: props.yAxisTitleText === undefined ? 'Percentages(%)' : props.yAxisTitleText,
            },
        },
        xAxis: {
            categories: props.categories,
            labels: {
                style: {
                    fontSize: '12px', // Adjust font size if needed
                    whiteSpace: 'normal', // Allow labels to wrap when long
                    overflow: 'scroll',
                },
                tickWidth: 0,
            },
        },
        series: props.series,
    });

    return (
        <>
            <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Remuneration/Bonus Allocation"} />
            <main id="main" className="main">
                <div className='d-flex flex-column'>
                    <div>
                        <button className='btn btn-primary btn-sm py-1 me-2'>RESET</button>
                        <button className='btn btn-primary btn-sm py-1'>REAPPLY</button>
                    </div>
                    <div>
                        <TempFilters />
                    </div>
                </div>
                <section className="section mt-3">
                    <div className='row'>

                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className="mx-3 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Total Bonus Allocated</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="scroll-x mb-3">
                                        <table className="table  band-table">
                                            <thead>
                                            <tr className='text-center'>
                                                <th scope="col" className='text-center'></th>
                                                <th scope="col" className='text-center'>Band 1 & 2</th>
                                                <th scope="col" className='text-center'>Band 3</th>
                                                <th scope="col" className='text-center'>Band 4 & 5</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="">
                                                <td>Bonus as % of ATP</td>
                                                <td>64.4 %</td>
                                                <td>17.0 %</td>
                                                <td>8.1 %</td>
                                            </tr>
                                            <tr className="">
                                                <td>Total Incentive</td>
                                                <td>644 816 598</td>
                                                <td>183 506 752</td>
                                                <td>47 983 560</td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>


                                    <div className="scroll-x mb-3">
                                        <table className="table  custom2-table">
                                            <thead>
                                            <tr className='text-center'>
                                                <th scope="col" className='text-start'>Incentive Scheme</th>
                                                <th scope="col" className='text-center'>Headcount</th>
                                                <th scope="col" className='text-center'>Bonus as % of TP</th>
                                                <th scope="col" className='text-center'>Total Incentive</th>
                                                <th scope="col" className='text-center'>Average Incentive %</th>
                                                <th scope="col" className='text-center'>Average Incentive</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="">
                                                <td>General Staff Scheme</td>
                                                <td>2 834</td>
                                                <td>8.8%</td>
                                                <td>119 621 618</td>
                                                <td>8.4%</td>
                                                <td>42 209</td>
                                            </tr>
                                            <tr className="">
                                                <td>Risk Senior Management Scheme</td>
                                                <td>18</td>
                                                <td>66.9%</td>
                                                <td>26 564 073</td>
                                                <td>61.8%</td>
                                                <td>1 475 781</td>
                                            </tr>
                                            <tr className="">
                                                <td>Senior Management Scheme</td>
                                                <td>100</td>
                                                <td>74.6%</td>
                                                <td>177 251 951</td>
                                                <td>67.1%</td>
                                                <td>1 772 519</td>
                                            </tr>
                                            <tr className="">
                                                <td>Senior Management Scheme - GIP</td>
                                                <td>6</td>
                                                <td>61.3%</td>
                                                <td>7 554 823</td>
                                                <td>60.9%</td>
                                                <td>1 259 137</td>
                                            </tr>
                                            <tr className="">
                                                <td>Senior Management Scheme - Health</td>
                                                <td>1</td>
                                                <td>105.6%</td>
                                                <td>3 050 000</td>
                                                <td>105.6%</td>
                                                <td>3 050 000</td>
                                            </tr>
                                            <tr className="">
                                                <td>Senior Management Scheme - Prop</td>
                                                <td>30</td>
                                                <td>56.9%</td>
                                                <td>22 842 601</td>
                                                <td>39.8%</td>
                                                <td>761 420</td>
                                            </tr>
                                            <tr className="">
                                                <td>Senior Management Scheme - FIN</td>
                                                <td>48</td>
                                                <td>52.5%</td>
                                                <td>29 475 300</td>
                                                <td>45.5%</td>
                                                <td>614 068</td>
                                            </tr>
                                            <tr className="">
                                                <td>Senior Management Scheme - FIN Markets</td>
                                                <td>26</td>
                                                <td>135.3%</td>
                                                <td>59 125 000</td>
                                                <td>119.6%</td>
                                                <td>2 274 038</td>
                                            </tr>
                                            <tr className="">
                                                <td>Specialist Scheme</td>
                                                <td>395</td>
                                                <td>29.4%</td>
                                                <td>135 943 373</td>
                                                <td>27.2%</td>
                                                <td>344 160</td>
                                            </tr>
                                            <tr className="">
                                                <td>Sales Incentive Scheme</td>
                                                <td>3</td>
                                                <td>98.1%</td>
                                                <td>2 625 754</td>
                                                <td>75.4%</td>
                                                <td>875 251</td>
                                            </tr>
                                            <tr className="">
                                                <td>CAE Sales Incentive Scheme</td>
                                                <td>51</td>
                                                <td>62.8%</td>
                                                <td>46 702 381</td>
                                                <td>54.0%</td>
                                                <td>915 732</td>
                                            </tr>
                                            <tr className="">
                                                <td>Corporate Functions</td>
                                                <td>53</td>
                                                <td>14.4%</td>
                                                <td>6 426 125</td>
                                                <td>11.6%</td>
                                                <td>121 247</td>
                                            </tr>
                                            <tr className="">
                                                <td>Distribution</td>
                                                <td>46</td>
                                                <td>47.9%</td>
                                                <td>62 450 161</td>
                                                <td>40.1%</td>
                                                <td>488 173</td>
                                            </tr>

                                            <tr className="">
                                                <td>Investment Professional</td>
                                                <td>62</td>
                                                <td>142.0%</td>
                                                <td>142 724 594</td>
                                                <td>120.1%</td>
                                                <td>2 302 009</td>
                                            </tr>
                                            <tr className="">
                                                <td>Shared Services</td>
                                                <td>132</td>
                                                <td>52.3%</td>
                                                <td>67 560 417</td>
                                                <td>26.1%</td>
                                                <td>511 821</td>
                                            </tr>
                                            <tr className="">
                                                <td>Others</td>
                                                <td>26</td>
                                                <td>28.1%</td>
                                                <td>6 382 900</td>
                                                <td>15.5%</td>
                                                <td>245 496</td>
                                            </tr>
                                            {/*last row start*/}
                                            <tr className="last-row">
                                                <td>TOTAL</td>
                                                <td>3 831</td>
                                                <td>32.7%</td>
                                                <td>876 306 910</td>
                                                <td>17.2%</td>
                                                <td>228 741</td>
                                            </tr>
                                            {/*last row end*/}
                                            </tbody>
                                        </table>
                                    </div>


                                    <button className="btn btn-primary">
                                        <i className="fa-solid fa-table text-white"></i> Export Report
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className="mx-3 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Average Bonus % of TP by Band & Performance</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="scroll-x mb-3">
                                        <table className="table  custom2-table">
                                            <thead>
                                            <tr className='text-center'>
                                                <th scope="col" className='text-center'>Band</th>
                                                <th scope="col" className='text-center'>Not Achieved</th>
                                                <th scope="col" className='text-center'>Partially Achieved</th>
                                                <th scope="col" className='text-center'>Fully Achieved</th>
                                                <th scope="col" className='text-center'>Exceptionally Achieved</th>
                                                <th scope="col" className='text-center'>Too Early to Assess</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="">
                                                <td>B1</td>
                                                <td>0.0%</td>
                                                <td>50.0%</td>
                                                <td>73.6%</td>
                                                <td>108.0%</td>
                                                <td>50.5%</td>
                                            </tr>
                                            <tr className="">
                                                <td>B2</td>
                                                <td>0.0%</td>
                                                <td>15.5%</td>
                                                <td>37.6%</td>
                                                <td>51.3%</td>
                                                <td>7.9%</td>
                                            </tr>
                                            <tr className="">
                                                <td>B3</td>
                                                <td>0.0%</td>
                                                <td>4.0%</td>
                                                <td>13.5%</td>
                                                <td>20.3%</td>
                                                <td>13.5%</td>
                                            </tr>
                                            <tr className="">
                                                <td>B4</td>
                                                <td>0.0%</td>
                                                <td>1.9%</td>
                                                <td>7.5%</td>
                                                <td>11.5%</td>
                                                <td>5.7%</td>
                                            </tr>
                                            <tr className="">
                                                <td>B5</td>
                                                <td>0.0%</td>
                                                <td>3.0%</td>
                                                <td>7.5%</td>
                                                <td>13.3%</td>
                                                <td>0.0%</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-3 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Average Bonus % per Gender</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="my-2">
                                            <span className="me-2">Show History:</span>
                                            <select className="chart-select" aria-label="Default select example">
                                                <option selected>Current Only</option>
                                            </select>
                                        </div>
                                    </div>

                                    <HighchartsReact highcharts={Highcharts} options={getOptions(perGender)} />

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-3 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Average Bonus % per Race</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="my-2">
                                            <span className="me-2">Show History:</span>
                                            <select className="chart-select" aria-label="Default select example">
                                                <option selected>Current Only</option>
                                            </select>
                                        </div>
                                    </div>

                                    <HighchartsReact highcharts={Highcharts} options={getOptions(perRace)} />

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-3 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Average Bonus % per Band</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="my-2">
                                            <span className="me-2">Show History:</span>
                                            <select className="chart-select" aria-label="Default select example">
                                                <option selected>Current Only</option>
                                            </select>
                                        </div>
                                    </div>

                                    <HighchartsReact highcharts={Highcharts} options={getOptions(perBand)} />

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='card'>

                                <div className='card-body'>
                                    <div className="mx-3 mb-4">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="">Average Bonus % per Business Area</h5>
                                            <div className="d-flex justify-content-center align-items-center chart-controls">
                                                <span className="py-1">
                                                    <i className="fa-solid fa-expand"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="my-2">
                                            <span className="me-2">Show History:</span>
                                            <select className="chart-select" aria-label="Default select example">
                                                <option selected>Current Only</option>
                                            </select>
                                        </div>
                                    </div>

                                    <HighchartsReact highcharts={Highcharts} options={getOptions(perBA)} />

                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </>
    )
}
export default BonusAllocation;
