import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';

const Develop = () => {



  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"}  label={"People Management/Develop"} />
      <main id="main" className="main">

        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>

                  <div className='card-description'>
                    <p>
                      Developing employees promotes a shared commitment to nurturing their skills and potential, encouraging active growth in various aspects of our organisation and enhancing their alignment with our mission.
                    </p>
                  </div>
                  <div className='content-block'>

                    <div className='h-4 card-content-headers mb-2'>
                      Learning Management System (LMS)
                    </div>
                    <p>A functional, integrated, and sustainable Learning Management System (LMS) is a paramount objective in our commitment to employee development. This robust LMS not only facilitates the efficient delivery but also enables comprehensive monitoring of training and development programmes, ensuring they align seamlessly with our business requirements.</p>
                    <p>We aim to empower our workforce with the essential tools for continuous growth and skill enhancement, contributing significantly to our organisation's overall success.</p>

                    <div className='link-container'>
                      <a href=''>
                        Click here to access the Learning Management System
                      </a>
                    </div>
                  </div>


                  <div className='content-block'>

                    <div className='h-4 card-content-headers mb-2'>
                      Work Architecture & Job Benchmark
                    </div>
                    <p>
                      Work Architecture presents an opportunity to review operating and service delivery models for growth and sustainability in a changing world. To remain competitive in today's business landscape, we have to adapt more quickly, learn more rapidly, and embrace dynamic career demands from current and prospective employees.
                    </p>
                    <p>
                      The relevancy and flexibility of an organisation's Jobs will largely affect the successful attraction and retention of Talent in a rapidly changing world.
                    </p>
                    <div className='link-container'>
                      <a href=''>
                        Click here to access the Work Architecture system
                      </a>
                    </div>
                  </div>

                  <div className='content-block'>

                    <div className='h-4 card-content-headers mb-2'>
                      WSP / ATR Reporting
                    </div>
                    <p>Learning and Development reporting is required not only from an internal management reporting perspective but is also required for
                      Skills Development as a key element of the B-BBEE scorecard and for skills levy reporting.</p>
                    <p>
                      The Workplace Skills Plan (WSP) process focuses on compiling a n organisation-wide skills plan that is submitted to SETA on an annual
                      basis.
                    </p>
                    <p>The Annual Training Report (AT) process enables the monitoring of intervention attendance on an ongoing basis. It ensures that the relevant stakeholders are advised if attendance is sub-optimal so that this can be addressed.</p>
                    <div className='link-container'>
                      <a href=''>
                        Click here to access the WSP / ATR Reporting system
                      </a>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default Develop;
